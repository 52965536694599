import english from './languageModule/english'
import chinese from './languageModule/chinese'
import japanese from './languageModule/japanese'
import chineseTraditional from './languageModule/chineseTraditional'
import portuguese from './languageModule/portuguese'
import german from './languageModule/german'
import french from './languageModule/french'
import turkish from './languageModule/turkish'
import spanish from './languageModule/spanish'
import arabic from './languageModule/arabic'
import korean from './languageModule/korean'
import finland from './languageModule/finland'
import russian from './languageModule/russian'
import italian from './languageModule/italian'
import thai from './languageModule/thai'
import dutch from './languageModule/dutch'

// 英语
/*登录页面输入框提示语 */
let loginLanguage = {}
export default function languageType(key) {

  if (key == 'English' || key === null) {
    return loginLanguage = english
  }

  if (key == '日本語') {
    return loginLanguage = japanese
  }

  if (key == 'Português') {
    return loginLanguage = portuguese
  }

  if (key == '繁體中文') {
    return loginLanguage = chineseTraditional
  }

  if (key == '简体中文') {
    return loginLanguage = chinese
  }

  if (key == 'Français') {
    return loginLanguage = french
  }

  if (key == 'Deutsch') {
    return loginLanguage = german
  }

  if (key == 'Türk') {
    return loginLanguage = turkish
  }

  if (key == 'Español') {
    return loginLanguage = spanish
  }

  if (key == 'عربي') {
    return loginLanguage = arabic
  }

  if (key == '한국인') {
    return loginLanguage = korean
  }

  if (key == 'Suomi') {
    return loginLanguage = finland
  }

  if (key == 'Русский') {
    return loginLanguage = russian
  }

  if (key == 'italiano') {
    return loginLanguage = italian
  }

  if (key == 'แบบไทย') {
    return loginLanguage = thai
  }

  if (key == 'Nederlands') {
    return loginLanguage = dutch
  }
}