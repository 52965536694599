// 简体中文

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: '版本升级，请确认？',
    loadingTitle: '正在下载...',
    systemUpgrade: '系统升级',
    upgradeImmediately: '确认',
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: '店铺名称',
    pass: '密码',
    placeholderPass: '请输入密码',
    placeholderShopName: "请输入店铺名称",
    mail: "邮箱",
    placeholderMail: "请输入邮箱",
    emailCode: "邮箱验证码",
    placeholderEmailCode: "请输入验证码",
    send: "发送",
    InvitationCode: '邀请码',
    PlaceholderInvitationCode: '请输入邀请码',
    documents: '上传证件',
    front: '证件正面',
    reverseSide: '证件正面',
    placeholderFront: '请上传证件正面',
    placeholderReverseSide: '请上传证件反面',
    btn: '申请',
    applicationBtn: '确认',
    applicationNav: '店铺申请协议',
    dialogTitle: '审核成功',
    dialogMessage: '申请成功，请耐心等待工作人员审核！',
    close: '确认',
}
// 登录
let loginLanguage = {
    navTitle: '登录',
    warningTitle: '请输入登录邮箱',
    passTitle: '请输入登录密码',
    forgetPassword: '忘记密码？',
    btnTitle: '登录',
    hintTitle: '没有账号？去注册',
    linkTitle: '客服',
    AppDownload: '下载app',
    goToShop: '前往商城',
    register: '注册'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: '忘记密码',
    email: '登录邮箱',
    placeholderEmail: '请输入登录邮箱',
    emailCode: '验证码',
    placeholderEmailCode: '请输入验证码',
    newPass: '新密码',
    placeholderNewPass: "请输入新密码",
    affirmPass: '确认密码',
    placeholderAffirmPass: '请二次输入密码',
    submitBtn: '发送',
    affirm: '确认',
}

// 首页
let homeLanguage = {
    navTitle: '今日销售额',
    orderTitle: '今日订单数',
    balanceTitle: '余额',
    flowTitle: '今日流量数',
    navTitleLine: '销售额',
    orderTitleLine: '订单数',
    flowTitleLine: '流量数',
    rechargeTitle: '充值',
    bankTitle: '提现',
    analysis: '近30天概览',
    showTitle: '展开',
    selectAll: '全选',
    cancel: '关闭',
    sure: '确认',
}


// 充值页
let rechargeLanguage = {
    navTitle: '充值',
    currencyType1: '法定货币',
    currencyType2: '虚拟货币',
    pleaseChoose: "请选择",
    cancelBtn: '取消',
    submitBtn: '确认',
    placeholderNav: '输入充值金额',
    placeholderCurrent: '请输入充值金额',
    dialogMessage: '请选择货币种类。',
    pathTitle: '充值方式',
    hintTitle: '重要提示:',
    hintContent: '仅允许转入<资产',
    success: '复制成功！',
    hintPopup: '请前往联系客服人员！',
    hintTile: '提示信息'

}

// 提现页
let withdrawLanguage = {
    navTitle: '提现',
    currencyType1: '法定货币',
    currencyType2: '虚拟货币',
    pleaseChoose: "请选择",
    cancelBtn: '取消',
    submitBtn: '确认',
    placeholderNav: '输入提现金额',
    placeholderCurrent: '请输入提现金额',
    currentBalance: '当前余额',
    passwordTips: '请输入提现密码',
    pathTitle: '汇率',
    rateTips: '当前转化汇率',
    dialogMessage: '请选择货币种类。',
    hintTitle: '重要提示:',
    hintContent: '仅允许转入<资产',
}


// 提示文本
let messageLanguage = {
    warningTitle: '错误',
    loadingTitle: '加载中',
    finishedText: "无",
    loadingText: '加载',
    wait: '请稍等',
    placeholderHite: '输入值有误',
    uploadError: '上传失败',
    upload: '上传中',
}

// 个人中心
let myCenterLanguage = {
    navTitle: '个人中心',
    bankCard: '提现设置',
    productLibrary: '产品管理',
    financialManagement: '资金记录',
    reportStatistics: '经营报表',
    accountInformation: '店铺信息',
    setting: '系统设置',
    goToShop: '前往商城',
    goToLoan: '去贷款',
}

// 产品库
let productLibraryLanguage = {
    navTitle: '产品库',
    productList: '店铺产品',
    productLibraryTitle: '平台产品',
    purchasePrice: '采购价格',
    sellingPrice: '销售价格',
    selectAll: '选中所有',
    affirm: '确认',
    close: '取消',
    editPrice: '修改价格',
    deleteTitle: '删除订单',
    dialogMessage: '确定删除该产品吗？'
}
// 资金记录
let financialManagementLanguage = {
    navTitle: '资金记录',
    tabs1Title: '所有资金',
    tabs2Title: '充值记录',
    tabs3Title: '提现记录',
    selectionPeriod: '时间选择',
    rechargeTitle: '充值',
    wthdrawTitle: '提现',
    successful: '成功',
    balance: '余额',
    rechargeTime: '充值时间',
    rechargeMessage: '货币种类',
    wthdrawTime: '提现时间',
    wthdrawMessage: '货币种类',
    currency: "货币",
    timeAffirm: '确认',
    timeCancel: '取消',
}
// 经营报表
let statisticsLanguage = {
    totalSales: '总销售额',
    totalPurchase: '总采购额',
    totalProfit: '总利润额',
    selectionPeriod: '时间筛选',
    analysis: '折线图',
    showTitle: '展开',
    selectAll: '全选',
    cancel: '关闭',
    sure: '确认',
    fundingDetails: '详细数据',
    typeSelection: '选择类型',
    purchaseAmount: '采购额',
    profitAmount: '利润额',
    unitAmount: '销售额',
    grandTotal: '累计',
    timeAffirm: '确认',
    timeCancel: '取消',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: '经营列表',
    selectionPeriod: '选择时间',
    totalPurchase: '采购额',
    totalSales: '利润额',
    totalProfit: '销售额',
    grandTotal: '累计',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: '店铺信息',
    picture: '店铺图像',
    nameTitle: '店铺名称',
    Mail: '登录邮箱',
    invitationCode: '邀请码',
    loginPassword: '登录密码',
    paymentPassword: '支付密码',
    documents: '证件信息',
    frontDocument: '证件正面',
    backDocument: '证件反面',
    exit: '退出登录',
}

//  提现设置
let eBackLanguage = {
    navTitle: '提现设置',
    cashCurrency: '法定货币',
    virtualCurrency: '虚拟货币',
    pleaseChoose: '请选择',
    popupAffirm: '确认',
    popupCancel: '取消',
    hintTitle: '重要提示:',
    hintContent: '仅允许转入<资产。',
    affirmBtn: '确认',
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: '真实姓名',
    email: '邮箱',
    mobilePhone: '手机号',
    pixType: 'PIX类型',
    pixKey: 'PIX账号',
    pleaseEnter: '请输入',
    pleaseSelect: '请选择',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: '请输入',
    country: '国家/地区',
    name: '名称',
    surname: '姓氏',
    bsb: 'BSB',
    bankName: '银行名称',
    bankAccount: '银行账户',
    state: '省或州',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: '请输入',
    country: '国家/地区',
    name: '名称',
    surname: '姓氏',
    swift: 'swift',
    bankName: '银行名称',
    iban: 'iban',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: '请输入',
    country: '国家/地区',
    name: '名称',
    surname: '姓氏',
    bankCode: '银行代码',
    bankName: '银行名称',
    bankAccount: '银行账户',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: '请输入',
    country: '国家/地区',
    name: '名称',
    surname: '姓氏',
    zengin: 'zengin',
    bankName: '银行名称',
    bankAccount: '银行账户',
    county: '县',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: '请输入',
    country: '国家/地区',
    name: '名称',
    surname: '姓氏',
    swift: 'swift',
    ach: 'ach',
    bankName: '银行名称',
    bankAccount: '银行账户',
    state: '省或州',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: '地址',
    pleaseInformation: '请输入虚拟货币地址'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: '登录密码修改',
    oldPass: '旧密码',
    newPass: '新密码',
    email: '验证码',
    placeholderOldPass: '请输入旧密码',
    placeholderNewPass: '请输入新密码',
    placeholderEmailCode: '请输入验证码',
    submitBtn: '发送',
    affirm: '确认',

}

//  个人中心修改支付密码
let payPass = {
    navTitle: '支付密码修改',
    affirmPass: '支付密码',
    email: '验证码',
    submitBtn: '发送',
    affirm: '确认',
    placeholder: '请输入',
    placeholderPaymentPassword: '请输入支付密码',
    placeholderEmailCode: '请输入验证码',
}

//  系统设置
let settingLanguage = {
    navTitle: '系统设置',
    languageShift: '多语言设置',
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '多语言设置',
    affirm: '确认'
}
//  公告
let afficheDetail = {
    navTitle: '消息',
    comment: '回复',
    unreadMessage: '未读',
    readTitle: '已读'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: '消息详情',
    comment: '评论',
    check: '查看',
    packUpPlaceholder: '请输入回复内容',
    sendInform: '发送'
}

// 客服
let serviceLanguage = {
    navTitle: '客服',
    customerService: '专属客服',
    helloTitle: '你好',
    welcomeTitle: ' 我是您的 <， 您可以通过以下方式联系我',
    iconInformation: '请选择沟通软件进行咨询',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "订单管理",
    tabsTodos: "已采购",
    tabsPendente: "待采购",
    tabsCompleto: "物流中",
    tabsCancelar: "已完成",
    totalProfit: '总利润',
    statementPrice: "总采购额",
    affirmBtn: "采购",
    orderTotal: "总订单数",
    orderCode: "订单号:",
    childrenDetail: '个产品',
    checkDetail: '产看详情',
    buyAll: '采购所有',
    dialogTitle: '采购信息',
    dialogTotalSales: '总销售额',
    dialogTotalPurchase: '总采购额',
    dialogTotalProfit: '总利润额',
    dialogBtn: '采购',
    deleteTitle: '订单采购',
    dialogMessage: '您确认采购该订单吗？',
    dialogAllMessage: '您确认采购所有订单吗？',
    affirm: '确认',
    close: '取消',
    selectionPeriod: '时间选择'
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "订单详情",
    orderCode: "订单号:",
    totalProfitTitleCard: '总利润',
    statementPrice: "总采购额",
    amountRelated: '订单金额',
    salesTitle: '销售金额',
    totalSalesTitle: '总销售额',
    totalPurchase: '总利润额',
    totalProfitTitle: '总采购额',
    btnTitle: '采购',
    deleteTitle: '订单采购',
    dialogMessage: '您确认采购改订单吗？',
    affirm: '确认',
    close: '取消',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "订单产品详情",
    orderCode: "订单号:",
    productInformation: "产品信息",
    totalProfitTitleCard: '总利润额',
    statementPrice: "总采购额：",
    unitPrice: "销售单价",
    salesVolume: "销售总价",
    purchasePrices: "采购单价",
    purchasePricesTotal: "采购总价",
    profitPerPiece: "利润单价",
    profitPerPieceTotal: "利润总价",
    orderCardTitle: '支付',
    paymentMethods: "支付方式",
    paymentTime: "支付时间",
    returnedMoneyTitle: '回款',
    returnedMoneyMethods: '是否回款',
    returnedMoneyMethods1: '未回款',
    returnedMoneyMethods2: '已回款',
    returnedMoneyTime: '回款时间',
    logisticsInformation: '物流信息',
    logisticsInformationTitle: '点击查看最新物流动态',
    trackingNumber: '物流号码',
    orderNumber: '订单编号',
    logisticsCardTitle: '物流信息',
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: '请输入查询条件',
}
// 脚部语言
let footerLanguage = {
    home: '首页',
    order: '订单',
    Workbench: '工作台',
}
let chatPageMessage = {
    ladingText: '下载 . ...',
    loosingText: '发布更新..',
    pullingText: '下拉更新...',
    send: '发送',
    messageText: '消息'
}
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
})
