// 俄语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Обновление версии, пожалуйста, подтвердите? ', // Обновление версии, пожалуйста, подтвердите?
    loadingTitle: 'Загрузка...', // Загрузка...
    systemUpgrade: 'обновление системы', // обновление системы
    upgradeImmediately: 'Подтвердить', // Подтвердить
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'название магазина',
    pass: 'пароль',
    placeholderPass: 'Пожалуйста, введите ваш пароль',
    placeholderShopName: "Пожалуйста, введите название магазина",
    mail: "Почтовый ящик",
    placeholderMail: "Пожалуйста, введите адрес электронной почты",
    emailCode: "Код подтверждения по электронной почте",
    placeholderEmailCode: "Пожалуйста, введите код подтверждения",
    send: "Отправить",
    InvitationCode: 'Код приглашения',
    PlaceholderInvitationCode: 'Пожалуйста, введите код приглашения',
    documents: 'Загрузить документы',
    front: 'документ фронт',
    reverseSide: 'лицевая сторона документа',
    placeholderFront: 'Пожалуйста, загрузите лицевую часть сертификата',
    placeholderReverseSide: 'Пожалуйста, загрузите обратную сторону документа',
    btn: 'Применить',
    applicationBtn: 'Подтвердить',
    applicationNav: 'Соглашение о применении магазина',
    dialogTitle: 'Проверить успешность',
    dialogMessage: 'Заявка принята успешно, пожалуйста, терпеливо подождите, пока персонал проверит！',
    close: 'Подтвердить',
}
// 登录
let loginLanguage = {
    navTitle: 'логин', // логин
    warningTitle: 'Пожалуйста, введите адрес электронной почты для входа',
    passTitle: 'Пожалуйста, введите пароль для входа', // пароль
    forgetPassword: 'Забыли пароль? ',//Забыли пароль
    btnTitle: 'логин', // логин
    hintTitle: 'Нет учетной записи? перейти к регистрации', // зарегистрироваться
    linkTitle: 'обслуживание клиентов', // обслуживание клиентов
    AppDownload: 'Загрузить приложение', //Загрузить приложение
    goToShop: 'В торговый центр',
    register: 'зарегистрироваться'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Забыли пароль',
    email: 'электронная почта для входа',
    placeholderEmail: 'Пожалуйста, введите адрес электронной почты для входа',
    emailCode: 'Код подтверждения',
    placeholderEmailCode: 'Пожалуйста, введите код подтверждения',
    newPass: 'Новый пароль',
    placeholderNewPass: "Пожалуйста, введите новый пароль",
    affirmPass: 'Подтвердить пароль',
    placeholderAffirmPass: 'Пожалуйста, введите пароль дважды',
    submitBtn: 'отправить',
    affirm: 'подтверждать',
}

// 首页
let homeLanguage = {
    navTitle: 'Сегодняшние распродажи', // Главная
    orderTitle: 'Количество заказов сегодня',//Заказ
    balanceTitle: 'баланс',//баланс
    flowTitle: 'Счетчик трафика за сегодня', // Трафик за сегодня
    navTitleLine: 'Продажи', // Главная
    orderTitleLine: 'Количество ордеров',//Заказ
    flowTitleLine: 'Номер потока', //Сегодняшний поток
    rechargeTitle: 'Перезарядка', // перезарядка
    bankTitle: 'Снять', // Снять
    analysis: 'Обзор за последние 30 дней',//Заголовок линейного графика
    showTitle: 'Expand',//Развернуть линейный график
    selectAll: 'выбрать все',//выбрать все
    cancel: 'закрыть',//закрыть
    sure: 'подтвердить',//подтвердить
    revenueLanguage: 'налог',
    revenuepopupTitle: 'Налоговое напоминание',
    revenuepopupWarn: 'Для пополнения счета и уплаты налога',
    revenuepopupNeed: 'Налог в настоящее время подлежит уплате',
    revenuepopupSurn: 'подтвердить',
    outstandingAmount: 'непогашенная сумма',
    outstandingOrders: 'выполненные заказы',
    totalSalesYesterday: 'Общий объем продаж вчера',
    yesterdaySalesOrders: 'Вчерашние заказы на продажу',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Перезарядка', // Перезарядка
    currencyType1: 'Фиатная валюта',
    currencyType2: 'Виртуальная валюта',
    pleaseChoose: "Пожалуйста, выберите",
    cancelBtn: 'Отмена',
    submitBtn: 'подтвердить', // подтвердить
    placeholderNav: 'Введите сумму пополнения', // текущий баланс
    placeholderCurrent: 'Пожалуйста, введите сумму пополнения', // введите сумму
    dialogMessage: 'Пожалуйста, выберите тип валюты. ',
    pathTitle: 'метод пополнения',// канал
    hintTitle: 'Важно:',
    hintContent: 'Разрешено импортировать только <assets',
    success: 'Копировать успешно! ',
    hintPopup: 'Пожалуйста, обратитесь в службу поддержки клиентов!',
    hintTile: 'Быстрая информация'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Снять', // Снять
    currencyType1: 'Фиатная валюта',
    currencyType2: 'Виртуальная валюта',
    pleaseChoose: "Пожалуйста, выберите",
    cancelBtn: 'Отмена',
    submitBtn: 'подтвердить', // подтвердить
    placeholderNav: 'Введите сумму вывода',
    placeholderCurrent: 'Пожалуйста, введите сумму вывода', // введите сумму
    currentBalance: 'Текущий баланс', // Текущий баланс
    passwordTips: 'Пожалуйста, введите пароль для вывода средств', // введите сумму
    pathTitle: 'обменный курс', // канал
    rateTips: 'Текущий коэффициент конверсии',
    dialogMessage: 'Пожалуйста, выберите тип валюты. ',
    hintTitle: 'Важно:',
    hintContent: 'Разрешено импортировать только <assets',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'ошибка', // ошибка
    loadingTitle: 'Загрузка', // Загрузка
    finishedText: "нет", //нет
    loadingText: 'загрузка', // загрузка
    wait: 'Пожалуйста, подождите минутку',
    placeholderHite: 'Введено неверное значение',
    uploadError: 'Ошибка загрузки',
    upload: 'Загружается',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'личный центр', // личный центр
    bankCard: 'Настройки вывода', // Адрес вывода
    productLibrary: 'управление продуктами', //библиотека продуктов
    financialManagement: 'Запись фонда', //финансовый менеджмент
    reportStatistics: 'Бизнес-отчет', //Статистика
    accountInformation: 'Информация о магазине', //Переключить язык
    setting: 'Системные настройки',
    goToShop: 'В торговый центр',
    goToLoan: 'кредит',
    downloadApp: 'Скачать приложение',
    activity: 'Активность'
}
// 活动页面
let activityLanguage = {
    navTitle: 'Центр действий',
    discount: 'скидка'
}
let discountLanguage = {
    navTitle: 'скидка',
    affirmBtn: 'Добавить',
    submitBtn: 'отправить',
    accain: 'Полный',
    amount: 'сумма',
    ratio: 'Скидка',
    plusAffirm: 'подтвердить',
    placeholderEnter: 'пожалуйста, введите'
}

// 下载
let downloadApp = {
    navTitle: 'Скачать приложение',
    downloadAndroidApp: 'загрузить приложение для Android',
    downloadIosApp: 'загрузить приложение ios',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Библиотека продуктов', // Библиотека продуктов
    productList: 'Продукт магазина', // Список товаров
    productLibraryTitle: 'Продукт платформы', // библиотека продуктов
    purchasePrice: 'Цена покупки',
    sellingPrice: 'Цена продажи',
    selectAll: 'выбрать все',
    affirm: 'подтверждать',
    close: 'Отмена',
    editPrice: 'Изменить цену',
    deleteTitle: 'Удалить заказ',
    dialogMessage: 'Вы уверены, что хотите удалить этот продукт? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Фондовые записи',
    tabs1Title: 'Все средства', // Ликвидность
    tabs2Title: 'запись пополнения', // запись пополнения
    tabs3Title: 'запись о снятии средств',//запись о снятии средств
    selectionPeriod: 'выбор времени',//выберите время
    rechargeTitle: 'перезарядка',// перезарядка
    wthdrawTitle: 'Снятие средств',// Снятие средств
    successful: 'успех',//успех
    balance: 'баланс', // баланс
    rechargeTime: 'время перезарядки', // время перезарядки
    rechargeMessage: 'тип валюты',// метод пополнения
    wthdrawTime: 'время вывода',// время перезарядки
    wthdrawMessage: 'тип валюты', // метод пополнения
    currency: "валюта",// валюта
    timeAffirm: 'Подтвердить',
    timeCancel: 'Отмена',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Общий объем продаж',
    totalPurchase: 'Общая сумма покупки',
    totalProfit: 'Общая прибыль',
    selectionPeriod: 'фильтр времени',//выберите время
    analysis: 'линейный график',//название линейного графика
    showTitle: 'Expand',//Развернуть линейный график
    selectAll: 'выбрать все',//выбрать все
    cancel: 'закрыть',//закрыть
    sure: 'подтвердить',//подтвердить
    fundingDetails: 'подробные данные', // детали финансирования
    typeSelection: 'тип выбора',//тип выбора
    purchaseAmount: 'Сумма покупки', //Продажи
    profitAmount: 'Сумма прибыли',//Общий объем продаж
    unitAmount: 'Продажи', // покупка в рассрочку
    grandTotal: 'кумулятивный',
    timeAffirm: 'Подтвердить',
    timeCancel: 'Отмена',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Список управления',
    selectionPeriod: 'Выберите время',//Выберите время
    totalPurchase: 'сумма покупки',
    totalSales: 'сумма прибыли',
    totalProfit: 'Продажи',
    grandTotal: 'кумулятивный',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Информация о магазине',
    picture: 'изображение магазина',
    nameTitle: 'Название магазина',
    Mail: 'Электронная почта для входа',
    invitationCode: 'Код приглашения',
    loginPassword: 'пароль для входа',
    paymentPassword: 'платежный пароль',
    documents: 'информация о документе',
    frontDocument: 'передний план документа',
    backDocument: 'оборотная сторона документа',
    exit: 'выйти',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Настройки вывода средств', // Платежный адрес
    cashCurrency: 'Фиатная валюта',
    virtualCurrency: 'Виртуальная валюта',
    pleaseChoose: 'Пожалуйста, выберите',
    popupAffirm: 'Подтвердить',
    popupCancel: 'Отмена',
    hintTitle: 'Важно:', // Поддерживает вывод только на < адрес.
    hintContent: 'Разрешено импортировать только < assets. ', // Поддерживает вывод только на < адрес.
    affirmBtn: 'подтвердить', // подтвердить
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'настоящее имя',
    email: 'электронная почта',
    mobilePhone: 'Номер мобильного телефона',
    pixType: 'Тип PIX',
    pixKey: 'Учетная запись PIX',
    pleaseEnter: 'Пожалуйста, введите',
    pleaseSelect: 'Пожалуйста, выберите',

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'пожалуйста, введите',
    country: 'страна/регион',
    name: 'имя',
    surname: 'фамилия',
    bsb: 'бсб',
    bankName: 'Название банка',
    bankAccount: 'Банковский счет',
    state: 'Провинция или штат',
    mobilePhone: 'Номер мобильного телефона',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'пожалуйста, введите',
    country: 'страна/регион',
    name: 'имя',
    surname: 'фамилия',
    swift: 'быстрый',
    bankName: 'Название банка',
    iban: 'ибан',
    mobilePhone: 'Номер мобильного телефона',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'пожалуйста, введите',
    country: 'страна/регион',
    name: 'имя',
    surname: 'фамилия',
    bankCode: 'код банка',
    bankName: 'Название банка',
    bankAccount: 'Банковский счет',
    mobilePhone: 'Номер мобильного телефона',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'пожалуйста, введите',
    country: 'страна/регион',
    name: 'имя',
    surname: 'фамилия',
    zengin: 'зенгин',
    bankName: 'Название банка',
    bankAccount: 'Банковский счет',
    county: 'округ',
    mobilePhone: 'Номер мобильного телефона',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'пожалуйста, введите',
    country: 'страна/регион',
    name: 'имя',
    surname: 'фамилия',
    swift: 'быстрый',
    ach: 'ах',
    bankName: 'Название банка',
    bankAccount: 'Банковский счет',
    state: 'Провинция или штат',
    mobilePhone: 'Номер мобильного телефона',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'пожалуйста, введите',
    name: 'имя',
    surname: 'фамилия',
    bankName: 'Название банка',
    bankAccount: 'Банковский счет',
    mobilePhone: 'Номер мобильного телефона',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Пожалуйста, введите',
    bankName: 'Название банка',
    account: 'Учетная запись',
    clabe: 'учетная запись clabe',
    mobilePhone: 'Номер мобильного телефона',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Адрес',
    pleaseInformation: 'Пожалуйста, введите адрес виртуальной валюты'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Изменение пароля для входа', // пароль для входа
    oldPass: 'старый пароль', // старый пароль
    newPass: 'новый пароль', // новый пароль
    email: 'Код подтверждения',
    placeholderOldPass: 'Пожалуйста, введите старый пароль',
    placeholderNewPass: 'Пожалуйста, введите новый пароль',
    placeholderEmailCode: 'Пожалуйста, введите код подтверждения',
    submitBtn: 'отправить', // подтвердить
    affirm: 'подтвердить', // подтвердить

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Изменение платежного пароля', // Платежный пароль
    affirmPass: 'платежный пароль',
    email: 'Код подтверждения', // Подтвердите пароль
    submitBtn: 'Отправить', // Подтвердить
    affirm: 'подтвердить', // подтвердить
    placeholder: 'Пожалуйста, введите',
    placeholderPaymentPassword: 'Пожалуйста, введите платежный пароль',
    placeholderEmailCode: 'Пожалуйста, введите код подтверждения',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Системные настройки', // Настройка
    languageShift: 'Многоязычные настройки', // смена языка
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Системные настройки', // Настройка
    affirm: 'Многоязычные настройки', // смена языка
}
//  公告
let afficheDetail = {
    navTitle: 'сообщение', // уведомление
    comment: 'ответить',// комментарий
    unreadMessage: 'непрочитано',
    readTitle: 'читать'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'детали сообщения', // уведомление
    comment: 'комментарий',// комментарий
    check: 'Просмотр',
    packUpPlaceholder: 'Пожалуйста, введите содержание ответа',
    sendInform: 'отправить'
}

// 客服
let serviceLanguage = {
    navTitle: 'Обслуживание клиентов', // Обслуживание клиентов
    customerService: 'эксклюзивное обслуживание клиентов', // обслуживание клиентов
    helloTitle: 'Привет',
    welcomeTitle: 'Я твой <, вы можете связаться со мной следующими способами',
    iconInformation: 'Пожалуйста, выберите коммуникационное программное обеспечение для консультации',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Управление заказами", // Список заказов
    tabsTodos: "Куплено", // все
    tabsPendente: "для покупки", // для покупки
    tabsCompleto: "В логистике", // в логистике
    tabsCancelar: "Завершено", // Завершено
    totalProfit: 'Общая прибыль',
    statementPrice: "Общая сумма покупки", // Общая сумма:
    affirmBtn: "Покупка", // подтвердить
    orderTotal: "Всего заказов",
    orderCode: "Номер заказа:",
    childrenDetail: 'продукты', // детали
    checkDetail: 'Детали производства', // Посмотреть подробности
    buyAll: 'Купить все',
    dialogTitle: 'Информация о покупке',
    dialogTotalSales: 'Общий объем продаж',
    dialogTotalPurchase: 'Общая сумма покупки',
    dialogTotalProfit: 'Общая прибыль',
    dialogBtn: 'Покупка',
    deleteTitle: 'Заказать покупку',
    dialogMessage: 'Вы уверены, что хотите приобрести этот заказ? ',
    dialogAllMessage: 'Вы уверены, что купили все заказы? ',
    affirm: 'подтверждать',
    close: 'Отмена',
    selectionPeriod: 'выбор времени',//выберите время
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Детали заказа", // Список заказов
    orderCode: "Номер заказа:",//Номер заказа
    totalProfitTitleCard: 'Общая прибыль',
    statementPrice: "Общая сумма покупки", // Общая сумма:
    amountRelated: 'Сумма заказа',
    totalSalesTitle: 'Общий объем продаж',
    totalPurchase: 'Общая прибыль',
    totalProfitTitle: 'Всего покупок',
    btnTitle: 'Покупка',
    deleteTitle: 'Заказать покупку',
    dialogMessage: 'Подтверждаете ли вы изменение заказа на поставку? ',
    affirm: 'подтверждать',
    close: 'Отмена',
    salesTitle: 'Сумма продаж',
    discount: 'коэффициент продаж',
    salesAmount: 'Фактическая сумма продаж',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "детали заказа", // список заказов
    orderCode: "Номер заказа:",//Номер заказа
    productInformation: "Информация о продукте", // Информация о продукте
    totalProfitTitleCard: 'Общая прибыль',
    statementPrice: "Общая сумма покупки:", // Общая сумма:
    unitPrice: "цена за единицу", // цена за единицу
    salesVolume: "Общая цена продажи", // Объем продаж
    purchasePrices: "Цена покупки за единицу", // Цена покупки
    purchasePricesTotal: "Общая цена покупки", // цена покупки
    profitPerPiece: "цена за единицу прибыли", // прибыль
    profitPerPieceTotal: "Общая цена прибыли", // Прибыль
    orderCardTitle: 'Платеж',
    paymentMethods: "Способ оплаты", // Создать время жизни
    paymentTime: "время платежа", // время платежа
    returnedMoneyTitle: 'возврат денег',
    returnedMoneyMethods: 'Вернут ли деньги',
    returnedMoneyMethods1: 'Не возвращено',
    returnedMoneyMethods2: 'Деньги возвращены',
    returnedMoneyTime: 'Время возврата',
    logisticsInformation: 'Логистическая информация',
    logisticsInformationTitle: 'Нажмите, чтобы просмотреть последние новости логистики',
    trackingNumber: 'Номер логистики',
    orderNumber: 'Номер заказа',
    logisticsCardTitle: 'Информация по логистике'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Пожалуйста, введите условия запроса',
}
// 脚部语言
let footerLanguage = {
    home: 'Home',
    order: 'order',
    Workbench: 'Workbench',
}
let chatPageMessage = {
    ladingText: 'Скачать...',
    loosingText: 'Публиковать обновления...',
    pullingText: 'Обновление раскрывающегося списка...',
    send: 'Отправить',
    messageText: 'новости',
    connecting: 'Подключение службы обмена мгновенными сообщениями...',
    connectionSuccess: 'Подключение службы обмена мгновенными сообщениями выполнено успешно',
    connectionFail: 'Ошибка подключения к службе обмена мгновенными сообщениями',
    connectionSendFail: 'служба обмена мгновенными сообщениями не подключена, отправка не удалась',
}
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage
})
