import Vue from 'vue'
import {
    PullRefresh,
    Dialog,
    Picker,
    Uploader,
    Grid,
    GridItem,
    Swipe,
    Button,
    Icon,
    Field,
    SwipeItem,
    Tabbar,
    TabbarItem,
    Tab,
    Tabs,
    Cell,
    CellGroup,
    Calendar,
    DatetimePicker,
    Popup,
    Form,
    Checkbox,
    CheckboxGroup,
    Toast,
    List,
    Loading,
    DropdownMenu,
    DropdownItem,
    Search,

} from 'vant';
import 'vant/lib/index.css'
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(PullRefresh);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(DropdownMenu);
Vue.use(Search);
Vue.use(DropdownItem);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(List);
Vue.use(CheckboxGroup);
Vue.use(Cell);
Vue.use(Form);
Vue.use(CellGroup)
Vue.use(Calendar);
Vue.use(Field);
Vue.use(Button)
Vue.use(Field)
Vue.use(Icon);
Vue.use(GridItem);
Vue.use(Grid);
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(DatetimePicker)
Vue.use(Popup)


Vue.use(Checkbox);
Vue.use(CheckboxGroup);