// 荷兰语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Versie-upgrade, gelieve te bevestigen? ',// Versie-upgrade, gelieve te bevestigen?
    loadingTitle: 'Downloaden...', // Downloaden...
    systemUpgrade: 'systeem upgrade', // systeem upgrade
    upgradeImmediately: 'Bevestigen', // Bevestigen
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'winkelnaam',
    pass: 'wachtwoord',
    placeholderPass: 'Voer uw wachtwoord in',
    placeholderShopName: "Voer de naam van de winkel in",
    mail: "brievenbus",
    placeholderMail: "Voer uw e-mailadres in",
    emailCode: "E-mailverificatiecode",
    placeholderEmailCode: "Voer de verificatiecode in",
    send: "Verzenden",
    InvitationCode: 'Uitnodigingscode',
    PlaceholderInvitationCode: 'Voer de uitnodigingscode in',
    documents: 'Documenten uploaden',
    front: 'document voorzijde',
    reverseSide: 'document voorzijde',
    placeholderFront: 'Upload de voorkant van het certificaat',
    placeholderReverseSide: 'Upload a.u.b. de achterkant van het document',
    btn: 'Toepassen',
    applicationBtn: 'Bevestigen',
    applicationNav: 'Applicatieovereenkomst opslaan',
    dialogTitle: 'Bekijk het succes',
    dialogMessage: 'De aanvraag is succesvol, wacht geduldig tot het personeel dit beoordeelt！',
    close: 'Bevestigen',
}
// 登录
let loginLanguage = {
    navTitle: 'inloggen', // inloggen
    warningTitle: 'Voer uw login e-mailadres in',
    passTitle: 'Voer het inlogwachtwoord in', // wachtwoord
    forgetPassword: 'Wachtwoord vergeten? ',//Wachtwoord vergeten
    btnTitle: 'inloggen', // inloggen
    hintTitle: 'Geen account? ga naar registreren', // registreren
    linkTitle: 'klantenservice', // klantenservice
    AppDownload: 'Download app', //Download app
    goToShop: 'Naar het winkelcentrum',
    register: 'registreren'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Wachtwoord vergeten',
    email: 'inlog e-mail',
    placeholderEmail: 'Voer uw login e-mailadres in',
    emailCode: 'Verificatiecode',
    placeholderEmailCode: 'Voer de verificatiecode in',
    newPass: 'Nieuw wachtwoord',
    placeholderNewPass: "Voer een nieuw wachtwoord in",
    affirmPass: 'Bevestig wachtwoord',
    placeholderAffirmPass: 'Voer het wachtwoord twee keer in',
    submitBtn: 'verzenden',
    affirm: 'bevestigen',
}

// 首页
let homeLanguage = {
    navTitle: 'Verkopen van vandaag', // Home
    orderTitle: 'Aantal bestellingen vandaag',//Order
    balanceTitle: 'balance',//balance
    flowTitle: 'Today\'s traffic count', //Today's traffic
    navTitleLine: 'Verkoop', // Thuis
    orderTitleLine: 'Aantal bestellingen',//Bestelling
    flowTitleLine: 'Flownummer', //De flow van vandaag
    rechargeTitle: 'Opladen', // opladen
    bankTitle: 'Opnemen', // Opnemen
    analysis: 'Overzicht van de laatste 30 dagen',//Lijndiagramtitel
    showTitle: 'Uitvouwen', // Vouw het lijndiagram uit
    selectAll: 'selecteer alles',//selecteer alles
    cancel: 'sluiten',//sluiten
    sure: 'bevestigen',//bevestigen
    revenueLanguage: 'belasting',
    revenuepopupTitle: 'Belastingherinnering',
    revenuepopupWarn: 'Opwaarderen en belasting betalen',
    revenuepopupNeed: 'Belasting is momenteel verschuldigd',
    revenuepopupSurn: 'bevestigen',
    outstandingAmount: 'uitstaand bedrag',
    outstandingOrders: 'uitstaande bestellingen',
    totalSalesYesterday: 'Totale verkoop gisteren',
    yesterdaySalesOrders: 'Verkooporders van gisteren',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Opladen', // Opladen
    currencyType1: 'Fiat-valuta',
    currencyType2: 'Virtuele valuta',
    pleaseChoose: "Kies alstublieft",
    cancelBtn: 'Annuleren',
    submitBtn: 'bevestigen', // bevestigen
    placeholderNav: 'Voer het oplaadbedrag in', // huidig saldo
    placeholderCurrent: 'Voer het oplaadbedrag in', // voer het bedrag in
    dialogMessage: 'Selecteer het valutatype. ',
    pathTitle: 'oplaadmethode',// kanaal
    hintTitle: 'Belangrijk:',
    hintContent: 'Alleen toegestaan om <activa te importeren',
    success: 'Kopiëren geslaagd! ',
    hintPopup: 'Gelieve contact op te nemen met de klantenservice!',
    hintTile: 'Informatie vragen'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Terugtrekken', // Terugtrekken
    currencyType1: 'Fiat-valuta',
    currencyType2: 'Virtuele valuta',
    pleaseChoose: "Kies alstublieft",
    cancelBtn: 'Annuleren',
    submitBtn: 'bevestigen', // bevestigen
    placeholderNav: 'Voer het opnamebedrag in',
    placeholderCurrent: 'Voer het opnamebedrag in', // voer het bedrag in
    currentBalance: 'Huidig saldo', // Huidig saldo
    passwordTips: 'Voer het opnamewachtwoord in', // voer het bedrag in
    pathTitle: 'wisselkoers', // kanaal
    rateTips: 'Huidige wisselkoers',
    dialogMessage: 'Selecteer het valutatype. ',
    hintTitle: 'Belangrijk:',
    hintContent: 'Alleen toegestaan om <activa te importeren',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'fout', // fout
    loadingTitle: 'Bezig met laden', // Bezig met laden
    finishedText: "geen", // geen
    loadingText: 'laden', // laden
    wait: 'Even geduld alstublieft',
    placeholderHite: 'De invoerwaarde is onjuist',
    uploadError: 'Upload mislukt',
    upload: 'Uploaden',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'persoonlijk centrum', // persoonlijk centrum
    bankCard: 'Instellingen voor opname', // Adres voor opname
    productLibrary: 'productbeheer', //productbibliotheek
    financialManagement: 'Fondsoverzicht', //financieel beheer
    reportStatistics: 'Bedrijfsrapport', //Statistics
    accountInformation: 'Bewaar informatie', //Taal wisselen
    setting: 'Systeeminstellingen',
    goToShop: 'Naar het winkelcentrum',
    goToLoan: 'lening',
    downloadApp: 'Download app',
    activity: 'Activiteit'
}
// 活动页面
let activityLanguage = {
    navTitle: 'activiteitencentrum',
    discount: 'korting'
}
let discountLanguage = {
    navTitle: 'korting',
    affirmBtn: 'Toevoegen',
    submitBtn: 'verzenden',
    accain: 'Vol',
    amount: 'het bedrag',
    ratio: 'Korting',
    plusAffirm: 'bevestigen',
    placeholderEnter: 'vul in'
}

// 下载
let downloadApp = {
    navTitle: 'App downloaden',
    downloadAndroidApp: 'download Android-app',
    downloadIosApp: 'download ios-app',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Productbibliotheek', // Productbibliotheek
    productList: 'Product opslaan', // Productlijst
    productLibraryTitle: 'Platformproduct', // productbibliotheek
    purchasePrice: 'Aankoopprijs',
    sellingPrice: 'Verkoopprijs',
    selectAll: 'alles selecteren',
    affirm: 'bevestigen',
    close: 'Annuleren',
    editPrice: 'Prijs wijzigen',
    deleteTitle: 'Bestelling verwijderen',
    dialogMessage: 'Weet u zeker dat u dit product wilt verwijderen? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Fondsadministratie',
    tabs1Title: 'Alle fondsen', // Liquiditeit
    tabs2Title: 'oplaadrecord', // oplaadrecord
    tabs3Title: 'intrekkingsrecord',//intrekkingsrecord
    selectionPeriod: 'tijdselectie',//selecteer tijd
    rechargeTitle: 'opladen',//opladen
    wthdrawTitle: 'Intrekken',// Intrekken
    successful: 'succes',//succes
    balance: 'balans', // balans
    rechargeTime: 'oplaadtijd',// oplaadtijd
    rechargeMessage: 'valutatype',// oplaadmethode
    wthdrawTime: 'opnametijd',// oplaadtijd
    wthdrawMessage: 'valutatype',// oplaadmethode
    currency: "valuta", // valuta
    timeAffirm: 'Bevestigen',
    timeCancel: 'Annuleren',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Totale verkoop',
    totalPurchase: 'Totaal aankoopbedrag',
    totalProfit: 'Totale winst',
    selectionPeriod: 'tijdfilter',//selecteer tijd
    analysis: 'lijndiagram',//titel lijndiagram
    showTitle: 'Uitvouwen', // Vouw het lijndiagram uit
    selectAll: 'selecteer alles',//selecteer alles
    cancel: 'sluiten',//sluiten
    sure: 'bevestigen',//bevestigen
    fundingDetails: 'gedetailleerde gegevens', // details van financiering
    typeSelection: 'selectietype',//selectietype
    purchaseAmount: 'Aankoopbedrag', //Verkoop
    profitAmount: 'Winstbedrag',//Totale verkoop
    unitAmount: 'Verkoop', // aankoop op afbetaling
    grandTotal: 'cumulatief',
    timeAffirm: 'Bevestigen',
    timeCancel: 'Annuleren',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Beheerlijst',
    selectionPeriod: 'Selecteer tijd',//Selecteer tijd
    totalPurchase: 'aankoopbedrag',
    totalSales: 'winstbedrag',
    totalProfit: 'Verkoop',
    grandTotal: 'cumulatief',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Winkelinformatie',
    picture: 'winkelafbeelding',
    nameTitle: 'Winkelnaam',
    Mail: 'Inlog-e-mail',
    invitationCode: 'Uitnodigingscode',
    loginPassword: 'inlogwachtwoord',
    paymentPassword: 'betalingswachtwoord',
    documents: 'documentinformatie',
    frontDocument: 'document voorzijde',
    backDocument: 'achterkant van het document',
    exit: 'uitloggen',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Opname-instellingen', // Betalingsadres
    cashCurrency: 'Fiat-valuta',
    virtualCurrency: 'Virtuele valuta',
    pleaseChoose: 'Maak een keuze',
    popupAffirm: 'Bevestigen',
    popupCancel: 'Annuleren',
    hintTitle: 'Belangrijk:', // Ondersteunt alleen opname naar < adres.
    hintContent: 'Alleen toegestaan om < items te importeren. ', // Ondersteunt alleen opname naar < adres.
    affirmBtn: 'bevestigen', // bevestigen
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'echte naam',
    email: 'e-mail',
    mobilePhone: 'Mobiel telefoonnummer',
    pixType: 'PIX-type',
    pixKey: 'PIX-account',
    pleaseEnter: 'Vul in',
    pleaseSelect: 'Maak een keuze',

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'vul in',
    country: 'land/regio',
    name: 'naam',
    surname: 'achternaam',
    bsb: 'BSB',
    bankName: 'Banknaam',
    bankAccount: 'Bankrekening',
    state: 'Provincie of Staat',
    mobilePhone: 'Mobiel telefoonnummer',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'vul in',
    country: 'land/regio',
    name: 'naam',
    surname: 'achternaam',
    swift: 'snel',
    bankName: 'Banknaam',
    iban: 'iban',
    mobilePhone: 'Mobiel telefoonnummer',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'vul in',
    country: 'land/regio',
    name: 'naam',
    surname: 'achternaam',
    bankCode: 'bankcode',
    bankName: 'Banknaam',
    bankAccount: 'Bankrekening',
    mobilePhone: 'Mobiel telefoonnummer',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'vul in',
    country: 'land/regio',
    name: 'naam',
    surname: 'achternaam',
    zengin: 'zengin',
    bankName: 'Banknaam',
    bankAccount: 'Bankrekening',
    county: 'provincie',
    mobilePhone: 'Mobiel telefoonnummer',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'vul in',
    country: 'land/regio',
    name: 'naam',
    surname: 'achternaam',
    swift: 'snel',
    ach: 'ach',
    bankName: 'Banknaam',
    bankAccount: 'Bankrekening',
    state: 'Provincie of Staat',
    mobilePhone: 'Mobiel telefoonnummer',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'vul in',
    name: 'naam',
    surname: 'achternaam',
    bankName: 'Banknaam',
    bankAccount: 'Bankrekening',
    mobilePhone: 'Mobiel telefoonnummer',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Vul in',
    bankName: 'Banknaam',
    account: 'Rekening',
    clabe: 'clabe rekening',
    mobilePhone: 'Mobiel telefoonnummer',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Adres',
    pleaseInformation: 'Voer het adres van de virtuele valuta in'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Inlogwachtwoord wijzigen', // inlogwachtwoord
    oldPass: 'oud wachtwoord', // oud wachtwoord
    newPass: 'nieuw wachtwoord', // nieuw wachtwoord
    email: 'Verificatiecode',
    placeholderOldPass: 'Voer het oude wachtwoord in',
    placeholderNewPass: 'Voer een nieuw wachtwoord in',
    placeholderEmailCode: 'Voer de verificatiecode in',
    submitBtn: 'verzenden', // bevestigen
    affirm: 'bevestigen', // bevestigen

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Betalingswachtwoord wijzigen', // Betalingswachtwoord
    affirmPass: 'betalingswachtwoord',
    email: 'Verificatiecode', // Wachtwoord bevestigen
    submitBtn: 'Verzenden',// Bevestigen
    affirm: 'bevestigen', // bevestigen
    placeholder: 'Gelieve in te voeren',
    placeholderPaymentPassword: 'Voer het betalingswachtwoord in',
    placeholderEmailCode: 'Voer de verificatiecode in',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Systeeminstellingen', // Instelling
    languageShift: 'Meertalige instellingen', // taalverschuiving
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Meertalige instellingen',
    affirm: 'bevestigen'
}
//  公告
let afficheDetail = {
    navTitle: 'bericht', // melding
    comment: 'antwoord',// opmerking
    unreadMessage: 'ongelezen',
    readTitle: 'lezen'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'berichtdetails', // melding
    comment: 'commentaar',// commentaar
    check: 'Bekijken',
    packUpPlaceholder: 'Voer de inhoud van het antwoord in',
    sendInform: 'verzenden'
}

// 客服
let serviceLanguage = {
    navTitle: 'Klantenservice', // Klantenservice
    customerService: 'exclusieve klantenservice',//klantenservice
    helloTitle: 'Hallo',
    welcomeTitle: 'Ik ben van jou <, je kunt op de volgende manieren contact met me opnemen',
    iconInformation: 'Selecteer een communicatiesoftware voor raadpleging',
    whatsApp: 'Whatsapp',
    telegram: 'telegram',
    line: 'lijn',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Bestelbeheer", // Bestellijst
    tabsTodos: "gekocht", // alles
    tabsPendente: "aan te schaffen", // aan te schaffen
    tabsCompleto: "In de logistiek", // in de logistiek
    tabsCancelar: "Voltooid", // Voltooid
    totalProfit: 'Totale winst',
    statementPrice: "Totaal aankoopbedrag", // Totaalbedrag:
    affirmBtn: "Aankoop", // bevestigen
    orderTotal: "Totaal aantal bestellingen",
    orderCode: "Bestelnummer:",
    childrenDetail: 'producten', // details
    checkDetail: 'Productiedetails', // Bekijk details
    buyAll: 'Koop alles',
    dialogTitle: 'Inkoopinformatie',
    dialogTotalSales: 'Totale verkoop',
    dialogTotalPurchase: 'Totaal aankoopbedrag',
    dialogTotalProfit: 'Totale winst',
    dialogBtn: 'Aankoop',
    deleteTitle: 'Aankoop bestellen',
    dialogMessage: 'Weet u zeker dat u deze bestelling wilt kopen? ',
    dialogAllMessage: 'Weet u zeker dat u alle bestellingen wilt kopen? ',
    affirm: 'bevestigen',
    close: 'Annuleren',
    selectionPeriod: 'tijdselectie',//selecteer tijd
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Bestelgegevens", // Bestellijst
    orderCode: "Bestelnummer:",//Bestelnummer
    totalProfitTitleCard: 'Totale winst',
    statementPrice: "Totaal aankoopbedrag", // Totaalbedrag:
    amountRelated: 'Bestelbedrag',
    totalSalesTitle: 'Totale verkoop',
    totalPurchase: 'Totale winst',
    totalProfitTitle: 'Totaal aankopen',
    btnTitle: 'Aankoop',
    deleteTitle: 'Aankoop bestellen',
    dialogMessage: 'Bevestigt u de wijziging van de bestelling? ',
    affirm: 'bevestigen',
    close: 'Annuleren',
    salesTitle: 'Verkoopbedrag',
    discount: 'verkoopratio',
    salesAmount: 'Werkelijk verkoopbedrag',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "productdetails bestellen", // bestellijst
    orderCode: "Bestelnummer:",//Bestelnummer
    productInformation: "Productinformatie", // Productinformatie
    totalProfitTitleCard: 'Totale winst',
    statementPrice: "Totaal aankoopbedrag:", // Totaalbedrag:
    unitPrice: "eenheidsprijs", // eenheidsprijs
    salesVolume: "Totale verkoopprijs", // Verkoopvolume
    purchasePrices: "Aankoop Eenheidsprijs", // Aankoopprijs
    purchasePricesTotal: "Totale aankoopprijs", // aankoopprijs
    profitPerPiece: "winst eenheidsprijs", // winst
    profitPerPieceTotal: "Totale winstprijs", // Winst
    orderCardTitle: 'Betaling',
    paymentMethods: "Betaalmethode", // Levensduur genereren
    paymentTime: "betalingstijd", // betalingstijd
    returnedMoneyTitle: 'geld teruggegeven',
    returnedMoneyMethods: 'Of het geld wordt teruggegeven',
    returnedMoneyMethods1: 'Niet terugbetaald',
    returnedMoneyMethods2: 'Het geld is teruggestort',
    returnedMoneyTime: 'Retourtijd',
    logisticsInformation: 'Logistieke Informatie',
    logisticsInformationTitle: 'Klik om het laatste logistieke nieuws te bekijken',
    trackingNumber: 'Logistiek nummer',
    orderNumber: 'Bestelnummer',
    logisticsCardTitle: 'Logistieke informatie'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Voer de vraagvoorwaarden in',
}
// 脚部语言
let footerLanguage = {
    home: 'huis',
    order: 'bestellen',
    Workbench: 'Werkbank',
}
let chatPageMessage = {
    ladingText: 'Download...',
    loosingText: 'Updates publiceren...',
    pullingText: 'Dropdown-update...',
    send: 'Verzenden',
    messageText: 'nieuws',
    connecting: 'IM-service verbinden...',
    connectionSuccess: 'IM service verbinding is geslaagd',
    connectionFail: 'Verbinding met IM-service mislukt',
    connectionSendFail: 'IM-service is niet verbonden, verzenden mislukt',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Nodig vrienden uit om beloningen te verdienen',
    qrCodeLanguage: 'Kopiëren',
    ruleTitle: 'Uitnodigingsregels',
    rule1: 'De uitnodiger kan vrienden uitnodigen om de registratie te voltooien door de promotielink te delen, en de uitgenodigde vrienden worden aan jou gekoppeld;',
    rule2: 'Nadat je gebruikers hebt uitgenodigd om de betaling te voltooien, kun je een deel van hun inkomsten krijgen;',
    rule3: 'Ondersteunt alleen uitnodigingsbeloningen op drie niveaus, de beloningsverhouding is: <%, >%, @%;',
    rule4: 'De uitnodigingsmethode moet legaal zijn en in overeenstemming met de regels voor het uitnodigen van vrienden. Als er sprake is van een ongepaste uitnodigingsmethode of gedrag dat in strijd is met de regels voor het uitnodigen van vrienden, worden alle niet-uitgegeven beloningen afgetrokken zodra dit is geverifieerd. Het platform behoudt zich het recht voor om alle uitgegeven beloningen te traceren en, indien van toepassing, wettelijke verantwoordelijkheid na te streven. ',
    situation: 'Het laatste uitnodigingsrecord',
    copySuccess: 'Kopiëren gelukt',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Gebruikersrecord uitnodigen',
    cardTitle: 'Totaal aantal gebruikers',
    firstLevelUser: 'niveau één',
    secondaryUser: 'secundaire',
    tertiaryUser: 'Tertiair',

    tabs1Title: 'Alle',
    tabs2Title: 'niveau één',
    tabs3Title: 'Secundair',
    tabs4Title: 'Derde niveau',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Inkomstenrecord uitnodiging',
    cardTitle: 'Totale omzet',
    placeholder: 'Gelieve in te voeren',
    firstLevelUser: 'niveau één',
    secondaryUser: 'secundaire',
    tertiaryUser: 'Tertiair',
    store: 'totaalbedrag',
    ratio: 'verhouding',
    commission: 'inkomen',

    tabs1Title: 'Alle',
    tabs2Title: 'niveau één',
    tabs3Title: 'Secundair',
    tabs4Title: 'Derde niveau',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Verkoopbeheer',
    navContent: 'Order product verkoop ranking',
    tabs1: 'Verkoop',
    tabs2: 'Totaalbedrag',
    tabs3: 'Winstbedrag',
    searchPlaceholder: 'Productnaam',
    date1Placeholder: 'Starttijd',
    date2Placeholder: 'Eindtijd',
    salesVolume: 'verkoop',
    totalPrice: 'totale verkoop',
    shelfTime: 'Totale winst',
    ranking: 'NEE',
    timeAffirm: 'Bevestigen',
    timeCancel: 'Annuleren'
};
let rankingItemLanguage = {
    navTitle: 'Productverkooprecord',
    date1Placeholder: 'Starttijd',
    date2Placeholder: 'Eindtijd',
    tabs1: 'Verkoop',
    tabs2: 'tijd',
    salesTime: 'tijd',
    salesVolume: 'verkoop',
    totalAmount: 'totale verkoop',
    timeAffirm: 'Bevestigen',
    timeCancel: 'Annuleren'

};
// 个人中心新增
myCenterLanguage.invitation = 'uitnodiging'
myCenterLanguage.commission = 'commissie'

// 订单页面新增
orderPageLangage.orderBgTitle = 'Verkooprang, ik weet het'
orderPageLangage.orderBgLeaveFor = 'ga naar'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
