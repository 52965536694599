<template>
  <!-- 过渡页 -->
  <div class="loading_wrap">
    <div class="loader-box">
      <div class="loader-card">
        <div class="ele-animation">
          <img src="../../assets/ICON.png" alt="" />
        </div>
      </div>
      <div class="lable">{{ messageLanguage.loadingTitle }}...</div>
    </div>
  </div>
</template>
<script>
import '@/components/component/css/index.css'
import languageType from "@/components/component/js/language";
import { getParams } from "@/utils/publicMethods";
let Base64 = require('js-base64').Base64;
import transitionApi from '@/api/transitionApi'
export default {
  name: "transitionPageIndex",
  data () {
    return {
      language: {},
      messageLanguage: {},
      decodedStr: {},
      loginObj: {},
      routeEnum: {
        'goodsList': '/workbench/workbench-index/productLibrary-index',
        'shopCenter': '/home/home-index',
        'orderList': '/order/order-index'
      },
      strBase64: '',
      loadingSuccessCount: 1
    };
  },
  created () {
    this.onLoad()
  },
  methods: {
    onLoad () {
      this.getLanguage()
      this.linkBase64()

    },
    getLanguage () {
      const localLanguage = localStorage.getItem("languageType")
      this.language = languageType(localLanguage);
      this.messageLanguage = this.language.messageLanguage;
    },
    linkBase64 () {
      const Router_1002 = sessionStorage.getItem('ROUTER_1002')
      const BASE64 = sessionStorage.getItem('BASE64_STR')
      let decodedStr
      if (Router_1002 || BASE64) {
        this.strBase64 = sessionStorage.getItem('BASE64_STR')
      } else {
        this.strBase64 = getParams('data')
      }

      sessionStorage.setItem('BASE64_STR', this.strBase64)
      decodedStr = Base64.decode(decodeURIComponent(this.strBase64))
      sessionStorage.setItem('BASE64', decodedStr)
      this.decodedStr = JSON.parse(decodedStr)
      this.flowLinkCall()
    },
    flowLinkCall () {
      let authentication = localStorage.getItem('authentication')
      console.log(this.decodedStr)
      if (authentication) {
        const router = this.decodedStr?.route
        if (router) {
          this.$router.push({ path: this.routeEnum[router] })
          this.loadingSuccessCount += 1
          setTimeout(() => {
            this.$resetSetItem('loadingSuccessCount', "loadingSuccessCount", this.loadingSuccessCount);
          }, 2000)
        }
      } else {
        this.getTiTokCertification()
      }
    },
    getTiTokCertification () {
      let params = { data: decodeURIComponent(this.strBase64) }
      transitionApi.certificationTiTok(params).then(res => {
        const { code, data } = res
        if (code == 200) {
          this.loginObj = data
          const Router_1002 = sessionStorage.getItem('ROUTER_1002')
          setTimeout(() => {
            if (data.isLogin) {
              localStorage.setItem("account", JSON.stringify(data.account))
              localStorage.setItem("authentication", data.token)
              sessionStorage.removeItem('ROUTER_1002');
              if (!Router_1002) {
                const router = this.decodedStr?.route
                if (router) {
                  this.$router.push({ path: this.routeEnum[router] })
                  setTimeout(() => {
                    this.$emit('tiTokClose')
                  }, 800)
                }
              } else {
                this.$emit('tiTokClose')

              }
            } else {
              setTimeout(() => {
                this.$emit('tiTokClose')  
              }, 800)
              this.$router.push({
                path: "/join-index",
                query: this.$route.query
              })
            }
          }, 2500)

        }
      })
    },

  },
};
</script>

<style lang="scss" scoped>
.loading_wrap {
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.loader-box {
  width: 100%;
  height: 100vh;
  border-radius: 0.3125rem;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-1.875rem);
}

.loader-card {
  width: 320px;
  height: 320px;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  border: 0.1875rem solid #295fd4;
  position: relative;
  margin: 0.9375rem auto;
  z-index: 1;
}

img {
  animation: animationFrames-c2dc4248 4s linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: initial;
  image-rendering: pixelated;
  object-fit: cover;
  transform: translate(32.5rem, 0.9375rem);
}

.lable {
  transform: translateX(0.2375rem);
  font-family: Gotham, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 600;
  color: #295fd4;
}

@keyframes animationFrames-c2dc4248 {
  0% {
    transform: translate(-12.5rem, 1.1375rem);
  }

  100% {
    transform: translate(4.5rem, 1.175rem);
  }
}
</style>
