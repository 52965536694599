// 繁体中文

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: '版本升級,請確認？ ',
    loadingTitle: '正在下載...',
    systemUpgrade: '系統升級',
    upgradeImmediately: '確認',
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: '店鋪名稱',
    pass: '密碼',
    placeholderPass: '請輸入密碼',
    placeholderShopName: "請輸入店鋪名稱",
    mail: "郵箱",
    placeholderMail: "請輸入郵箱",
    emailCode: "郵箱驗證碼",
    placeholderEmailCode: "請輸入驗證碼",
    send: "發送",
    InvitationCode: '邀請碼',
    PlaceholderInvitationCode: '請輸入邀請碼',
    documents: '上傳證件',
    front: '證件正面',
    reverseSide: '證件正面',
    placeholderFront: '請上傳證件正面',
    placeholderReverseSide: '請上傳證件反面',
    btn: '申請',
    applicationBtn: '確認',
    applicationNav: '店鋪申請協議',
    dialogTitle: '稽核成功',
    dialogMessage: '申請成功,請耐心等待工作人員稽核！',
    close: '確認',

}
// 登录
let loginLanguage = {
    navTitle: '登錄',
    warningTitle: '請輸入登錄郵箱',
    passTitle: '請輸入登錄密碼',
    forgetPassword: '忘記密碼？ ',
    btnTitle: '登錄',
    hintTitle: '沒有賬號？去註冊',
    linkTitle: '客服',
    AppDownload: '下載应用程序',
    goToShop: '前往商城',
    register: '注册'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: '忘記密碼',
    email: '登錄郵箱',
    placeholderEmail: '請輸入登錄郵箱',
    emailCode: '驗證碼',
    placeholderEmailCode: '請輸入驗證碼',
    newPass: '新密碼',
    placeholderNewPass: "請輸入新密碼",
    affirmPass: '確認密碼',
    placeholderAffirmPass: '請二次輸入密碼',
    submitBtn: '發送',
    affirm: '確認',
}

// 首页
let homeLanguage = {
    navTitle: '今日銷售額',
    orderTitle: '今日訂單數',
    balanceTitle: '餘額',
    flowTitle: '今日流量數',
    navTitleLine: '銷售額',
    orderTitleLine: '訂單數',
    flowTitleLine: '流量數',
    rechargeTitle: '充值',
    bankTitle: '提現',
    analysis: '近30天概覽',
    showTitle: '展開',
    selectAll: '全選',
    cancel: '關閉',
    sure: '確認',
    revenueLanguage: '稅收',
    revenuepopupTitle: '補稅提醒',
    revenuepopupWarn: '(前往充值頁面進行補稅)',
    revenuepopupNeed: '當前需補稅',
    revenuepopupSurn: '確認',
    outstandingAmount: '未結金額',
    outstandingOrders: '未結訂單',
    totalSalesYesterday: '昨天的總銷售額',
    yesterdaySalesOrders: '昨天的銷售訂單',
}


// 充值页
let rechargeLanguage = {
    navTitle: '充值',
    currencyType1: '法定貨幣',
    currencyType2: '虛擬貨幣',
    pleaseChoose: "請選擇",
    cancelBtn: '取消',
    submitBtn: '確認',
    placeholderNav: '輸入充值金額',
    placeholderCurrent: '請輸入充值金額',
    dialogMessage: '請選擇貨幣種類。 ',
    pathTitle: '充值方式',
    hintTitle: '重要提示:',
    hintContent: '僅允許轉入<資產',
    success: '複製成功！ ',
    hintPopup: '請前往聯系客服人員！',
    hintTile: '提示資訊'
}

// 提现页
let withdrawLanguage = {
    navTitle: '提現',
    currencyType1: '法定貨幣',
    currencyType2: '虛擬貨幣',
    pleaseChoose: "請選擇",
    cancelBtn: '取消',
    submitBtn: '確認',
    placeholderNav: '輸入提現金額',
    placeholderCurrent: '請輸入提現金額',
    currentBalance: '當前餘額',
    passwordTips: '請輸入提現密碼',
    pathTitle: '匯率',
    rateTips: '當前轉化匯率',
    dialogMessage: '請選擇貨幣種類。',
    hintTitle: '重要提示:',
    hintContent: '僅允許轉入<資產',
}


// 提示文本
let messageLanguage = {
    warningTitle: '錯誤',
    loadingTitle: '加載中',
    finishedText: "無",
    loadingText: '加載',
    wait: '請稍等',
    placeholderHite: '輸入值有誤',
    uploadError: '上傳失敗',
    upload: '上傳中',
}

// 个人中心
let myCenterLanguage = {
    navTitle: '個人中心',
    bankCard: '提現設置',
    productLibrary: '產品管理',
    financialManagement: '資金記錄',
    reportStatistics: '經營報表',
    accountInformation: '店鋪信息',
    setting: '系統設置',
    goToShop: '前往商城',
    goToLoan: '去貸款',
    downloadApp: '下載应用程序',
    activity: '活動',
    invitation: '总邀请',
    commission: '总佣金',
}

// 活动页面
let activityLanguage = {
    navTitle: '活動中心',
    discount: '打折活動'
}
let discountLanguage = {
    navTitle: '打折活動',
    affirmBtn: '新增',
    submitBtn: '提交',
    accain: '滿',
    amount: '金額',
    ratio: '折扣',
    plusAffirm: '確認',
    placeholderEnter: '請輸入'
}
// 下载
let downloadApp = {
    navTitle: '下載应用程序',
    downloadAndroidApp: '下載Android應用程序',
    downloadIosApp: '下載ios應用程序',
}

// 产品库
let productLibraryLanguage = {
    navTitle: '產品庫',
    productList: '店鋪產品',
    productLibraryTitle: '平台產品',
    purchasePrice: '採購價格',
    sellingPrice: '銷售價格',
    selectAll: '選中所有',
    affirm: '確認',
    close: '取消',
    editPrice: '修改價格',
    deleteTitle: '刪除訂單',
    dialogMessage: '確定刪除該產品嗎？ '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: '資金記錄',
    tabs1Title: '所有資金',
    tabs2Title: '充值記錄',
    tabs3Title: '提現記錄',
    selectionPeriod: '時間選擇',
    rechargeTitle: '充值',
    wthdrawTitle: '提現',
    successful: '成功',
    balance: '餘額',
    rechargeTime: '充值時間',
    rechargeMessage: '貨幣種類',
    wthdrawTime: '提現時間',
    wthdrawMessage: '貨幣種類',
    currency: "貨幣",
    timeAffirm: '確認',
    timeCancel: '取消',
}
// 经营报表
let statisticsLanguage = {
    totalSales: '總銷售額',
    totalPurchase: '總採購額',
    totalProfit: '總利潤額',
    selectionPeriod: '時間篩選',
    analysis: '折線圖',
    showTitle: '展開',
    selectAll: '全選',
    cancel: '關閉',
    sure: '確認',
    fundingDetails: '詳細數據',
    typeSelection: '選擇類型',
    purchaseAmount: '採購額',
    profitAmount: '利潤額',
    unitAmount: '銷售額',
    grandTotal: '累計',
    timeAffirm: '確認',
    timeCancel: '取消',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: '經營列表',
    selectionPeriod: '選擇時間',
    totalPurchase: '採購額',
    totalSales: '利潤額',
    totalProfit: '銷售額',
    grandTotal: '累計',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: '店鋪信息',
    picture: '店鋪圖像',
    nameTitle: '店鋪名稱',
    Mail: '登錄郵箱',
    invitationCode: '邀請碼',
    loginPassword: '登錄密碼',
    paymentPassword: '支付密碼',
    documents: '證件信息',
    frontDocument: '證件正面',
    backDocument: '證件反面',
    exit: '退出登錄',
}

//  提现设置
let eBackLanguage = {
    navTitle: '提現設置',
    cashCurrency: '法定貨幣',
    virtualCurrency: '虛擬貨幣',
    pleaseChoose: '請選擇',
    popupAffirm: '確認',
    popupCancel: '取消',
    hintTitle: '重要提示:',
    hintContent: '僅允許轉入<資產。 ',
    affirmBtn: '確認',
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: '真實姓名',
    email: '郵箱',
    mobilePhone: '手機號',
    pixType: 'PIX類型',
    pixKey: 'PIX賬號',
    pleaseEnter: '請輸入',
    pleaseSelect: '請選擇',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: '請輸入',
    country: '國家/地區',
    name: '名稱',
    surname: '姓氏',
    bsb: 'BSB',
    bankName: '銀行名稱',
    bankAccount: '銀行賬戶',
    state: '省或州',
    mobilePhone: '手機號',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: '請輸入',
    country: '國家/地區',
    name: '名稱',
    surname: '姓氏',
    swift: 'swift',
    bankName: '銀行名稱',
    iban: 'iban',
    mobilePhone: '手機號',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: '請輸入',
    country: '國家/地區',
    name: '名稱',
    surname: '姓氏',
    bankCode: '銀行代碼',
    bankName: '銀行名稱',
    bankAccount: '銀行賬戶',
    mobilePhone: '手機號',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: '請輸入',
    country: '國家/地區',
    name: '名稱',
    surname: '姓氏',
    zengin: 'zengin',
    bankName: '銀行名稱',
    bankAccount: '銀行賬戶',
    county: '省或州',
    mobilePhone: '手機號',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: '請輸入',
    country: '國家/地區',
    name: '名稱',
    surname: '姓氏',
    swift: 'swift',
    ach: 'ach',
    bankName: '銀行名稱',
    bankAccount: '銀行賬戶',
    state: '省或州',
    mobilePhone: '手機號',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: '請輸入',
    name: '名稱',
    surname: '姓氏',
    bankName: '銀行名稱',
    bankAccount: '銀行賬戶',
    mobilePhone: '手機號',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: '請輸入',
    bankName: '銀行名稱',
    account: '賬戶',
    clabe: 'clabe賬戶',
    mobilePhone: '手機號',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: '地址',
    pleaseInformation: '請輸入虛擬貨幣地址'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: '登錄密碼修改',
    oldPass: '舊密碼',
    newPass: '新密碼',
    email: '驗證碼',
    placeholderOldPass: '請輸入舊密碼',
    placeholderNewPass: '請輸入新密碼',
    placeholderEmailCode: '請輸入驗證碼',
    submitBtn: '發送',
    affirm: '確認',

}

//  个人中心修改支付密码
let payPass = {
    navTitle: '支付密碼修改',
    affirmPass: '支付密碼',
    email: '驗證碼',
    submitBtn: '發送',
    affirm: '確認',
    placeholder: '請輸入',
    placeholderPaymentPassword: '請輸入支付密碼',
    placeholderEmailCode: '請輸入驗證碼',
}

//  系统设置
let settingLanguage = {
    navTitle: '系統設置',
    languageShift: '多語言設置',
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '多語言設置',
    affirm: '確認'
}
//  公告
let afficheDetail = {
    navTitle: '消息',
    comment: '回复',
    unreadMessage: '未讀',
    readTitle: '已讀'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: '消息詳情',
    comment: '評論',
    check: '查看',
    packUpPlaceholder: '請輸入回复內容',
    sendInform: '發送'
}

// 客服
let serviceLanguage = {
    navTitle: '客服',
    customerService: '專屬客服',
    helloTitle: '你好',
    welcomeTitle: ' 我是您的 <, 您可以通過以下方式聯繫我',
    iconInformation: '請選擇溝通軟件進行諮詢',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "訂單管理",
    tabsTodos: "已採購",
    tabsPendente: "待採購 ",
    tabsCompleto: "物流中",
    tabsCancelar: "已完成",
    totalProfit: '總利潤',
    statementPrice: "總採購額",
    affirmBtn: "採購",
    orderTotal: "總訂單數",
    orderCode: "訂單號:",
    childrenDetail: '個產品',
    checkDetail: '產看詳情',
    buyAll: '採購所有',
    dialogTitle: '採購信息',
    dialogTotalSales: '總銷售額',
    dialogTotalPurchase: '總採購額',
    dialogTotalProfit: '總利潤額',
    dialogBtn: '採購',
    deleteTitle: '訂單採購',
    dialogMessage: '您確認採購該訂單嗎？ ',
    dialogAllMessage: '您確認採購所有訂單嗎？ ',
    affirm: '確認',
    close: '取消',
    selectionPeriod: '時間選擇',
};
//  订单详情
let orderDetailListLangage = {
    navTitle: "訂單詳情",
    orderCode: "訂單號:",
    totalProfitTitleCard: '總利潤',
    statementPrice: "總採購額",
    amountRelated: '訂單金額',
    salesTitle: '銷售金額',
    discount: '銷售比例',
    salesAmount: '實際銷售金額',
    totalSalesTitle: '總銷售額',
    totalPurchase: '總利潤額',
    totalProfitTitle: '總採購額',
    btnTitle: '採購',
    deleteTitle: '訂單採購',
    dialogMessage: '您確認採購改訂單嗎？ ',
    affirm: '確認',
    close: '取消',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "訂單產品詳情",
    orderCode: "訂單號:",
    productInformation: "產品信息",
    totalProfitTitleCard: '總利潤額',
    statementPrice: "總採購額:",
    unitPrice: "銷售單價",
    salesVolume: "銷售總價",
    purchasePrices: "採購單價",
    purchasePricesTotal: "採購總價",
    profitPerPiece: "利潤單價",
    profitPerPieceTotal: "利潤總價",
    orderCardTitle: '支付',
    paymentMethods: "支付方式",
    paymentTime: "支付時間",
    returnedMoneyTitle: '回款',
    returnedMoneyMethods: '是否回款',
    returnedMoneyMethods1: '未回款',
    returnedMoneyMethods2: '已回款',
    returnedMoneyTime: '回款時間',
    logisticsInformation: '物流信息',
    logisticsInformationTitle: '點擊查看最新物流動態',
    trackingNumber: '物流號碼',
    orderNumber: '訂單編號',
    logisticsCardTitle: '物流信息',
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: '請輸入查詢條件',
}
// 脚部语言
let footerLanguage = {
    home: '首頁',
    order: '訂單',
    Workbench: '工作台',
}
let chatPageMessage = {
    ladingText: '下載...',
    loosingText: '發佈更新..',
    pullingText: '下拉更新…',
    send: '發送',
    messageText: '消息',
    connecting: 'IM服務連接中...',
    connectionSuccess: 'IM服務連接成功',
    connectionFail: 'IM服務連接失敗',
    connectionSendFail: 'IM服務未連接，發送失敗',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: '邀好友赚奖励',
    qrCodeLanguage: '複製',
    ruleTitle: '邀請規則',
    rule1: '邀請人可通過分享推廣鏈接方式邀請好友完成註冊，被邀請的好友將與您關聯；',
    rule2: '邀請用戶完成回款後你可以獲得其收益的分成；',
    rule3: '僅支持三級邀請獎勵，獎勵比例為：<%，>%，@%；',
    rule4: '邀請的方式需要合法，符合邀請好友規則，如有任何不正當的邀請手段或違反邀請好友規則的行為發生，一經查實扣除所有未發獎勵，平台保留追溯所有已發獎勵的權利，並視情況追究法律責任。',
    situation: '最新邀請記錄',
    copySuccess: '复制成功',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: '邀請用戶記錄',
    cardTitle: '總用戶數',
    firstLevelUser: '一級',
    secondaryUser: '二級',
    tertiaryUser: '三級',

    tabs1Title: '所有',
    tabs2Title: '一級',
    tabs3Title: '二級',
    tabs4Title: '三級',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: '邀請收益記錄',
    cardTitle: '總收益',
    placeholder: '請輸入',
    firstLevelUser: '一級',
    secondaryUser: '二級',
    tertiaryUser: '三級',
    store: '總金額',
    ratio: '比例',
    commission: '收益',

    tabs1Title: '所有',
    tabs2Title: '一級',
    tabs3Title: '二級',
    tabs4Title: '三級',
}
// 排行榜页面 
let rankinLanguage = {
    navTitle: '銷量管理',
    navContent: '订单产品銷量排行',
    tabs1: '銷量',
    tabs2: '銷售額',
    tabs3: '利潤額',
    searchPlaceholder: '產品名稱',
    date1Placeholder: '開始時間',
    date2Placeholder: '結束時間',
    salesVolume: '銷量',
    totalPrice: '總銷售額',
    shelfTime: '總利潤額',
    ranking: 'NO',
    timeAffirm: '確認',
    timeCancel: '取消'
};
let rankingItemLanguage = {
    navTitle: '產品銷售記錄',
    date1Placeholder: '開始時間',
    date2Placeholder: '結束時間',
    tabs1: '銷量',
    tabs2: '時間',
    salesTime: '時間',
    salesVolume: '銷量',
    totalAmount: '總銷售額',
    timeAffirm: '確認',
    timeCancel: '取消'

};
// 个人中心新增
myCenterLanguage.invitation = '邀請'
myCenterLanguage.commission = '佣金'

// 订单页面新增
orderPageLangage.orderBgTitle = '銷售排名，我知道'
orderPageLangage.orderBgLeaveFor = '前往'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
