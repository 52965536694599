// 自定义函数集合 utils.js
exports.resetSetItem = function (key, newVal) {
    if (key === 'errorType') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent')
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val)
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal)
    }
}

