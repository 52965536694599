// 英语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Version upgrade, please confirm? ',// Version upgrade, please confirm?
    loadingTitle: 'Downloading...', // Downloading...
    systemUpgrade: 'system upgrade', // system upgrade
    upgradeImmediately: 'Confirm', // Confirm
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'shop name',
    pass: 'password',
    placeholderPass: 'Please enter your password',
    placeholderShopName: "Please enter the shop name",
    mail: "Mailbox",
    placeholderMail: "Please enter your email",
    emailCode: "Email Verification Code",
    placeholderEmailCode: "Please enter the verification code",
    send: "Send",
    InvitationCode: 'Invitation Code',
    PlaceholderInvitationCode: 'Please enter the invitation code',
    documents: 'Upload documents',
    front: 'document front',
    reverseSide: 'document front',
    placeholderFront: 'Please upload the front of the certificate',
    placeholderReverseSide: 'Please upload the reverse side of the document',
    btn: 'Apply',
    applicationBtn: 'Confirm',
    applicationNav: 'Store Application Agreement',
    dialogTitle:'Review the success',
    dialogMessage:'The application is successful, please wait patiently for the staff to review！',
    close:'Confirm',
}
// 登录
let loginLanguage = {
    navTitle: 'login', // login
    warningTitle: 'Please enter your login email',
    passTitle: 'Please enter the login password', // password
    forgetPassword: 'Forgot your password? ',//Forgot password
    btnTitle: 'login', // login
    hintTitle: 'No account? go to register', // register
    linkTitle: 'customer service', // customer service
    AppDownload: 'Download app', //Download app
    goToShop: 'To the mall',
    register:'register'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Forgot password',
    email: 'login email',
    placeholderEmail: 'Please enter your login email',
    emailCode: 'Verification code',
    placeholderEmailCode: 'Please enter the verification code',
    newPass: 'New password',
    placeholderNewPass: "Please enter a new password",
    affirmPass: 'Confirm Password',
    placeholderAffirmPass: 'Please enter the password twice',
    submitBtn: 'send',
    affirm: 'confirm',
}

// 首页
let homeLanguage = {
    navTitle: 'Today\'s sales', // Home
    orderTitle: 'Number of orders today',//Order
    balanceTitle:'balance',//balance
    flowTitle: 'Today\'s traffic count', //Today's traffic
    navTitleLine: 'Sales', // Home
    orderTitleLine: 'Number of orders',//Order
    flowTitleLine: 'Flow number', //Today's flow
    rechargeTitle: 'Recharge', // recharge
    bankTitle: 'Withdraw', // Withdraw
    analysis: 'Overview of the last 30 days',//Line chart title
    showTitle: 'Expand',//Expand the line chart
    selectAll: 'select all',//select all
    cancel: 'close',//close
    sure: 'confirm',//confirm
    revenueLanguage: 'tax',
    revenuepopupTitle: 'Tax Reminder',
    revenuepopupWarn: 'To recharge and pay tax',
    revenuepopupNeed:'Tax is currently due',
    revenuepopupSurn:'confirm',
    outstandingAmount: 'outstanding amount',
    outstandingOrders: 'outstanding orders',
    totalSalesYesterday : 'Total sales yesterday',
    yesterdaySalesOrders:'Yesterday\'s Sales Orders',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Recharge', // Recharge
    currencyType1: 'Fiat currency',
    currencyType2: 'Virtual currency',
    pleaseChoose: "Please choose",
    cancelBtn: 'Cancel',
    submitBtn: 'confirm', // confirm
    placeholderNav: 'Enter the recharge amount', // current balance
    placeholderCurrent: 'Please enter the recharge amount', // enter the amount
    dialogMessage: 'Please select the currency type. ',
    pathTitle: 'recharge method',// channel
    hintTitle: 'Important:',
    hintContent: 'Only allowed to import <assets',
    success: 'Copy successfully! ',
    hintPopup: 'Please go to contact customer service personnel!',
    hintTile:'Prompt information'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Withdraw', // Withdraw
    currencyType1: 'Fiat currency',
    currencyType2: 'Virtual currency',
    pleaseChoose: "Please choose",
    cancelBtn: 'Cancel',
    submitBtn: 'confirm', // confirm
    placeholderNav: 'Enter the withdrawal amount',
    placeholderCurrent: 'Please enter the withdrawal amount', // enter the amount
    currentBalance: 'Current Balance', // Current Balance
    passwordTips: 'Please enter the withdrawal password', // enter the amount
    pathTitle: 'exchange rate', // channel
    rateTips: 'Current conversion rate',
    dialogMessage: 'Please select the currency type. ',
    hintTitle: 'Important:',
    hintContent: 'Only allowed to import <assets',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'error', // error
    loadingTitle: 'Loading', // Loading
    finishedText: "none", //none
    loadingText: 'loading', // loading
    wait: 'Please wait a moment',
    placeholderHite: 'The input value is incorrect',
    uploadError: 'Upload failed',
    upload: 'Uploading',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'personal center', // personal center
    bankCard: 'Withdrawal settings', // Withdrawal address
    productLibrary: 'product management', //product library
    financialManagement: 'Fund record', //financial management
    reportStatistics: 'Business report', //Statistics
    accountInformation: 'Store information', //Switch language
    setting: 'System settings',
    goToShop:'To the mall',
    goToLoan:'loan',
    downloadApp:'Download app',
    activity: 'Activity'
}
// 活动页面
let activityLanguage = {
    navTitle: 'activity Center',
    discount: 'discount'
}
let discountLanguage = {
    navTitle: 'discount',
    affirmBtn: 'Add',
    submitBtn: 'submit',
    accain: 'Full',
    amount: 'the amount',
    ratio: 'Discount',
    plusAffirm: 'affirm',
    placeholderEnter: 'please enter'
}

// 下载
let downloadApp = {
    navTitle: 'Download app',
    downloadAndroidApp:'download android app',
    downloadIosApp:'download ios app',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Product library', // Product library
    productList: 'Store Product', // Product List
    productLibraryTitle: 'Platform product', // product library
    purchasePrice: 'Purchase Price',
    sellingPrice: 'Selling Price',
    selectAll: 'select all',
    affirm: 'confirm',
    close: 'Cancel',
    editPrice: 'Modify price',
    deleteTitle: 'Delete order',
    dialogMessage: 'Are you sure you want to delete this product? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Fund records',
    tabs1Title: 'All funds', // Liquidity
    tabs2Title: 'recharge record', // recharge record
    tabs3Title: 'withdrawal record',//withdrawal record
    selectionPeriod: 'time selection',//select time
    rechargeTitle: 'recharge',// recharge
    wthdrawTitle: 'Withdrawal',// Withdraw
    successful: 'success',//success
    balance: 'balance', // balance
    rechargeTime: 'recharge time',// recharge time
    rechargeMessage: 'currency type',// recharge method
    wthdrawTime: 'withdrawal time',// recharge time
    wthdrawMessage: 'currency type',// recharge method
    currency: "currency",// currency
    timeAffirm: 'Confirm',
    timeCancel: 'Cancel',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Total Sales',
    totalPurchase: 'Total purchase amount',
    totalProfit: 'Total profit',
    selectionPeriod: 'time filter',//select time
    analysis: 'line chart',//line chart title
    showTitle: 'Expand',//Expand the line chart
    selectAll: 'select all',//select all
    cancel: 'close',//close
    sure: 'confirm',//confirm
    fundingDetails: 'detailed data', // details of funding
    typeSelection: 'selection type',//selection type
    purchaseAmount: 'Purchase Amount', //Sales
    profitAmount: 'Profit Amount',//Total sales
    unitAmount: 'Sales', // installment purchase
    grandTotal: 'cumulative',
    timeAffirm: 'Confirm',
    timeCancel: 'Cancel',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Management list',
    selectionPeriod: 'Select time',//Select time
    totalPurchase: 'purchase amount',
    totalSales: 'profit amount',
    totalProfit: 'Sales',
    grandTotal: 'cumulative',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Store Information',
    picture: 'shop image',
    nameTitle: 'Store name',
    Mail: 'Login Email',
    invitationCode: 'Invitation Code',
    loginPassword: 'login password',
    paymentPassword: 'payment password',
    documents: 'document information',
    frontDocument: 'document front',
    backDocument: 'back of the document',
    exit: 'log out',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Withdrawal settings', // Payment address
    cashCurrency: 'Fiat currency',
    virtualCurrency: 'Virtual Currency',
    pleaseChoose: 'Please choose',
    popupAffirm: 'Confirm',
    popupCancel: 'Cancel',
    hintTitle: 'Important:', // Only supports withdrawal to < address.
    hintContent: 'Only allowed to import < assets. ', // Only supports withdrawal to < address.
    affirmBtn: 'confirm', // confirm
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'real name',
    email: 'email',
    mobilePhone: 'Mobile phone number',
    pixType: 'PIX type',
    pixKey: 'PIX account',
    pleaseEnter: 'Please enter',
    pleaseSelect: 'Please select',

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'please enter ',
    country: 'country/region',
    name: 'name',
    surname: 'surname',
    bsb: 'BSB',
    bankName: 'Bank name',
    bankAccount: 'Bank Account',
    state: 'Province or State',
    mobilePhone: 'Mobile phone number',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'please enter ',
    country: 'country/region',
    name: 'name',
    surname: 'surname',
    swift: 'swift',
    bankName: 'Bank name',
    iban: 'iban',
    mobilePhone: 'Mobile phone number',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'please enter ',
    country: 'country/region',
    name: 'name',
    surname: 'surname',
    bankCode: 'bank code',
    bankName: 'Bank name',
    bankAccount: 'Bank Account',
    mobilePhone: 'Mobile phone number',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'please enter ',
    country: 'country/region',
    name: 'name',
    surname: 'surname',
    zengin: 'zengin',
    bankName: 'Bank name',
    bankAccount: 'Bank Account',
    county: 'county',
    mobilePhone: 'Mobile phone number',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'please enter ',
    country: 'country/region',
    name: 'name',
    surname: 'surname',
    swift: 'swift',
    ach: 'ach',
    bankName: 'Bank name',
    bankAccount: 'Bank Account',
    state: 'Province or State',
    mobilePhone: 'Mobile phone number',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'please enter ',
    name: 'name',
    surname: 'surname',
    bankName: 'Bank name',
    bankAccount: 'Bank Account',
    mobilePhone: 'Mobile phone number',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Please enter',
    bankName: 'Bank Name',
    account: 'Account',
    clabe: 'clabe account',
    mobilePhone: 'Mobile phone number',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Address',
    pleaseInformation: 'Please enter the virtual currency address'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Login password modification', // login password
    oldPass: 'old password', // old password
    newPass: 'new password', // new password
    email: 'Verification code',
    placeholderOldPass: 'Please enter the old password',
    placeholderNewPass: 'Please enter a new password',
    placeholderEmailCode: 'Please enter the verification code',
    submitBtn: 'send', // confirm
    affirm: 'confirm', // confirm

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Payment password modification', // Payment password
    affirmPass: 'payment password',
    email: 'Verification code', // Confirm password
    submitBtn: 'Send',// Confirm
    affirm: 'confirm', // confirm
    placeholder: 'Please enter',
    placeholderPaymentPassword: 'Please enter the payment password',
    placeholderEmailCode: 'Please enter the verification code',
}

//  系统设置
let settingLanguage = {
    navTitle: 'System settings', // Setting
    languageShift: 'Multilingual settings', // language shift
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Multilingual settings',
    affirm: 'confirm'
}
//  公告
let afficheDetail = {
    navTitle: 'message', // notification
    comment: 'reply',// comment
    unreadMessage: 'unread',
    readTitle: 'read'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'message details', // notification
    comment: 'comment',// comment
    check: 'View',
    packUpPlaceholder: 'Please enter the reply content',
    sendInform: 'send'
}

// 客服
let serviceLanguage = {
    navTitle: 'Customer Service', // Customer Service
    customerService: 'exclusive customer service',//customer service
    helloTitle: 'Hello',
    welcomeTitle: 'I am yours <, you can contact me in the following ways',
    iconInformation: 'Please select a communication software for consultation',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Order Management", // Order List
    tabsTodos: "purchased", // all
    tabsPendente: "to be purchased", // to be purchased
    tabsCompleto: "In logistics", // in logistics
    tabsCancelar: "Completed", // Completed
    totalProfit: 'Total Profit',
    statementPrice: "Total Purchase Amount", // Total Amount:
    affirmBtn: "Purchase", // confirm
    orderTotal: "Total Orders",
    orderCode: "Order number:",
    childrenDetail: 'products', // details
    checkDetail: 'Production details', // View details
    buyAll: 'Buy all',
    dialogTitle: 'Purchasing Information',
    dialogTotalSales: 'Total Sales',
    dialogTotalPurchase: 'Total purchase amount',
    dialogTotalProfit: 'Total profit',
    dialogBtn: 'Purchasing',
    deleteTitle: 'Order purchase',
    dialogMessage: 'Are you sure to purchase this order? ',
    dialogAllMessage: 'Are you sure to purchase all orders? ',
    affirm: 'confirm',
    close: 'Cancel',
    selectionPeriod: 'time selection',//select time
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Order Details", // Order List
    orderCode: "Order number:",//Order number
    totalProfitTitleCard: 'Total Profit',
    statementPrice: "Total Purchase Amount", // Total Amount:
    amountRelated: 'Order amount',
    totalSalesTitle: 'Total Sales',
    totalPurchase: 'Total profit',
    totalProfitTitle: 'Total purchases',
    btnTitle: 'Purchase',
    deleteTitle: 'Order purchase',
    dialogMessage: 'Do you confirm the purchase order change? ',
    affirm: 'confirm',
    close: 'Cancel',
    salesTitle: 'Sales Amount',
    discount: 'sales ratio',
    salesAmount: 'Actual sales amount',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "order product details", // order list
    orderCode: "Order number:",//Order number
    productInformation: "Product Information", // Product Information
    totalProfitTitleCard: 'Total Profit',
    statementPrice: "Total purchase amount:", // Total amount:
    unitPrice: "unit price", // unit price
    salesVolume: "Total Sales Price", // Sales Volume
    purchasePrices: "Purchase Unit Price", // Purchase price
    purchasePricesTotal: "Total purchase price", // purchase price
    profitPerPiece: "profit unit price", // profit
    profitPerPieceTotal: "Total Profit Price", // Profit
    orderCardTitle: 'Payment',
    paymentMethods: "Payment Method", // Generate lifetime
    paymentTime: "payment time", // payment time
    returnedMoneyTitle: 'returned money',
    returnedMoneyMethods: 'Whether the money is returned',
    returnedMoneyMethods1: 'Not paid back',
    returnedMoneyMethods2: 'The money has been returned',
    returnedMoneyTime: 'Return time',
    logisticsInformation: 'Logistics Information',
    logisticsInformationTitle: 'Click to view the latest logistics news',
    trackingNumber: 'Logistics Number',
    orderNumber: 'Order Number',
    logisticsCardTitle: 'Logistics Information'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Please enter the query conditions',
}
// 脚部语言
let footerLanguage = {
    home: 'Home',
    order: 'order',
    Workbench: 'Workbench',
}
let chatPageMessage = {
    ladingText: 'Download...',
    loosingText: 'Publish updates...',
    pullingText: 'Dropdown update...',
    send: 'Send',
    messageText:'news',
    connecting: 'IM service connecting...',
    connectionSuccess:'IM service connection is successful',
    connectionFail: 'IM service connection failed',
    connectionSendFail: 'IM service is not connected, sending failed',
}




/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Invite friends to earn rewards',
    qrCodeLanguage: 'Copy',
    ruleTitle: 'Invitation Rules',
    rule1: 'The inviter can invite friends to complete the registration by sharing the promotion link, and the invited friends will be associated with you;',
    rule2: 'After inviting users to complete the payment, you can get a share of their revenue;',
    rule3: 'Only support three-level invitation rewards, the reward ratio is: <%, >%, @%;',
    rule4: 'The method of invitation needs to be legal and in line with the rules for inviting friends. If there is any improper invitation method or behavior that violates the rules for inviting friends, once it is verified, all unissued rewards will be deducted. The platform reserves the right to trace all issued rewards, and Pursue legal responsibility as the case may be. ',
    situation: 'The latest invitation record',
    copySuccess: 'Copy successful',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Invite user record',
    cardTitle: 'Total number of users',
    firstLevelUser: 'level one',
    secondaryUser: 'secondary',
    tertiaryUser: 'Tertiary',

    tabs1Title: 'All',
    tabs2Title: 'level one',
    tabs3Title: 'Secondary',
    tabs4Title: 'Third level',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Invitation revenue record',
    cardTitle: 'Total revenue',
    placeholder: 'Please enter',
    firstLevelUser: 'level one',
    secondaryUser: 'secondary',
    tertiaryUser: 'Tertiary',
    store: 'total amount',
    ratio: 'ratio',
    commission: 'income',

    tabs1Title: 'All',
    tabs2Title: 'level one',
    tabs3Title: 'Secondary',
    tabs4Title: 'Third level',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Sales Management',
    navContent: 'Order product sales ranking',
    tabs1: 'Sales',
    tabs2: 'Total amount',
    tabs3: 'Profit Amount',
    searchPlaceholder: 'Product Name',
    date1Placeholder: 'Start time',
    date2Placeholder: 'End time',
    salesVolume: 'sales',
    totalPrice: 'total sales',
    shelfTime: 'Total profit',
    ranking: 'NO',
    timeAffirm: 'Confirm',
    timeCancel: 'Cancel'
};
let rankingItemLanguage = {
    navTitle: 'Product Sales Record',
    date1Placeholder: 'Start time',
    date2Placeholder: 'End time',
    tabs1: 'Sales',
    tabs2: 'time',
    salesTime: 'time',
    salesVolume: 'sales',
    totalAmount: 'total sales',
    timeAffirm: 'Confirm',
    timeCancel: 'Cancel'

};
// 个人中心新增
myCenterLanguage.invitation = 'invite'
myCenterLanguage.commission = 'commission'

// 订单页面新增
orderPageLangage.orderBgTitle='Sales rank, I know'
orderPageLangage.orderBgLeaveFor='go to'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
