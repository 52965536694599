// 脚部
import home from "@/assets/footer-images/home.png";
import home2 from "@/assets/footer-images/home2.png";
import myCenter from "@/assets/footer-images/myCenter.png";
import myCenter2 from "@/assets/footer-images/myCenter2.png";
import order from "@/assets/footer-images/order.png";
import order2 from "@/assets/footer-images/order2.png";

let footerImages = {
    home,
    home2,
    myCenter,
    myCenter2,
    order,
    order2,
};
// 登录页
import loginUser from "@/assets/loginUser.png";

let loginImages = {
    loginUser,
};
// home页
import messageIcon from "@/assets/messageIcon.png";
import money_bg from "@/assets/money_bg.png";
import packUpIcon from "@/assets/packUpIcon.png";
import unDownIcon from "@/assets/unDownIcon.png";
import uploadIocn from "@/assets/uploadIocn.png";
import revenue from "@/assets/revenue.png";
let homeImages = {
    messageIcon,
    money_bg,
    packUpIcon,
    unDownIcon,
    uploadIocn,
    revenue
};
// 订单
import searchIcon from "@/assets/searchIcon.png";
import locationIcon from "@/assets/locationIcon.png";
import orderIcon_images from "@/assets/orderIcon_images.png";
import iconDate from "@/assets/icon-date.png";
import turchIcon from "@/assets/turchIcon.png";
let orderImages = {
    searchIcon,
    locationIcon,
    orderIcon_images,
    turchIcon,iconDate
};
// 我的工作台
import message_bg from "@/assets/message_bg.png";
import productIcon from "@/assets/productIcon.png";
import moneyIcon from "@/assets/moneyIcon.png";
import statementIcon from "@/assets/statementIcon.png";
import languageIcon from "@/assets/languageIcon.png";
import storeIcon from "@/assets/storeIcon.png";
import userIcon from "@/assets/userIcon.png";
import rightIcon from "@/assets/rightIcon.png";
import settingIcon from "@/assets/settingIcon.png";
import bankCardIcon from "@/assets/bankCardIcon.png";
import checkBoxIcon from "@/assets/checkBoxIcon.png";
import checkIcon from "@/assets/checkIcon.png";
import shoppingIcon from "@/assets/shoppingIcon.png";
import delIcon from "@/assets/delIcon.png";
import rechargeIcon_management from "@/assets/rechargeIcon_management.png";
import withdrawIcon_management from "@/assets/withdrawIcon_management.png";
import checkImages from "@/assets/checkImages.png";
import defaultIcon from "@/assets/defaultIcon.png";
import informUserIcon from "@/assets/informUserIcon.png";
import serviceIcon from "@/assets/serviceIcon.png";
import service1 from "@/assets/service1.png";
import service2 from "@/assets/service2.png";
import service3 from "@/assets/service3.png";
import serviceIconBg2 from "@/assets/serviceIconBg2.png";
import leftIcon from "@/assets/leftIcon.png";
import affirmIcon from "@/assets/affirmIcon.png";
import languageImages from "@/assets/languageImages.png";
import toLoginIcon from "@/assets/toLoginIcon.png";
import goToShop from "@/assets/goToShop.png";
import goToLoan from "@/assets/goToLoan.png";
import toLogin from "@/assets/toLogin.png";
import downloadApp from "@/assets/downloadApp.png";
import activity from "@/assets/activity.png";
let myCenterImages = {
    activity,
    service1,
    service2,
    service3,
    serviceIconBg2,
    serviceIcon,
    informUserIcon,
    defaultIcon,
    checkImages,
    message_bg,
    productIcon,
    moneyIcon,
    statementIcon,
    languageIcon,
    storeIcon,
    userIcon,
    rightIcon,
    settingIcon,
    bankCardIcon,
    checkBoxIcon,
    checkIcon,
    shoppingIcon,
    delIcon,
    rechargeIcon_management,
    withdrawIcon_management,
    leftIcon,
    affirmIcon,
    languageImages,
    toLoginIcon,
    goToShop,
    goToLoan,
    toLogin,
    downloadApp
};

import arrow_left from "@/assets/arrow_left.png";
let headerImages = {
    arrow_left
};
// 公告
import editIcon from "@/assets/editIcon.png";
import commentIcon from "@/assets/commentIcon.png";
import rightIconImages from "@/assets/rightIconImages.png";
import closeIcon from "@/assets/closeIcon.png";
let messageImages = {
    editIcon,
    commentIcon,
    rightIconImages,
    closeIcon,
};
// 记录图标
import recordIcon from "@/assets/recordIcon.png";
import radioIcon from "@/assets/radioIcon.png";
import unlockIcon from "@/assets/unlockIcon.png";
import copy from "@/assets/copy.png";
import radioGrayIcon from "@/assets/radioGrayIcon.png";
let recordImages = {
    recordIcon,
    radioIcon,
    copy,
    unlockIcon,
    radioGrayIcon
};

import fillIcon from '@/assets/fillIcon.png';
import classifyIcon2 from '@/assets/classifyIcon2.png';
import messageIcons from '@/assets/messageIcons.png';
let webSocketImages = {
    fillIcon,
    classifyIcon2,
    messageIcons
}
export default {
    ...recordImages,
    ...loginImages,
    ...headerImages,
    ...footerImages,
    ...homeImages,
    ...orderImages,
    ...myCenterImages,
    ...messageImages,
    ...webSocketImages
};