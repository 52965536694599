// 土耳其语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Sürüm yükseltme, lütfen onaylayın? ',// Sürüm yükseltme, lütfen onaylayın?
    loadingTitle: 'İndiriliyor...', // İndiriliyor...
    systemUpgrade: 'sistem yükseltme', // sistem yükseltme
    upgradeImmediately: 'Onayla', // Onayla
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'mağaza adı',
    pass: 'şifre',
    placeholderPass: 'Lütfen şifrenizi girin',
    placeholderShopName: "Lütfen mağaza adını girin",
    mail: "Posta kutusu",
    placeholderMail: "Lütfen e-postanızı girin",
    emailCode: "E-posta Doğrulama Kodu",
    placeholderEmailCode: "Lütfen doğrulama kodunu girin",
    send: "gönder",
    InvitationCode: 'Davetiye Kodu',
    PlaceholderInvitationCode: 'Lütfen davetiye kodunu girin',
    documents: 'Belgeleri yükleyin',
    front: 'belge önü',
    reverseSide: 'belge önü',
    placeholderFront: 'Lütfen sertifikanın ön yüzünü yükleyin',
    placeholderReverseSide: 'Lütfen belgenin arka yüzünü yükleyin',
    btn: 'Uygula',
    applicationBtn: 'Onayla',
    applicationNav: 'Mağaza Uygulama Sözleşmesi',
    dialogTitle: 'Başarıyı gözden geçirin',
    dialogMessage: 'Uygulama başarılı, lütfen sabırla personelin incelemesini bekleyin！',
    close: 'Onayla',
}
// 登录
let loginLanguage = {
    navTitle: 'giriş', // giriş
    warningTitle: 'Lütfen giriş e-posta adresinizi girin',
    passTitle: 'Lütfen oturum açma parolasını girin', // parola
    forgetPassword: 'Şifrenizi mi unuttunuz? ',//Parolanızı mı unuttunuz
    btnTitle: 'login', // login
    hintTitle: 'Hesap yok mu? kayıt ol', // kayıt ol
    linkTitle: 'müşteri hizmetleri', // müşteri hizmetleri
    AppDownload: 'Uygulamayı indir', //Uygulamayı indir
    goToShop: 'Alışveriş merkezine',
    register: 'Turquía'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Şifremi unuttum',
    email: 'giriş e-postası',
    placeholderEmail: 'Lütfen giriş e-postanızı girin',
    emailCode: 'Doğrulama kodu',
    placeholderEmailCode: 'Lütfen doğrulama kodunu girin',
    newPass: 'Yeni şifre',
    placeholderNewPass: "Lütfen yeni bir şifre girin",
    affirmPass: 'Şifreyi Onayla',
    placeholderAffirmPass: 'Lütfen parolayı iki kez girin',
    submitBtn: 'gönder',
    affirm: 'onaylamak',
}

// 首页
let homeLanguage = {
    navTitle: 'Bugünün satışları', // Ana Sayfa
    orderTitle: 'Bugünkü sipariş sayısı',//Sipariş
    balanceTitle: 'denge',//denge
    flowTitle: 'Bugünün trafik sayısı', //Bugünün trafiği
    navTitleLine: 'Satış', // Ana Sayfa
    orderTitleLine: 'Sipariş sayısı',//Sipariş
    flowTitleLine: 'Akış numarası', //Bugünün akışı
    rechargeTitle: 'Yeniden şarj et', // yeniden şarj et
    bankTitle: 'Çek', // Geri Çek
    analysis: 'Son 30 güne genel bakış',//Çizgi grafik başlığı
    showTitle: 'Genişlet',//Çizgi grafiği genişlet
    selectAll: 'tümünü seç', // tümünü seç
    cancel: 'kapat',//kapat
    sure: 'onayla',//onayla
    revenueLanguage: 'vergi',
    revenuepopupTitle: 'Vergi',
    revenuepopupWarn: 'Yeniden şarj etmek ve vergi ödemek için',
    revenuepopupNeed: 'Vergi şu anda ödenecek',
    revenuepopupSurn: 'onayla',
    outstandingAmount: 'ödenmemiş miktar',
    outstandingOrders: 'bekleyen siparişler',
    totalSalesYesterday: 'Dünkü toplam satış',
    yesterdaySalesOrders: 'Dünün Satış Siparişleri',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Yeniden Yükle', // Yeniden Yükle
    currencyType1: 'Fiat para birimi',
    currencyType2: 'Sanal para birimi',
    pleaseChoose: "Lütfen seçin",
    cancelBtn: 'İptal',
    submitBtn: 'onayla', // onayla
    placeholderNav: 'Yeniden yükleme miktarını girin', // mevcut bakiye
    placeholderCurrent: 'Lütfen şarj miktarını girin', // miktarı girin
    dialogMessage: 'Lütfen para birimi türünü seçin. ',
    pathTitle: 'yeniden yükleme yöntemi',// kanal
    hintTitle: 'Önemli:',
    hintContent: 'Yalnızca <varlıkların içe aktarılmasına izin verilir',
    success: 'Başarıyla kopyalayın! ',
    hintPopup: 'Lütfen müşteri hizmet personeline bağlantı edin!',
    hintTile: 'Bilgi soru'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Çek', // Geri Çek
    currencyType1: 'Fiat para birimi',
    currencyType2: 'Sanal para birimi',
    pleaseChoose: "Lütfen seçin",
    cancelBtn: 'İptal',
    submitBtn: 'onayla', // onayla
    placeholderNav: 'Çekme tutarını girin',
    placeholderCurrent: 'Lütfen para çekme miktarını girin', // miktarı girin
    currentBalance: 'Mevcut Bakiye', // Mevcut Bakiye
    passwordTips: 'Lütfen para çekme şifresini girin', // miktarı girin
    pathTitle: 'döviz kuru', // kanal
    rateTips: 'Mevcut dönüşüm oranı',
    dialogMessage: 'Lütfen para birimi türünü seçin. ',
    hintTitle: 'Önemli:',
    hintContent: 'Yalnızca <varlıkların içe aktarılmasına izin verilir',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'hata', // hata
    loadingTitle: 'Yükleniyor', // Yükleniyor
    finishedText: "yok", //yok
    loadingText: 'yükleniyor', // yükleniyor
    wait: 'Lütfen bir dakika bekleyin',
    placeholderHite: 'Girilen değer yanlış',
    uploadError: 'Yükleme başarısız',
    upload: 'Yükleniyor',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'kişisel merkez', // kişisel merkez
    bankCard: 'Para çekme ayarları', // Para çekme adresi
    productLibrary: 'ürün yönetimi', //ürün kitaplığı
    financialManagement: 'Fon kaydı', //finansal yönetim
    reportStatistics: 'İş raporu', //İstatistikler
    accountInformation: 'Bilgileri depola', //Dili değiştir
    setting: 'Sistem ayarları',
    goToShop: 'Alışveriş merkezine',
    goToLoan: 'kredi',
    downloadApp: 'Uygulamayı indir',
    activity: 'Aktivite'
}
// 活动页面
let activityLanguage = {
    navTitle: 'etkinlik Merkezi',
    discount: 'indirim'
}
let discountLanguage = {
    navTitle: 'indirim',
    affirmBtn: 'Ekle',
    submitBtn: 'gönder',
    accain: 'Dolu',
    amount: 'miktar',
    ratio: 'İndirim',
    plusAffirm: 'onayla',
    placeholderEnter: "lütfen girin"
}

// 下载
let downloadApp = {
    navTitle: 'Uygulamayı indir',
    downloadAndroidApp: 'android uygulamasını indir',
    downloadIosApp: 'ios uygulamasını indir',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Ürün kitaplığı', // Ürün kitaplığı
    productList: 'Mağaza Ürünü', // Ürün Listesi
    productLibraryTitle: 'Platform ürünü', // ürün kitaplığı
    purchasePrice: 'Satın Alma Fiyatı',
    sellingPrice: 'Satış Fiyatı',
    selectAll: 'hepsini seç',
    affirm: 'onaylamak',
    close: 'İptal',
    editPrice: 'Fiyatı değiştir',
    deleteTitle: 'Siparişi sil',
    dialogMessage: 'Bu ürünü silmek istediğinizden emin misiniz? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Fon kayıtları',
    tabs1Title: 'Tüm fonlar', // Likidite
    tabs2Title: 'yeniden yükleme kaydı', // yeniden yükleme kaydı
    tabs3Title: 'çekilme kaydı',//çekilme kaydı
    selectionPeriod: 'zaman seçimi',//zaman seç
    rechargeTitle: 'recharge',// recharge
    wthdrawTitle: 'Çekilme',// Çekilme
    successful: 'başarı',//başarı
    balance: 'denge', // denge
    rechargeTime: 'şarj süresi',// yeniden şarj süresi
    rechargeMessage: 'para birimi türü',// yeniden yükleme yöntemi
    wthdrawTime: 'çekilme zamanı',// yeniden doldurma zamanı
    wthdrawMessage: 'para birimi türü',// yeniden yükleme yöntemi
    currency: "para birimi",// para birimi
    timeAffirm: 'Onayla',
    timeCancel: 'İptal',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Toplam Satış',
    totalPurchase: 'Toplam satın alma tutarı',
    totalProfit: 'Toplam kar',
    selectionPeriod: 'zaman filtresi',//zaman seç
    analysis: 'çizgi grafiği',//çizgi grafiği başlığı
    showTitle: 'Genişlet',//Çizgi grafiği genişlet
    selectAll: 'tümünü seç', // tümünü seç
    cancel: 'kapat',//kapat
    sure: 'onayla',//onayla
    fundingDetails: 'ayrıntılı veri', // finansman ayrıntıları
    typeSelection: 'seçim türü',//seçim türü
    purchaseAmount: 'Satın Alma Tutarı', //Satış
    profitAmount: 'Kar Tutarı',//Toplam satışlar
    unitAmount: 'Satış', // taksitli satın alma
    grandTotal: 'kümülatif',
    timeAffirm: 'Onayla',
    timeCancel: 'İptal',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Yönetim listesi',
    selectionPeriod: 'Zaman seç',//Zaman seç
    totalPurchase: 'satın alma tutarı',
    totalSales: 'kar tutarı',
    totalProfit: 'Satış',
    grandTotal: 'kümülatif',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Mağaza Bilgileri',
    picture: 'mağaza resmi',
    nameTitle: 'Mağaza adı',
    Mail: 'Giriş E-postası',
    invitationCode: 'Davetiye Kodu',
    loginPassword: 'giriş şifresi',
    paymentPassword: 'ödeme şifresi',
    documents: 'belge bilgileri',
    frontDocument: 'ön belge',
    backDocument: 'belgenin arkası',
    exit: 'oturumu kapat',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Para çekme ayarları', // Ödeme adresi
    cashCurrency: 'Fiat para birimi',
    virtualCurrency: 'Sanal Para Birimi',
    pleaseChoose: 'Lütfen seçin',
    popupAffirm: 'Onayla',
    popupCancel: 'İptal',
    hintTitle: 'Önemli:', // Yalnızca < adresine para çekmeyi destekler.
    hintContent: 'Yalnızca < varlıkları içe aktarmaya izin verilir. ', // Yalnızca < adresine para çekmeyi destekler.
    affirmBtn: 'onayla', // onayla
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'gerçek isim',
    email: 'e-posta',
    mobilePhone: 'Cep telefonu numarası',
    pixType: 'PIX türü',
    pixKey: 'PIX hesabı',
    pleaseEnter: 'Lütfen girin',
    pleaseSelect: 'Lütfen seçin',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'lütfen girin ',
    country: 'ülke/bölge',
    name: 'isim',
    surname: 'soyadı',
    bsb: 'BSB',
    bankName: 'Banka adı',
    bankAccount: 'Banka Hesabı',
    state: 'İl veya Eyalet',
    mobilePhone: 'Cep telefonu numarası',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'lütfen girin ',
    country: 'ülke/bölge',
    name: 'isim',
    surname: 'soyadı',
    swift: 'swift',
    bankName: 'Banka adı',
    iban: 'iban',
    mobilePhone: 'Cep telefonu numarası',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'lütfen girin ',
    country: 'ülke/bölge',
    name: 'isim',
    surname: 'soyadı',
    bankCode: 'banka kodu',
    bankName: 'Banka adı',
    bankAccount: 'Banka Hesabı',
    mobilePhone: 'Cep telefonu numarası',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'lütfen girin ',
    country: 'ülke/bölge',
    name: 'isim',
    surname: 'soyadı',
    zengin: 'zengin',
    bankName: 'Banka adı',
    bankAccount: 'Banka Hesabı',
    county: 'ilçe',
    mobilePhone: 'Cep telefonu numarası',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'lütfen girin ',
    country: 'ülke/bölge',
    name: 'isim',
    surname: 'soyadı',
    swift: 'swift',
    ach: 'ach',
    bankName: 'Banka adı',
    bankAccount: 'Banka Hesabı',
    state: 'İl veya Eyalet',
    mobilePhone: 'Cep telefonu numarası',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'lütfen girin ',
    name: 'isim',
    surname: 'soyadı',
    bankName: 'Banka adı',
    bankAccount: 'Banka Hesabı',
    mobilePhone: 'Cep telefonu numarası',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Lütfen girin',
    bankName: 'Banka Adı',
    account: 'Hesap',
    clabe: 'clabe hesabı',
    mobilePhone: 'Cep telefonu numarası',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Adres',
    pleaseInformation: 'Lütfen sanal para birimi adresini girin'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Giriş şifresi değişikliği', // giriş şifresi
    oldPass: 'eski şifre', // eski şifre
    newPass: 'yeni şifre', // yeni şifre
    email: 'Doğrulama kodu',
    placeholderOldPass: 'Lütfen eski parolayı girin',
    placeholderNewPass: 'Lütfen yeni bir parola girin',
    placeholderEmailCode: 'Lütfen doğrulama kodunu girin',
    submitBtn: 'gönder', // onayla
    affirm: 'onayla', // onayla

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Ödeme şifresi değişikliği', // Ödeme şifresi
    affirmPass: 'ödeme şifresi',
    email: 'Doğrulama kodu', // Şifreyi onayla
    submitBtn: 'Gönder',// Onayla
    affirm: 'onayla', // onayla
    placeholder: 'Lütfen girin',
    placeholderPaymentPassword: 'Lütfen ödeme şifresini girin',
    placeholderEmailCode: 'Lütfen doğrulama kodunu girin',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Sistem ayarları', // Ayar
    languageShift: 'Çok dilli ayarlar', // dil kaydırma
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Çok dilli ayarlar',
    affirm: 'onaylamak'
}
//  公告
let afficheDetail = {
    navTitle: 'mesaj', // bildirim
    comment: 'cevap',// yorum
    unreadMessage: 'okunmadı',
    readTitle: 'oku'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'mesaj ayrıntıları', // bildirim
    comment: 'yorum', // yorum
    check: 'Görünüm',
    packUpPlaceholder: 'Lütfen yanıt içeriğini girin',
    sendInform: 'gönder'
}

// 客服
let serviceLanguage = {
    navTitle: 'Müşteri Hizmetleri', // Müşteri Hizmetleri
    customerService: 'özel müşteri hizmeti',//müşteri hizmetleri
    helloTitle: 'Merhaba',
    welcomeTitle: 'Ben seninim <, benimle aşağıdaki şekillerde iletişime geçebilirsiniz',
    iconInformation: 'Lütfen danışma için bir iletişim yazılımı seçin',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Sipariş Yönetimi", // Sipariş Listesi
    tabsTodos: "satın alındı", // tümü
    tabsPendente: "satın alınacak", // satın alınacak
    tabsCompleto: "Lojistikte", // lojistikte
    tabsCancelar: "Tamamlandı", // Tamamlandı
    totalProfit: 'Toplam Kâr',
    statementPrice: "Toplam Satın Alma Tutarı", // Toplam Tutar:
    affirmBtn: "Satın al", // onayla
    orderTotal: "Toplam Siparişler",
    orderCode: "Sipariş numarası:",
    childrenDetail: 'ürünler', // ayrıntılar
    checkDetail: 'Üretim ayrıntıları', // Ayrıntıları görüntüle
    buyAll: 'Hepsini satın al',
    dialogTitle: 'Satın Alma Bilgileri',
    dialogTotalSales: 'Toplam Satış',
    dialogTotalPurchase: 'Toplam satın alma tutarı',
    dialogTotalProfit: 'Toplam kar',
    dialogBtn: 'Satın alma',
    deleteTitle: 'Sipariş satın alma',
    dialogMessage: 'Bu siparişi satın aldığınızdan emin misiniz? ',
    dialogAllMessage: 'Tüm siparişleri satın alacağınızdan emin misiniz? ',
    affirm: 'onaylamak',
    close: 'İptal',
    selectionPeriod: 'zaman seçimi',//zaman seç
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Sipariş Ayrıntıları", // Sipariş Listesi
    orderCode: "Sipariş numarası:",//Sipariş numarası
    totalProfitTitleCard: 'Toplam Kâr',
    statementPrice: "Toplam Satın Alma Tutarı", // Toplam Tutar:
    amountRelated: 'Sipariş miktarı',
    totalSalesTitle: 'Toplam Satış',
    totalPurchase: 'Toplam kar',
    totalProfitTitle: 'Toplam satın alma',
    btnTitle: 'Satın alma',
    deleteTitle: 'Sipariş satın alma',
    dialogMessage: 'Satın alma siparişi değişikliğini onaylıyor musunuz? ',
    affirm: 'onaylamak',
    close: 'İptal',
    salesTitle: 'Satış Tutarı',
    discount: 'satış oranı',
    salesAmount: 'Fiili satış tutarı',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "ürün ayrıntılarını sipariş et", // sipariş listesi
    orderCode: "Sipariş numarası:",//Sipariş numarası
    productInformation: "Ürün Bilgileri", // Ürün Bilgileri
    totalProfitTitleCard: 'Toplam Kâr',
    statementPrice: "Toplam satın alma tutarı:", // Toplam tutar:
    unitPrice: "birim fiyat", // birim fiyat
    salesVolume: "Toplam Satış Fiyatı", // Satış Hacmi
    purchasePrices: "Satın Alma Birim Fiyatı", // Satınalma fiyatı
    purchasePricesTotal: "Toplam satın alma fiyatı", // satın alma fiyatı
    profitPerPiece: "kâr birim fiyatı", // kar
    profitPerPieceTotal: "Toplam Kâr Fiyatı", // Kâr
    orderCardTitle: 'Ödeme',
    paymentMethods: "Ödeme Yöntemi", // Ömür boyu üret
    paymentTime: "ödeme zamanı", // ödeme zamanı
    returnedMoneyTitle: 'iade edilen para',
    returnedMoneyMethods: 'Paranın iade edilip edilmediği',
    returnedMoneyMethods1: 'Geri ödenmedi',
    returnedMoneyMethods2: 'Para iade edildi',
    returnedMoneyTime: 'Dönüş zamanı',
    logisticsInformation: 'Lojistik Bilgi',
    logisticsInformationTitle: 'En son lojistik haberlerini görüntülemek için tıklayın',
    trackingNumber: 'Lojistik Numarası',
    orderNumber: 'Sipariş Numarası',
    logisticsCardTitle: 'Lojistik Bilgileri'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Lütfen sorgu koşullarını girin',
}
// 脚部语言
let footerLanguage = {
    home: 'Ev',
    order: 'emir',
    Workbench: 'tezgah',
}
let chatPageMessage = {
    ladingText: 'Download...',
    loosingText: 'Güncelleştirmeleri yayınlayın...',
    pullingText: 'Dropdown update...',
    send: 'Yiber',
    messageText: 'haberler',
    connecting: 'IM hizmeti bağlanıyor...',
    connectionSuccess: 'IM hizmeti bağlantısı başarılı',
    connectionFail: 'IM hizmeti bağlantısı başarısız oldu',
    connectionSendFail: 'IM hizmeti bağlı değil, gönderme başarısız',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Ödül kazanmak için arkadaşlarını davet et',
    qrCodeLanguage: 'Kopyala',
    ruleTitle: 'Davetiye Kuralları',
    rule1: 'Davet eden kişi promosyon bağlantısını paylaşarak kayıt işlemini tamamlaması için arkadaşlarını davet edebilir ve davet edilen arkadaşlar sizinle ilişkilendirilir;',
    rule2: 'Kullanıcıları ödemeyi tamamlamaya davet ettikten sonra gelirlerinden pay alabilirsiniz;',
    rule3: 'Yalnızca üç seviyeli davet ödüllerini destekleyin, ödül oranı: <%, >%, @%;',
    rule4: 'Davet yöntemi yasal ve arkadaş davet kurallarına uygun olmalıdır. Herhangi bir uygunsuz davet yöntemi veya arkadaş davet etme kurallarını ihlal eden bir davranış varsa, doğrulandıktan sonra verilmeyen tüm ödüller düşülecektir. Platform, verilen tüm ödülleri takip etme ve duruma göre yasal sorumluluk alma hakkını saklı tutar. ',
    situation: 'En son davet kaydı',
    copySuccess: 'Kopyalama başarılı',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Kullanıcı kaydını davet et',
    cardTitle: 'Toplam kullanıcı sayısı',
    firstLevelUser: 'birinci seviye',
    secondaryUser: 'ikincil',
    tertiaryUser: 'Üçüncül',

    tabs1Title: 'Tümü',
    tabs2Title: 'birinci seviye',
    tabs3Title: 'İkincil',
    tabs4Title: 'Üçüncü düzey',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Davetiye gelir kaydı',
    cardTitle: 'Toplam gelir',
    placeholder: 'Lütfen girin',
    firstLevelUser: 'birinci seviye',
    secondaryUser: 'ikincil',
    tertiaryUser: 'Üçüncül',
    store: 'toplam tutar',
    ratio: 'oran',
    commission: 'geliri',

    tabs1Title: 'Tümü',
    tabs2Title: 'birinci seviye',
    tabs3Title: 'İkincil',
    tabs4Title: 'Üçüncü seviye',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Satış Yönetimi',
    navContent: 'Sipariş ürün satış sıralaması',
    tabs1: 'Satış',
    tabs2: 'Toplam tutar',
    tabs3: 'Kar Tutarı',
    searchPlaceholder: 'Ürün Adı',
    date1Placeholder: 'Başlangıç zamanı',
    date2Placeholder: 'Bitiş zamanı',
    salesVolume: 'satış',
    totalPrice: 'toplam satış',
    shelfTime: 'Toplam kar',
    ranking: 'HAYIR',
    timeAffirm: 'Onayla',
    timeCancel: 'İptal'
};
let rankingItemLanguage = {
    navTitle: 'Ürün Satış Kaydı',
    date1Placeholder: 'Başlangıç zamanı',
    date2Placeholder: 'Bitiş zamanı',
    tabs1: 'Satış',
    tabs2: 'zaman',
    salesTime: 'zaman',
    salesVolume: 'satış',
    totalAmount: 'toplam satışlar',
    timeAffirm: 'Onayla',
    timeCancel: 'İptal'

};
// 个人中心新增
myCenterLanguage.invitation = 'davet etmek'
myCenterLanguage.commission = 'komisyon'

// 订单页面新增
orderPageLangage.orderBgTitle = 'Satış sıralaması, biliyorum'
orderPageLangage.orderBgLeaveFor = 'gitmek'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
