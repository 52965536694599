// 日语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'バージョンアップ,確認しますか? ',
    loadingTitle: 'ダウンロード中...',
    systemUpgrade: 'システムのアップグレード',
    upgradeImmediately: '確認',
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'ショップ名',
    pass: 'パスワード',
    placeholderPass: 'パスワードを入力してください ',
    placeholderShopName: "ショップ名を入力してください ",
    mail: "メールボックス",
    placeholderMail: "メールアドレスを入力してください ",
    emailCode: "メール認証コード",
    placeholderEmailCode: "確認コードを入力してください ",
    send: "送信",
    InvitationCode: '招待コード',
    PlaceholderInvitationCode: '招待コードを入力してください ',
    documents: 'ドキュメントのアップロード',
    front: 'ドキュメント フロント',
    reverseSide: 'ドキュメント表',
    placeholderFront: '証明書の前面をアップロードしてください',
    placeholderReverseSide: '証明書の裏面をアップロードしてください',
    btn: 'アプリケーション',
    applicationBtn: '確認',
    applicationNav: '店舗申込規約',
    dialogTitle: 'レビュー成功',
    dialogMessage: '申請が成功しました。スタッフの確認をお待ちください！',
    close: '確認',
}
// 登录
let loginLanguage = {
    navTitle: 'ログイン',
    warningTitle: 'ログイン用メールアドレスを入力してください ',
    passTitle: 'ログインパスワードを入力してください ',
    forgetPassword: 'パスワードをお忘れですか? ',
    btnTitle: 'ログイン',
    hintTitle: 'アカウントがありませんか? 登録に行く',
    linkTitle: 'カスタマーサービス',
    AppDownload: 'アプリをダウンロード',
    goToShop: 'ショッピングモールへ',
    register: '登録'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'パスワードを忘れた',
    email: 'ログイン用メール',
    placeholderEmail: 'ログイン用メールアドレスを入力してください ',
    emailCode: '確認コード',
    placeholderEmailCode: '確認コードを入力してください ',
    newPass: '新しいパスワード',
    placeholderNewPass: "新しいパスワードを入力してください ",
    affirmPass: 'パスワードの確認',
    placeholderAffirmPass: 'パスワードを 2 回入力してください ',
    submitBtn: '送信',
    affirm: '確認する',
}

// 首页
let homeLanguage = {
    navTitle: '今日の売上',
    orderTitle: '今日の注文数',
    balanceTitle: '資産',
    flowTitle: '今日のフロー番号',
    navTitleLine: '販売',
    orderTitleLine: '注文数',
    flowTitleLine: 'フロー番号',
    rechargeTitle: '入金',
    bankTitle: 'お金を引き出す',
    analysis: '過去 30 日間の概要',
    showTitle: '展開',
    selectAll: 'すべて選択',
    cancel: '閉じる',
    sure: '確認',
    revenueLanguage: '税金',
    revenuepopupTitle: '納税通知',
    revenuepopupWarn: 'リチャージして税金を支払うため',
    revenuepopupNeed: '税金は現在支払われるべきです',
    revenuepopupSurn: '確認',
    outstandingAmount: '未払い額',
    outstandingOrders: '未処理の注文',
    totalSalesYesterday: '昨日の総売上高',
    yesterdaySalesOrders: '昨日の販売注文',
}


// 入金页
let rechargeLanguage = {
    navTitle: '入金',
    currencyType1: '法定通貨',
    currencyType2: '仮想通貨',
    pleaseChoose: "選択してください",
    cancelBtn: 'キャンセル',
    submitBtn: '確認',
    placeholderNav: '入金額を入力してください ',
    placeholderCurrent: '入金額を入力してください ',
    dialogMessage: '通貨を選択してください。 ',
    pathTitle: '入金方法',
    hintTitle: '重要:',
    hintContent: '<資産のインポートのみ許可',
    success: '正常にコピーされました! ',
    hintPopup: 'カスタマーサービスに連絡してください！',
    hintTile: 'ヒント情報'
}

// 出金页
let withdrawLanguage = {
    navTitle: 'お金を引き出す',
    currencyType1: '法定通貨',
    currencyType2: '仮想通貨',
    pleaseChoose: "選択してください",
    cancelBtn: 'キャンセル',
    submitBtn: '確認',
    placeholderNav: '出金額を入力してください ',
    placeholderCurrent: '出金金額を入力してください ',
    currentBalance: '現在の残高',
    passwordTips: '出金パスワードを入力してください ',
    pathTitle: '為替レート',
    rateTips: '現在のコンバージョン率',
    dialogMessage: '通貨を選択してください。 ',
    hintTitle: '重要:',
    hintContent: '<資産のインポートのみ許可',
}


// 提示文本
let messageLanguage = {
    warningTitle: 'エラー',
    loadingTitle: '読み込み中',
    finishedText: "なし",
    loadingText: '読み込み中',
    wait: 'しばらくお待ちください',
    placeholderHite: '入力値が正しくありません',
    uploadError: 'アップロードに失敗しました',
    upload: 'アップロード中',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'パーソナル センター',
    bankCard: '出金設定',
    productLibrary: '製品管理',
    financialManagement: 'ファンドの記録',
    reportStatistics: '運用レポート',
    accountInformation: 'ストア情報',
    setting: 'システム設定',
    goToShop: 'ショッピングモールへ',
    goToLoan: 'ローン',
    downloadApp: 'アプリをダウンロードする',
    activity: 'アクティビティ'
}
// 活动页面
let activityLanguage = {
    navTitle: '活動センター',
    discount: '割引'
}
let discountLanguage = {
    navTitle: '割引',
    affirmBtn: '追加',
    submitBtn: '送信',
    accain: 'フル',
    amount: '金額',
    ratio: '割引',
    plusAffirm: '肯定',
    placeholderEnter: '入力してください'
}

// 下载
let downloadApp = {
    navTitle: 'アプリをダウンロードする',
    downloadAndroidApp: 'アンドロイドアプリをダウンロード',
    downloadIosApp: 'iOSアプリをダウンロード',
}

// 产品库
let productLibraryLanguage = {
    navTitle: '製品ライブラリ',
    productList: 'ストア製品',
    productLibraryTitle: 'プラットフォーム製品',
    purchasePrice: '購入価格',
    sellingPrice: '販売価格',
    selectAll: 'すべて選択',
    affirm: '確認する',
    close: 'キャンセル',
    editPrice: '価格の編集',
    deleteTitle: '注文を削除',
    dialogMessage: 'この製品を削除してもよろしいですか? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'ファンドの記録',
    tabs1Title: 'すべての資金',
    tabs2Title: '入金記録',
    tabs3Title: '出金記録',
    selectionPeriod: '時間選択',
    rechargeTitle: '入金',
    wthdrawTitle: '出金',
    successful: '成功',
    balance: 'バランス',
    rechargeTime: '入金時間',
    rechargeMessage: '通貨の種類',
    wthdrawTime: '出金時間',
    wthdrawMessage: '通貨の種類',
    currency: "通貨",
    timeAffirm: '確認',
    timeCancel: 'キャンセル',
}
// 经营报表
let statisticsLanguage = {
    totalSales: '総売上高',
    totalPurchase: '合計購入金額',
    totalProfit: '総利益',
    selectionPeriod: '時間フィルター',
    analysis: '折れ線グラフ',
    showTitle: '展開',
    selectAll: 'すべて選択',
    cancel: '閉じる',
    sure: '確認',
    fundingDetails: '詳細データ',
    typeSelection: '選択タイプ',
    purchaseAmount: '購入金額',
    profitAmount: '利益額',
    unitAmount: '売上高',
    grandTotal: '合計',
    timeAffirm: '確認',
    timeCancel: 'キャンセル',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'ビジネス リスト',
    selectionPeriod: '時間の選択',
    totalPurchase: '購入金額',
    totalSales: '利益額',
    totalProfit: '売上',
    grandTotal: '合計',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: '店舗情報',
    picture: 'ショップ画像',
    nameTitle: '店舗名',
    Mail: 'ログインメール',
    invitationCode: '招待コード',
    loginPassword: 'ログインパスワード',
    paymentPassword: '支払いパスワード',
    documents: 'ドキュメント情報',
    frontDocument: 'ドキュメント フロント',
    backDocument: 'ドキュメントの裏',
    exit: 'ログアウト',
}

//  入金设置
let eBackLanguage = {
    navTitle: '入金設定',
    cashCurrency: '法定通貨',
    virtualCurrency: '仮想通貨',
    pleaseChoose: '選択してください',
    popupAffirm: '確認',
    popupCancel: 'キャンセル',
    hintTitle: '重要:',
    hintContent: ' < アセットのインポートのみ許可されています。 ',
    affirmBtn: '確認',
}

//  入金设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: '本名',
    email: 'メール',
    mobilePhone: '携帯電話番号',
    pixType: 'PIX タイプ',
    pixKey: 'PIX アカウント',
    pleaseEnter: '入力してください ',
    pleaseSelect: '選択してください',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: '入力してください ',
    country: '国/地域',
    name: '名前',
    surname: '姓',
    bsb: 'BSB',
    bankName: '銀行名',
    bankAccount: '銀行口座',
    state: '県または州',
    mobilePhone: '携帯電話番号',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: '入力してください ',
    country: '国/地域',
    name: '名前',
    surname: '姓',
    swift: 'swift',
    bankName: '銀行名',
    iban: 'iban',
    mobilePhone: '携帯電話番号',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: '入力してください ',
    country: '国/地域',
    name: '名前',
    surname: '姓',
    bankCode: '銀行コード',
    bankName: '銀行名',
    bankAccount: '銀行口座',
    mobilePhone: '携帯電話番号',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: '入力してください ',
    country: '国/地域',
    name: '名前',
    surname: '姓',
    zengin: 'zengin',
    bankName: '銀行名',
    bankAccount: '銀行口座',
    county: '郡',
    mobilePhone: '携帯電話番号',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: '入力してください ',
    country: '国/地域',
    name: '名前',
    surname: '姓',
    swift: 'swift',
    ach: 'ach',
    bankName: '銀行名',
    bankAccount: '銀行口座',
    state: '県または州',
    mobilePhone: '携帯電話番号',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: '入力してください ',
    name: '名前',
    surname: '姓',
    bankName: '銀行名',
    bankAccount: '銀行口座',
    mobilePhone: '携帯電話番号',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: '入力してください',
    bankName: '銀行名',
    account: 'アカウント',
    clabe: 'クラベアカウント',
    mobilePhone: '携帯電話番号',
}

//  入金设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: '住所',
    pleaseInformation: '仮想通貨アドレスを入力してください '
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'ログインパスワードの変更',
    oldPass: '古いパスワード',
    newPass: '新しいパスワード',
    email: '確認コード',
    placeholderOldPass: '古いパスワードを入力してください ',
    placeholderNewPass: '新しいパスワードを入力してください ',
    placeholderEmailCode: '確認コードを入力してください ',
    submitBtn: '送信',
    affirm: '確認する',

}

//  个人中心修改支付密码
let payPass = {
    navTitle: '支払いパスワードの変更',
    affirmPass: '支払いパスワード',
    email: '確認コード',
    submitBtn: '送信',
    affirm: '確認する',
    placeholder: '入力してください ',
    placeholderPaymentPassword: '支払いパスワードを入力してください ',
    placeholderEmailCode: '確認コードを入力してください ',
}

//  系统设置
let settingLanguage = {
    navTitle: 'システム設定',
    languageShift: '多言語設定',
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '多言語設定',
    affirm: '確認する',
}
//  公告
let afficheDetail = {
    navTitle: 'メッセージ',
    comment: '返信',
    unreadMessage: '未読',
    readTitle: '読む'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'メッセージの詳細',
    comment: 'コメント',
    check: 'チェック',
    packUpPlaceholder: '返信内容を入力してください ',
    sendInform: '送信'
}

// 客服
let serviceLanguage = {
    navTitle: 'カスタマーサービス',
    customerService: '専用カスタマー サービス',
    helloTitle: 'こんにちは',
    welcomeTitle: '私はあなたの<です。次の方法で私に連絡できます',
    iconInformation: '相談する通信ソフトを選択してください',
    whatsApp: 'WhatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "注文管理",
    tabsTodos: "購入した",
    tabsPendente: "購入予定",
    tabsCompleto: "物流",
    tabsCancelar: "完了",
    totalProfit: '総利益',
    statementPrice: "合計購入",
    affirmBtn: "購入",
    orderTotal: "注文合計",
    orderCode: "注文番号:",
    childrenDetail: '商品',
    checkDetail: '生産詳細',
    buyAll: 'すべて購入',
    dialogTitle: '購入情報',
    dialogTotalSales: '総売上高',
    dialogTotalPurchase: '合計購入金額',
    dialogTotalProfit: '総利益',
    dialogBtn: '購入',
    deleteTitle: '注文購入',
    dialogMessage: 'この注文を購入してもよろしいですか? ',
    dialogAllMessage: 'すべての注文を購入してもよろしいですか? ',
    affirm: '確認する',
    close: 'キャンセル',
    selectionPeriod: '時間の選択',
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "注文の詳細",
    orderCode: "注文番号:",
    totalProfitTitleCard: '総利益',
    statementPrice: "合計購入",
    amountRelated: '注文金額',
    totalSalesTitle: '総売上高',
    totalPurchase: '総利益',
    totalProfitTitle: '合計購入金額',
    btnTitle: '購入',
    deleteTitle: '注文購入',
    dialogMessage: '発注書の変更を確認しますか? ',
    affirm: '確認する',
    close: 'キャンセル',
    salesTitle: '売上高',
    discount: '売上比率',
    salesAmount: '実際の販売額',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "注文商品の詳細",
    orderCode: "注文番号:",
    productInformation: "商品情報",
    totalProfitTitleCard: '総利益',
    statementPrice: "合計購入金額:",
    unitPrice: "販売単価",
    salesVolume: "総販売価格",
    purchasePrices: "購入単価",
    purchasePricesTotal: "合計購入価格",
    profitPerPiece: "利益単価",
    profitPerPieceTotal: "利益総額",
    orderCardTitle: '支払い',
    paymentMethods: "支払い方法",
    paymentTime: "支払い時間",
    returnedMoneyTitle: '返されたお金',
    returnedMoneyMethods: 'お金が戻ってくるかどうか',
    returnedMoneyMethods1: '未払い',
    returnedMoneyMethods2: '返金されました',
    returnedMoneyTime: '返却時間',
    logisticsInformation: '物流情報',
    logisticsInformationTitle: 'クリックして最新のロジスティクス ニュースを表示',
    trackingNumber: '物流番号',
    orderNumber: '注文番号',
    logisticsCardTitle: '物流情報',
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'クエリ条件を入力してください ',
}
// 脚部语言
let footerLanguage = {
    home: 'ホーム',
    order: '注文',
    Workbench: 'ワークベンチ',
}
let chatPageMessage = {
    ladingText: 'ダウンロード . ...',
    loosingText: 'リリースアップデート...',
    pullingText: 'プルダウン更新...',
    send: '送信',
    messageText: 'メッセージ',
    connecting: 'IM サービス接続中...',
    connectionSuccess: 'IM サービスの接続に成功しました',
    connectionFail: 'IM サービス接続に失敗しました',
    connectionSendFail: 'IM サービスが接続されていないため,送信に失敗しました',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: '友達を招待して報酬を獲得',
    qrCodeLanguage: 'コピー',
    ruleTitle: '招待ルール',
    rule1: '招待者は,プロモーション リンクを共有することで登録を完了するように友人を招待でき,招待された友人はあなたと関連付けられます。',
    rule2: 'ユーザーを招待して支払いを完了すると,その収益の一部を受け取ることができます;',
    rule3: '3 レベルの招待報酬のみをサポートします。報酬率は次のとおりです: <%,>%,@%;',
    rule4: '招待方法は合法的であり,友達を招待するためのルールに沿ったものである必要があります。」 不適切な招待方法やフレンド招待ルールに違反する行為があった場合,確認次第,未発行の報酬は全て減額させていただきます。 プラットフォームは,発行されたすべての報酬を追跡し,場合によっては法的責任を追及する権利を留保します。 ',
    situation: '最新の招待記録',
    copySuccess: 'コピー成功',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'ユーザーレコードを招待',
    cardTitle: 'ユーザーの総数',
    firstLevelUser: 'レベル 1',
    secondaryUser: 'セカンダリ',
    tertiaryUser: 'Tertiary',

    tabs1Title: 'すべて',
    tabs2Title: 'レベル 1',
    tabs3Title: 'セカンダリ',
    tabs4Title: '第 3 レベル',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: '招待収益記録',
    cardTitle: '総収入',
    placeholder: '入力してください',
    firstLevelUser: 'レベル 1',
    secondaryUser: 'セカンダリ',
    tertiaryUser: 'Tertiary',
    store: '合計金額',
    ratio: '比率',
    commission: '収入',

    tabs1Title: 'すべて',
    tabs2Title: 'レベル 1',
    tabs3Title: 'セカンダリ',
    tabs4Title: '第 3 レベル',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: '販売管理',
    navContent: '注文商品売上ランキング',
    tabs1: '売上',
    tabs2: '合計金額',
    tabs3: '利益額',
    searchPlaceholder: '製品名',
    date1Placeholder: '開始時刻',
    date2Placeholder: '終了時刻',
    salesVolume: '売上',
    totalPrice: '総売上高',
    shelfTime: '総利益',
    ranking: 'いいえ',
    timeAffirm: '確認',
    timeCancel: 'キャンセル'
};
let rankingItemLanguage = {
    navTitle: '商品販売実績',
    date1Placeholder: '開始時刻',
    date2Placeholder: '終了時刻',
    tabs1: '売上',
    tabs2: '時間',
    salesTime: '時間',
    salesVolume: '売上',
    totalAmount: '総売上高',
    timeAffirm: '確認',
    timeCancel: 'キャンセル'

};
// 个人中心新增
myCenterLanguage.invitation = '招待'
myCenterLanguage.commission = '手数料'

// 订单页面新增
orderPageLangage.orderBgTitle = '売上順位はわかるけど'
orderPageLangage.orderBgLeaveFor = 'に行く'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 入金页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
