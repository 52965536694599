// 意大利语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Aggiornamento versione, conferma? ',// Aggiornamento della versione, conferma?
    loadingTitle: 'Download in corso...', // Download in corso...
    systemUpgrade: 'aggiornamento del sistema', // aggiornamento del sistema
    upgradeImmediately: 'Conferma', // Conferma
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'nome del negozio',
    pass: 'password',
    placeholderPass: 'Inserisci la tua password',
    placeholderShopName: "Inserisci il nome del negozio",
    mail: "Cassetta postale",
    placeholderMail: "Inserisci la tua email",
    emailCode: "Codice di verifica e-mail",
    placeholderEmailCode: "Inserisci il codice di verifica",
    send: "Invia",
    InvitationCode: 'Codice Invito',
    PlaceholderInvitationCode: 'Inserisci il codice di invito',
    documents: 'Carica documenti',
    front: 'documento anteriore',
    reverseSide: 'documento fronte',
    placeholderFront: 'Si prega di caricare la parte anteriore del certificato',
    placeholderReverseSide: 'Carica il retro del documento',
    btn: 'Applica',
    applicationBtn: 'Conferma',
    applicationNav: 'Store Application Agreement',
    dialogTitle: 'Esamina il successo',
    dialogMessage: 'L\'applicazione è andata a buon fine, attendi pazientemente che lo staff esamini!',
    close: 'Conferma',
}
// 登录
let loginLanguage = {
    navTitle: 'login', // login
    warningTitle: 'Inserisci la tua email di accesso',
    passTitle: 'Inserisci la password di accesso', // password
    forgetPassword: 'Hai dimenticato la password? ',//Ha dimenticato la password
    btnTitle: 'accedi', // accedi
    hintTitle: 'Nessun account? vai a registrarti', // registrati
    linkTitle: 'servizio clienti', // servizio clienti
    AppDownload: 'Scarica app', //Scarica app
    goToShop: 'Al centro commerciale',
    register: 'registrarsi'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Password dimenticata',
    email: 'e-mail di accesso',
    placeholderEmail: 'Inserisci la tua email di accesso',
    emailCode: 'Codice di verifica',
    placeholderEmailCode: 'Inserisci il codice di verifica',
    newPass: 'Nuova password',
    placeholderNewPass: "Inserisci una nuova password",
    affirmPass: 'Conferma password',
    placeholderAffirmPass: 'Si prega di inserire la password due volte',
    submitBtn: 'invia',
    affirm: 'conferma',
}

// 首页
let homeLanguage = {
    navTitle: 'Vendite di oggi', // Home
    orderTitle: 'Numero di ordini oggi',//Ordine
    balanceTitle: 'balance',//bilanciamento
    flowTitle: 'Conteggio del traffico di oggi', //Traffico di oggi
    navTitleLine: 'Vendite', // Home
    orderTitleLine: 'Numero di ordini',//Ordine
    flowTitleLine: 'Numero flusso', //Flusso di oggi
    rechargeTitle: 'Ricarica', // ricarica
    bankTitle: 'Prelevare', // Prelevare
    analysis: 'Panoramica degli ultimi 30 giorni',//Titolo del grafico a linee
    showTitle: 'Espandi',//Espandi il grafico a linee
    selectAll: 'seleziona tutto',//seleziona tutto
    cancel: 'chiudi',//chiudi
    sure: 'conferma',//conferma
    revenueLanguage: 'imposta',
    revenuepopupTitle: 'Promemoria fiscale',
    revenuepopupWarn: 'Per ricaricare e pagare le tasse',
    revenuepopupNeed: 'L\'imposta è attualmente dovuta',
    revenuepopupSurn: 'conferma',
    outstandingAmount: 'importo in sospeso',
    outstandingOrders: 'ordini in sospeso',
    totalSalesYesterday: 'Vendite totali ieri',
    yesterdaySalesOrders: 'Ordini di vendita di ieri',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Ricarica', // Ricarica
    currencyType1: 'Valuta fiat',
    currencyType2: 'Valuta virtuale',
    pleaseChoose: "Per favore scegli",
    cancelBtn: 'Annulla',
    submitBtn: 'conferma', // conferma
    placeholderNav: 'Inserisci l\'importo della ricarica', // saldo corrente
    placeholderCurrent: 'Inserisci l\'importo della ricarica', // inserisci l'importo
    dialogMessage: 'Seleziona il tipo di valuta. ',
    pathTitle: 'metodo di ricarica',// channel
    hintTitle: 'Importante:',
    hintContent: 'È consentito solo importare <assets',
    success: 'Copia riuscita! ',
    hintPopup: 'Vai a contattare il personale del servizio clienti!',
    hintTile: 'Richiesta informazioni'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Ritiro', // Ritiro
    currencyType1: 'Valuta fiat',
    currencyType2: 'Valuta virtuale',
    pleaseChoose: "Per favore scegli",
    cancelBtn: 'Annulla',
    submitBtn: 'conferma', // conferma
    placeholderNav: 'Inserisci l\'importo del prelievo',
    placeholderCurrent: 'Inserisci l\'importo del prelievo', // inserisci l'importo
    currentBalance: 'Saldo attuale', // Saldo attuale
    passwordTips: 'Inserisci la password per il prelievo', // inserisci l'importo
    pathTitle: 'tasso di cambio', // canale
    rateTips: 'Tasso di conversione attuale',
    dialogMessage: 'Seleziona il tipo di valuta. ',
    hintTitle: 'Importante:',
    hintContent: 'È consentito solo importare <assets',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'errore', // errore
    loadingTitle: 'Caricamento in corso', // Caricamento in corso
    finishedText: "nessuno", //nessuno
    loadingText: 'caricamento', // caricamento
    wait: 'Per favore aspetta un momento',
    placeholderHite: 'Il valore inserito non è corretto',
    uploadError: 'Caricamento non riuscito',
    upload: 'Caricamento in corso',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'centro personale', // centro personale
    bankCard: 'Impostazioni di prelievo', // Indirizzo di prelievo
    productLibrary: 'gestione prodotti', //libreria prodotti
    financialManagement: 'Registrazione fondi', //gestione finanziaria
    reportStatistics: 'Rapporto d\'affari', //Statistiche
    accountInformation: 'Informazioni negozio', //Cambia lingua
    setting: 'Impostazioni di sistema',
    goToShop: 'Al centro commerciale',
    goToLoan: 'prestito',
    downloadApp: 'Scarica app',
    activity: 'Attività'
}
// 活动页面
let activityLanguage = {
    navTitle: 'Centro attività',
    discount: 'sconto'
}
let discountLanguage = {
    navTitle: 'sconto',
    affirmBtn: 'Aggiungi',
    submitBtn: 'invia',
    accain: 'Completo',
    amount: 'l\'importo',
    ratio: 'Sconto',
    plusAffirm: 'affermare',
    placeholderEnter: 'si prega di inserire'
}

// 下载
let downloadApp = {
    navTitle: 'Scarica app',
    downloadAndroidApp: 'scarica app Android',
    downloadIosApp: 'scarica app ios',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Libreria prodotti', // Libreria prodotti
    productList: 'Memorizza prodotto', // Elenco prodotti
    productLibraryTitle: 'Prodotto piattaforma', // libreria di prodotti
    purchasePrice: 'Prezzo di acquisto',
    sellingPrice: 'Prezzo di vendita',
    selectAll: 'seleziona tutto',
    affirm: 'conferma',
    close: 'Annulla',
    editPrice: 'Modifica prezzo',
    deleteTitle: 'Elimina ordine',
    dialogMessage: 'Sei sicuro di voler eliminare questo prodotto? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Registri fondi',
    tabs1Title: 'Tutti i fondi', // Liquidità
    tabs2Title: 'ricarica record', // ricarica record
    tabs3Title: 'record di prelievo',//record di prelievo
    selectionPeriod: 'selezione dell\'ora',//seleziona l'ora
    rechargeTitle: 'ricarica',// ricarica
    wthdrawTitle: 'Ritiro',// Ritiro
    successful: 'successo',//successo
    balance: 'equilibrio', // equilibrio
    rechargeTime: 'tempo di ricarica',// tempo di ricarica
    rechargeMessage: 'tipo di valuta',// metodo di ricarica
    wthdrawTime: 'tempo di prelievo',// tempo di ricarica
    wthdrawMessage: 'tipo di valuta',// metodo di ricarica
    currency: "valuta",// valuta
    timeAffirm: 'Conferma',
    timeCancel: 'Annulla',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Vendite totali',
    totalPurchase: 'Importo totale dell\'acquisto',
    totalProfit: 'Profitto totale',
    selectionPeriod: 'time filter',//seleziona l'ora
    analysis: 'grafico a linee',//titolo del grafico a linee
    showTitle: 'Espandi',//Espandi il grafico a linee
    selectAll: 'seleziona tutto',//seleziona tutto
    cancel: 'chiudi',//chiudi
    sure: 'conferma',//conferma
    fundingDetails: 'dati dettagliati', // dettagli del finanziamento
    typeSelection: 'tipo di selezione',//tipo di selezione
    purchaseAmount: 'Importo acquisto', //Sales
    profitAmount: 'Importo profitto',//Vendite totali
    unitAmount: 'Sales', // acquisto rateale
    grandTotal: 'cumulativo',
    timeAffirm: 'Conferma',
    timeCancel: 'Annulla',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Elenco di gestione',
    selectionPeriod: 'Seleziona ora',//Seleziona ora
    totalPurchase: 'importo dell\'acquisto',
    totalSales: 'importo del profitto',
    totalProfit: 'Vendite',
    grandTotal: 'cumulativo',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Informazioni negozio',
    picture: 'immagine del negozio',
    nameTitle: 'Nome negozio',
    Mail: 'E-mail di accesso',
    invitationCode: 'Codice di invito',
    loginPassword: 'password di accesso',
    paymentPassword: 'password di pagamento',
    documents: 'informazioni sul documento',
    frontDocument: 'documento anteriore',
    backDocument: 'retro del documento',
    exit: 'disconnettersi',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Impostazioni prelievo', // Indirizzo di pagamento
    cashCurrency: 'Valuta fiat',
    virtualCurrency: 'Valuta virtuale',
    pleaseChoose: 'Scegli',
    popupAffirm: 'Conferma',
    popupCancel: 'Annulla',
    hintTitle: 'Importante:', // Supporta solo il prelievo a < indirizzo.
    hintContent: 'Consentito solo di importare < asset. ', // Supporta solo il ritiro a < indirizzo.
    affirmBtn: 'conferma', // conferma
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'vero nome',
    email: 'e-mail',
    mobilePhone: 'Numero di cellulare',
    pixType: 'tipo PIX',
    pixKey: 'Account PIX',
    pleaseEnter: 'Inserisci',
    pleaseSelect: 'Seleziona',

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'si prega di inserire ',
    country: 'paese/regione',
    name: 'nome',
    surname: 'cognome',
    bsb: 'BSB',
    bankName: 'Nome della banca',
    bankAccount: 'Conto bancario',
    state: 'Provincia o Stato',
    mobilePhone: 'Numero di cellulare',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'si prega di inserire ',
    country: 'paese/regione',
    name: 'nome',
    surname: 'cognome',
    swift: 'rapido',
    bankName: 'Nome della banca',
    iban: 'iban',
    mobilePhone: 'Numero di cellulare',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'si prega di inserire ',
    country: 'paese/regione',
    name: 'nome',
    surname: 'cognome',
    bankCode: 'codice banca',
    bankName: 'Nome della banca',
    bankAccount: 'Conto bancario',
    mobilePhone: 'Numero di cellulare',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'si prega di inserire ',
    country: 'paese/regione',
    name: 'nome',
    surname: 'cognome',
    zengin: 'zengin',
    bankName: 'Nome della banca',
    bankAccount: 'Conto bancario',
    county: 'contea',
    mobilePhone: 'Numero di cellulare',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'si prega di inserire ',
    country: 'paese/regione',
    name: 'nome',
    surname: 'cognome',
    swift: 'rapido',
    ach: 'ach',
    bankName: 'Nome della banca',
    bankAccount: 'Conto bancario',
    state: 'Provincia o Stato',
    mobilePhone: 'Numero di cellulare',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'si prega di inserire ',
    name: 'nome',
    surname: 'cognome',
    bankName: 'Nome della banca',
    bankAccount: 'Conto bancario',
    mobilePhone: 'Numero di cellulare',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Inserisci',
    bankName: 'Nome banca',
    account: 'Conto',
    clabe: 'conto clabe',
    mobilePhone: 'Numero di cellulare',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Indirizzo',
    pleaseInformation: 'Inserisci l\'indirizzo della valuta virtuale'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Modifica password di accesso', // password di accesso
    oldPass: 'vecchia password', // vecchia password
    newPass: 'nuova password', // nuova password
    email: 'Codice di verifica',
    placeholderOldPass: 'Inserisci la vecchia password',
    placeholderNewPass: 'Inserisci una nuova password',
    placeholderEmailCode: 'Inserisci il codice di verifica',
    submitBtn: 'invia', // conferma
    affirm: 'conferma', // conferma

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Modifica password pagamento', // Password pagamento
    affirmPass: 'password di pagamento',
    email: 'Codice di verifica', // Conferma password
    submitBtn: 'Invia',// Conferma
    affirm: 'conferma', // conferma
    placeholder: 'Inserisci',
    placeholderPaymentPassword: 'Inserisci la password per il pagamento',
    placeholderEmailCode: 'Inserisci il codice di verifica',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Impostazioni di sistema', // Setting
    languageShift: 'Impostazioni multilingue', // cambio lingua
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Impostazioni multilingue',
    affirm: 'confermare'
}
//  公告
let afficheDetail = {
    navTitle: 'messaggio', // notifica
    comment: 'rispondi',// commento
    unreadMessage: 'non letto',
    readTitle: 'leggere'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'dettagli messaggio', // notifica
    comment: 'commento',// commento
    check: 'Visualizza',
    packUpPlaceholder: 'Inserisci il contenuto della risposta',
    sendInform: 'invia'
}

// 客服
let serviceLanguage = {
    navTitle: 'Servizio clienti', // Servizio clienti
    customerService: 'servizio clienti esclusivo',//servizio clienti
    helloTitle: 'Ciao',
    welcomeTitle: 'Sono tuo <, puoi contattarmi nei seguenti modi',
    iconInformation: 'Selezionare un software di comunicazione per la consultazione',
    whatsApp: 'whatsApp ',
    telegram: 'telegramma',
    line: 'linea',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Gestione ordini", // Elenco ordini
    tabsTodos: "acquistato", // all
    tabsPendente: "da acquistare", // da acquistare
    tabsCompleto: "Nella logistica", // nella logistica
    tabsCancelar: "Completato", // Completato
    totalProfit: 'Profitto totale',
    statementPrice: "Total Purchase Amount", // Importo totale:
    affirmBtn: "Acquista", // conferma
    orderTotal: "Ordini totali",
    orderCode: "Numero ordine:",
    childrenDetail: 'prodotti', // dettagli
    checkDetail: 'Dettagli di produzione', // Visualizza i dettagli
    buyAll: 'Acquista tutto',
    dialogTitle: 'Informazioni sull\'acquisto',
    dialogTotalSales: 'Vendite totali',
    dialogTotalPurchase: 'Importo totale dell\'acquisto',
    dialogTotalProfit: 'Profitto totale',
    dialogBtn: 'Acquisti',
    deleteTitle: 'Ordine di acquisto',
    dialogMessage: 'Sei sicuro di acquistare questo ordine? ',
    dialogAllMessage: 'Sei sicuro di acquistare tutti gli ordini? ',
    affirm: 'conferma',
    close: 'Annulla',
    selectionPeriod: 'selezione dell\'ora',//seleziona l'ora
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Dettagli ordine", // Elenco ordini
    orderCode: "Numero ordine:",//Numero ordine
    totalProfitTitleCard: 'Profitto totale',
    statementPrice: "Total Purchase Amount", // Importo totale:
    amountRelated: 'Importo dell\'ordine',
    totalSalesTitle: 'Vendite totali',
    totalPurchase: 'Profitto totale',
    totalProfitTitle: 'Acquisti totali',
    btnTitle: 'Acquisto',
    deleteTitle: 'Ordine di acquisto',
    dialogMessage: 'Confermi la modifica dell\'ordine di acquisto? ',
    affirm: 'conferma',
    close: 'Annulla',
    salesTitle: 'Importo vendite',
    discount: 'rapporto di vendita',
    salesAmount: 'Importo vendite effettivo',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "ordina i dettagli del prodotto", // elenco degli ordini
    orderCode: "Numero ordine:",//Numero ordine
    productInformation: "Informazioni sul prodotto", // Informazioni sul prodotto
    totalProfitTitleCard: 'Profitto totale',
    statementPrice: "Importo totale acquisto:", // Importo totale:
    unitPrice: "prezzo unitario", // prezzo unitario
    salesVolume: "Prezzo di vendita totale", // Volume di vendita
    purchasePrices: "Purchase Unit Price", // Prezzo di acquisto
    purchasePricesTotal: "Prezzo di acquisto totale", // prezzo di acquisto
    profitPerPiece: "prezzo unitario di profitto", // profitto
    profitPerPieceTotal: "Prezzo profitto totale", // Profitto
    orderCardTitle: 'Pagamento',
    paymentMethods: "Metodo di pagamento", // Genera lifetime
    paymentTime: "tempo di pagamento", // tempo di pagamento
    returnedMoneyTitle: 'denaro restituito',
    returnedMoneyMethods: 'Se il denaro viene restituito',
    returnedMoneyMethods1: 'Non rimborsato',
    returnedMoneyMethods2: 'Il denaro è stato restituito',
    returnedMoneyTime: 'Tempo di ritorno',
    logisticsInformation: 'Informazioni logistiche',
    logisticsInformationTitle: 'Fai clic per visualizzare le ultime notizie sulla logistica',
    trackingNumber: 'Numero logistico',
    orderNumber: 'Numero ordine',
    logisticsCardTitle: 'Informazioni logistiche'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Si prega di inserire le condizioni della richiesta',
}
// 脚部语言
let footerLanguage = {
    home: 'Home',
    order: 'order',
    Workbench: 'Workbench',
}
let chatPageMessage = {
    ladingText: 'Scarica...',
    loosingText: 'Pubblica aggiornamenti...',
    pullingText: 'Aggiornamento menu a discesa...',
    send: 'Invia',
    messageText: 'notizie',
    connecting: 'Servizio IM in connessione...',
    connectionSuccess: 'La connessione al servizio IM è riuscita',
    connectionFail: 'Connessione al servizio IM fallita',
    connectionSendFail: 'Il servizio IM non è connesso, invio non riuscito',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Invita gli amici a guadagnare premi',
    qrCodeLanguage: 'Copia',
    ruleTitle: 'Regole di invito',
    rule1: 'L\'invitante può invitare gli amici a completare la registrazione condividendo il link della promozione e gli amici invitati verranno associati a te;',
    rule2: 'Dopo aver invitato gli utenti a completare il pagamento, puoi ottenere una quota delle loro entrate;',
    rule3: 'Supporta solo premi per inviti a tre livelli, il rapporto di ricompensa è: <%, >%, @%;',
    rule4: 'Il metodo di invito deve essere legale e in linea con le regole per invitare gli amici. Se c\'è un metodo di invito improprio o un comportamento che viola le regole per invitare gli amici, una volta verificato, tutti i premi non assegnati verranno detratti. La piattaforma si riserva il diritto di tracciare tutti i premi emessi e perseguire la responsabilità legale a seconda dei casi. ',
    situation: 'L\'ultimo record di invito',
    copySuccess: 'Copia riuscita',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Invita record utente',
    cardTitle: 'Numero totale di utenti',
    firstLevelUser: 'livello uno',
    secondaryUser: 'secondario',
    tertiaryUser: 'terziario',

    tabs1Title: 'Tutto',
    tabs2Title: 'livello uno',
    tabs3Title: 'Secondario',
    tabs4Title: 'Terzo livello',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Record dei ricavi degli inviti',
    cardTitle: 'Entrate totali',
    placeholder: 'Inserisci',
    firstLevelUser: 'livello uno',
    secondaryUser: 'secondario',
    tertiaryUser: 'terziario',
    store: 'importo totale',
    ratio: 'rapporto',
    commission: 'reddito',

    tabs1Title: 'Tutto',
    tabs2Title: 'livello uno',
    tabs3Title: 'Secondario',
    tabs4Title: 'Terzo livello',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Gestione vendite',
    navContent: 'Ordina la classifica delle vendite dei prodotti',
    tabs1: 'Vendite',
    tabs2: 'Importo totale',
    tabs3: 'Importo profitto',
    searchPlaceholder: 'Nome prodotto',
    date1Placeholder: 'Ora di inizio',
    date2Placeholder: 'Ora di fine',
    salesVolume: 'vendite',
    totalPrice: 'vendite totali',
    shelfTime: 'Profitto totale',
    ranking: 'NO',
    timeAffirm: 'Conferma',
    timeCancel: 'Annulla'
};
let rankingItemLanguage = {
    navTitle: 'Record vendite prodotto',
    date1Placeholder: 'Ora di inizio',
    date2Placeholder: 'Ora di fine',
    tabs1: 'Vendite',
    tabs2: 'tempo',
    salesTime: 'tempo',
    salesVolume: 'vendite',
    totalAmount: 'vendite totali',
    timeAffirm: 'Conferma',
    timeCancel: 'Annulla'

};
// 个人中心新增
myCenterLanguage.invitation = 'invitare'
myCenterLanguage.commission = 'commissione'

// 订单页面新增
orderPageLangage.orderBgTitle = 'Grado di vendita, lo so'
orderPageLangage.orderBgLeaveFor = 'vai a'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
