// 泰语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'อัพเกรดเวอร์ชั่น กรุณายืนยัน? ',// อัปเกรดเวอร์ชัน โปรดยืนยัน?
    loadingTitle: 'กำลังดาวน์โหลด...', // กำลังดาวน์โหลด...
    systemUpgrade: 'อัพเกรดระบบ', // อัพเกรดระบบ
    upgradeImmediately: 'ยืนยัน' // ยืนยัน
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'ชื่อร้าน',
    pass: 'รหัสผ่าน',
    placeholderPass: 'กรุณาใส่รหัสผ่าน',
    placeholderShopName: "กรุณากรอกชื่อร้าน",
    mail: "กล่องจดหมาย",
    placeholderMail: "กรุณาใส่อีเมลของคุณ",
    emailCode: "รหัสยืนยันอีเมล",
    placeholderEmailCode: "กรุณาใส่รหัสยืนยัน",
    send: "ส่ง",
    InvitationCode: 'รหัสเชิญ',
    PlaceholderInvitationCode: 'กรุณาใส่รหัสเชิญ',
    documents: 'อัปโหลดเอกสาร',
    front: 'เอกสารด้านหน้า',
    reverseSide: 'หน้าเอกสาร',
    placeholderFront: 'โปรดอัปโหลดด้านหน้าของใบรับรอง',
    placeholderReverseSide: 'โปรดอัปโหลดด้านหลังของเอกสาร',
    btn: 'สมัคร',
    applicationBtn: 'ยืนยัน',
    applicationNav: 'ข้อตกลงการสมัครร้านค้า',
    dialogTitle: 'ทบทวนความสำเร็จ',
    dialogMessage: 'การสมัครสำเร็จ โปรดรอเจ้าหน้าที่ตรวจสอบอย่างอดทน！',
    close: 'ยืนยัน',
}
// 登录
let loginLanguage = {
    navTitle: 'เข้าสู่ระบบ', // เข้าสู่ระบบ
    warningTitle: 'กรุณาใส่อีเมลสำหรับเข้าสู่ระบบ',
    passTitle: 'กรุณาป้อนรหัสผ่านเข้าสู่ระบบ', // รหัสผ่าน
    forgetPassword: 'ลืมรหัสผ่าน? ',//ลืมรหัสผ่าน
    btnTitle: 'เข้าสู่ระบบ', // เข้าสู่ระบบ
    hintTitle: 'ไม่มีบัญชี? ไปลงทะเบียน', // ลงทะเบียน
    linkTitle: 'การบริการลูกค้า', // การบริการลูกค้า
    AppDownload: 'ดาวน์โหลดแอป', //ดาวน์โหลดแอป
    goToShop: 'ไปห้าง',
    register: 'ลงทะเบียน'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'ลืมรหัสผ่าน',
    email: 'อีเมลสำหรับเข้าสู่ระบบ',
    placeholderEmail: 'กรุณาใส่อีเมลสำหรับเข้าสู่ระบบของคุณ',
    emailCode: 'รหัสยืนยัน',
    placeholderEmailCode: 'กรุณาใส่รหัสยืนยัน',
    newPass: 'รหัสผ่านใหม่',
    placeholderNewPass: "กรุณาป้อนรหัสผ่านใหม่",
    affirmPass: 'ยืนยันรหัสผ่าน',
    placeholderAffirmPass: 'กรุณาป้อนรหัสผ่านสองครั้ง',
    submitBtn: 'ส่ง',
    affirm: 'ยืนยัน',
}

// 首页
let homeLanguage = {
    navTitle: 'ยอดขายวันนี้', // หน้าแรก
    orderTitle: 'จำนวนการสั่งซื้อวันนี้',//Order
    balanceTitle: 'สมดุล',//สมดุล
    flowTitle: 'จำนวนการเข้าชมของวันนี้', //การเข้าชมของวันนี้
    navTitleLine: 'การขาย', // หน้าแรก
    orderTitleLine: 'จำนวนการสั่งซื้อ',//Order
    flowTitleLine: 'จำนวนโฟลว์', //โฟลว์ของวันนี้
    rechargeTitle: 'เติมเงิน', // เติมเงิน
    bankTitle: 'ถอน', // ถอน
    analysis: 'ภาพรวมของ 30 วันที่ผ่านมา',//ชื่อแผนภูมิเส้น
    showTitle: 'ขยาย',//ขยายแผนภูมิเส้น
    selectAll: 'เลือกทั้งหมด',//เลือกทั้งหมด
    cancel: 'ปิด',//ปิด
    sure: 'ยืนยัน',//ยืนยัน
    revenueLanguage: 'ภาษี',
    revenuepopupTitle: 'เตือนภาษี',
    revenuepopupWarn: 'เติมเงินและชำระภาษี',
    revenuepopupNeed: 'ภาษีถึงกำหนดชำระ',
    revenuepopupSurn: 'ยืนยัน',
    outstandingAmount: 'ยอดคงค้าง',
    outstandingOrders: 'คำสั่งซื้อคงค้าง',
    totalSalesYesterday: 'ยอดขายรวมเมื่อวาน',
    yesterdaySalesOrders: 'คำสั่งขายเมื่อวาน',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'เติมเงิน', // เติมเงิน
    currencyType1: 'สกุลเงินเฟียต',
    currencyType2: 'สกุลเงินเสมือนจริง',
    pleaseChoose: "กรุณาเลือก",
    cancelBtn: 'ยกเลิก',
    submitBtn: 'ยืนยัน', // ยืนยัน
    placeholderNav: 'ป้อนจำนวนเงินที่เติม', // ยอดเงินปัจจุบัน
    placeholderCurrent: 'กรุณาป้อนจำนวนเงินที่เติม', // ป้อนจำนวนเงิน
    dialogMessage: 'กรุณาเลือกประเภทสกุลเงิน ',
    pathTitle: 'วิธีเติมเงิน',//channel
    hintTitle: 'สำคัญ:',
    hintContent: 'อนุญาตให้นำเข้า <สินทรัพย์\' เท่านั้น',
    success: 'คัดลอกสำเร็จ! ',
    hintPopup: 'โปรดติดต่อเจ้าหน้าที่ฝ่ายบริการลูกค้า!',
    hintTile: 'ข้อมูลพรอมต์'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'ถอน', // ถอน
    currencyType1: 'สกุลเงินเฟียต',
    currencyType2: 'สกุลเงินเสมือนจริง',
    pleaseChoose: "กรุณาเลือก",
    cancelBtn: 'ยกเลิก',
    submitBtn: 'ยืนยัน', // ยืนยัน
    placeholderNav: 'ป้อนจำนวนเงินที่ถอน',
    placeholderCurrent: 'กรุณาใส่จำนวนเงินที่ถอน', // ป้อนจำนวนเงิน
    currentBalance: 'ยอดเงินปัจจุบัน', // ยอดเงินปัจจุบัน
    passwordTips: 'กรุณาใส่รหัสผ่านการถอน', // ใส่จำนวนเงิน
    pathTitle: 'อัตราแลกเปลี่ยน', // channel
    rateTips: 'อัตราการแปลงปัจจุบัน',
    dialogMessage: 'กรุณาเลือกประเภทสกุลเงิน ',
    hintTitle: 'สำคัญ:',
    hintContent: 'อนุญาตให้นำเข้า <สินทรัพย์\' เท่านั้น'
}

// 提示文本
let messageLanguage = {
    warningTitle: 'ข้อผิดพลาด', // ข้อผิดพลาด
    loadingTitle: 'กำลังโหลด', // กำลังโหลด
    finishedText: "ไม่มี", //ไม่มี
    loadingText: 'กำลังโหลด', // กำลังโหลด
    wait: 'โปรดรอสักครู่',
    placeholderHite: 'ค่าที่ป้อนไม่ถูกต้อง',
    uploadError: 'การอัปโหลดล้มเหลว',
    upload: 'กำลังอัปโหลด',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'ศูนย์ส่วนบุคคล', // ศูนย์ส่วนบุคคล
    bankCard: 'การตั้งค่าการถอน', // ที่อยู่การถอน
    productLibrary: 'การจัดการผลิตภัณฑ์', // ไลบรารีผลิตภัณฑ์
    financialManagement: 'บันทึกกองทุน', // การจัดการทางการเงิน
    reportStatistics: 'รายงานธุรกิจ', //สถิติ
    accountInformation: 'ข้อมูลร้านค้า', //เปลี่ยนภาษา
    setting: 'การตั้งค่าระบบ',
    goToShop: 'ไปห้าง',
    goToLoan: 'เงินกู้',
    downloadApp: 'ดาวน์โหลดแอป',
    activity: 'กิจกรรม'
}
// 活动页面
let activityLanguage = {
    navTitle: 'ศูนย์กิจกรรม',
    discount: 'ส่วนลด'
}
let discountLanguage = {
    navTitle: 'ส่วนลด',
    affirmBtn: 'เพิ่ม',
    submitBtn: 'ส่ง',
    accain: 'เต็ม',
    amount: 'จำนวนเงิน',
    ratio: 'ส่วนลด',
    plusAffirm: 'ยืนยัน',
    placeholderEnter: 'กรุณาป้อน'
}

// 下载
let downloadApp = {
    navTitle: 'ดาวน์โหลดแอป',
    downloadAndroidApp: 'ดาวน์โหลดแอพ android',
    downloadIosApp: 'ดาวน์โหลดแอป ios'
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'คลังผลิตภัณฑ์', // คลังผลิตภัณฑ์
    productList: 'เก็บสินค้า', // รายการสินค้า
    productLibraryTitle: 'ผลิตภัณฑ์แพลตฟอร์ม', // ไลบรารีผลิตภัณฑ์
    purchasePrice: 'ราคาซื้อ',
    sellingPrice: 'ราคาขาย',
    selectAll: 'เลือกทั้งหมด',
    affirm: 'ยืนยัน',
    close: 'ยกเลิก',
    editPrice: 'แก้ไขราคา',
    deleteTitle: 'ลบคำสั่งซื้อ',
    dialogMessage: 'คุณแน่ใจหรือไม่ว่าต้องการลบผลิตภัณฑ์นี้? ',
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'บันทึกกองทุน',
    tabs1Title: 'เงินทั้งหมด', // สภาพคล่อง
    tabs2Title: 'บันทึกการเติมเงิน', // บันทึกการเติมเงิน
    tabs3Title: 'บันทึกการถอนเงิน',//บันทึกการถอนเงิน
    selectionPeriod: 'การเลือกเวลา',//เลือกเวลา
    rechargeTitle: 'เติมเงิน',// เติมเงิน
    wthdrawTitle: 'การถอน',// การถอน
    successful: 'สำเร็จ',//สำเร็จ
    balance: 'สมดุล', // สมดุล
    rechargeTime: 'เวลาเติมเงิน',// เวลาเติมเงิน
    rechargeMessage: 'ประเภทสกุลเงิน', // วิธีเติมเงิน
    wthdrawTime: 'เวลาถอน',// เวลาเติมเงิน
    wthdrawMessage: 'ประเภทสกุลเงิน',// วิธีเติมเงิน
    currency: "สกุลเงิน",// สกุลเงิน
    timeAffirm: 'ยืนยัน',
    timeCancel: 'ยกเลิก',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'ยอดขายรวม',
    totalPurchase: 'จำนวนการซื้อทั้งหมด',
    totalProfit: 'กำไรทั้งหมด',
    selectionPeriod: 'ตัวกรองเวลา',//เลือกเวลา
    analysis: 'แผนภูมิเส้น',//ชื่อแผนภูมิเส้น
    showTitle: 'ขยาย',//ขยายแผนภูมิเส้น
    selectAll: 'เลือกทั้งหมด',//เลือกทั้งหมด
    cancel: 'ปิด',//ปิด
    sure: 'ยืนยัน',//ยืนยัน
    fundingDetails: 'ข้อมูลโดยละเอียด', // รายละเอียดของเงินทุน
    typeSelection: 'ประเภทการเลือก',//ประเภทการเลือก
    purchaseAmount: 'จำนวนซื้อ', //ยอดขาย
    profitAmount: 'จำนวนกำไร',//ยอดขายรวม
    unitAmount: 'Sales', // การซื้อแบบผ่อนชำระ
    grandTotal: 'สะสม',
    timeAffirm: 'ยืนยัน',
    timeCancel: 'ยกเลิก',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'รายการการจัดการ',
    selectionPeriod: 'เลือกเวลา',//เลือกเวลา
    totalPurchase: 'จำนวนซื้อ',
    totalSales: 'จำนวนกำไร',
    totalProfit: 'การขาย',
    grandTotal: 'สะสม',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'ข้อมูลร้านค้า',
    picture: 'รูปภาพร้านค้า',
    nameTitle: 'ชื่อร้าน',
    Mail: 'อีเมลสำหรับเข้าสู่ระบบ',
    invitationCode: 'รหัสเชิญ',
    loginPassword: 'รหัสผ่านสำหรับเข้าสู่ระบบ',
    paymentPassword: 'รหัสผ่านการชำระเงิน',
    documents: 'ข้อมูลเอกสาร',
    frontDocument: 'เอกสารด้านหน้า',
    backDocument: 'ด้านหลังของเอกสาร',
    exit: 'ออกจากระบบ',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'การตั้งค่าการถอน', // ที่อยู่การชำระเงิน
    cashCurrency: 'สกุลเงิน Fiat',
    virtualCurrency: 'สกุลเงินเสมือน',
    pleaseChoose: 'กรุณาเลือก',
    popupAffirm: 'ยืนยัน',
    popupCancel: 'ยกเลิก',
    hintTitle: 'สำคัญ:', // รองรับเฉพาะการถอนไปยังที่อยู่ <
    hintContent: 'อนุญาตให้นำเข้า < เนื้อหาเท่านั้น ', // รองรับเฉพาะการถอนไปยังที่อยู่ <
    affirmBtn: 'ยืนยัน' // ยืนยัน
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'ชื่อจริง',
    email: 'อีเมล',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
    pixType: 'ประเภท PIX',
    pixKey: 'บัญชี PIX',
    pleaseEnter: 'กรุณาป้อน',
    pleaseSelect: 'กรุณาเลือก',

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'กรุณาป้อน',
    country: 'ประเทศ/ภูมิภาค',
    name: 'ชื่อ',
    surname: 'นามสกุล',
    bsb: 'บีเอสบี',
    bankName: 'ชื่อธนาคาร',
    bankAccount: 'บัญชีธนาคาร',
    state: 'จังหวัดหรือรัฐ',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'กรุณาป้อน',
    country: 'ประเทศ/ภูมิภาค',
    name: 'ชื่อ',
    surname: 'นามสกุล',
    swift: 'รวดเร็ว',
    bankName: 'ชื่อธนาคาร',
    iban: 'อิบัน',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'กรุณาป้อน',
    country: 'ประเทศ/ภูมิภาค',
    name: 'ชื่อ',
    surname: 'นามสกุล',
    bankCode: 'รหัสธนาคาร',
    bankName: 'ชื่อธนาคาร',
    bankAccount: 'บัญชีธนาคาร',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'กรุณาป้อน',
    country: 'ประเทศ/ภูมิภาค',
    name: 'ชื่อ',
    surname: 'นามสกุล',
    zengin: 'เซงจิน',
    bankName: 'ชื่อธนาคาร',
    bankAccount: 'บัญชีธนาคาร',
    county: 'มณฑล',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'กรุณาป้อน',
    country: 'ประเทศ/ภูมิภาค',
    name: 'ชื่อ',
    surname: 'นามสกุล',
    swift: 'รวดเร็ว',
    ach: 'อค',
    bankName: 'ชื่อธนาคาร',
    bankAccount: 'บัญชีธนาคาร',
    state: 'จังหวัดหรือรัฐ',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'กรุณาป้อน',
    name: 'ชื่อ',
    surname: 'นามสกุล',
    bankName: 'ชื่อธนาคาร',
    bankAccount: 'บัญชีธนาคาร',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'กรุณาป้อน',
    bankName: 'ชื่อธนาคาร',
    account: 'บัญชี',
    clabe: 'บัญชีกลุ่ม',
    mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'ที่อยู่',
    pleaseInformation: 'กรุณาป้อนที่อยู่สกุลเงินเสมือน'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'แก้ไขรหัสผ่านเข้าสู่ระบบ', // รหัสผ่านเข้าสู่ระบบ
    oldPass: 'รหัสผ่านเก่า', // รหัสผ่านเก่า
    newPass: 'รหัสผ่านใหม่', // รหัสผ่านใหม่
    email: 'รหัสยืนยัน',
    placeholderOldPass: 'โปรดป้อนรหัสผ่านเดิม',
    placeholderNewPass: 'โปรดป้อนรหัสผ่านใหม่',
    placeholderEmailCode: 'กรุณาใส่รหัสยืนยัน',
    submitBtn: 'ส่ง', // ยืนยัน
    affirm: 'ยืนยัน' // ยืนยัน

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'การแก้ไขรหัสผ่านการชำระเงิน', // รหัสผ่านการชำระเงิน
    affirmPass: 'รหัสผ่านการชำระเงิน',
    email: 'รหัสยืนยัน', // ยืนยันรหัสผ่าน
    submitBtn: 'ส่ง', // ยืนยัน
    affirm: 'ยืนยัน', // ยืนยัน
    placeholder: 'กรุณาป้อน',
    placeholderPaymentPassword: 'โปรดป้อนรหัสผ่านการชำระเงิน',
    placeholderEmailCode: 'กรุณาใส่รหัสยืนยัน',
}

//  系统设置
let settingLanguage = {
    navTitle: 'การตั้งค่าระบบ', // การตั้งค่า
    languageShift: 'การตั้งค่าหลายภาษา', // การเปลี่ยนภาษา
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'การตั้งค่าหลายภาษา',
    affirm: 'ยืนยัน'
}
//  公告
let afficheDetail = {
    navTitle: 'ข้อความ', // การแจ้งเตือน
    comment: 'ตอบกลับ', // ความคิดเห็น
    unreadMessage: 'ยังไม่ได้อ่าน',
    readTitle: 'อ่าน'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'รายละเอียดข้อความ', // การแจ้งเตือน
    comment: 'ความคิดเห็น',// ความคิดเห็น
    check: 'ดู',
    packUpPlaceholder: 'กรุณาใส่เนื้อหาตอบกลับ',
    sendInform: 'ส่ง'
}

// 客服
let serviceLanguage = {
    navTitle: 'ฝ่ายบริการลูกค้า', // ฝ่ายบริการลูกค้า
    customerService: 'บริการลูกค้าพิเศษ',//บริการลูกค้า
    helloTitle: 'สวัสดี',
    welcomeTitle: 'ฉันเป็นของคุณ < คุณสามารถติดต่อฉันได้ด้วยวิธีต่อไปนี้',
    iconInformation: 'โปรดเลือกซอฟต์แวร์การสื่อสารเพื่อขอคำปรึกษา',
    whatsApp: 'วอทส์แอพ',
    telegram: 'โทรเลข',
    line: 'บรรทัด',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "การจัดการคำสั่งซื้อ", // รายการคำสั่งซื้อ
    tabsTodos: "ซื้อ", // ทั้งหมด
    tabsPendente: "ที่จะซื้อ", // ที่จะซื้อ
    tabsCompleto: "ในการขนส่ง", // ในการขนส่ง
    tabsCancelar: "เสร็จสมบูรณ์", // เสร็จสิ้น
    totalProfit: 'กำไรรวม',
    statementPrice: "ยอดซื้อทั้งหมด", // ยอดรวม:
    affirmBtn: "ซื้อ", // ยืนยัน
    orderTotal: "คำสั่งซื้อทั้งหมด",
    orderCode: "หมายเลขคำสั่งซื้อ:",
    childrenDetail: 'ผลิตภัณฑ์', // รายละเอียด
    checkDetail: 'รายละเอียดการผลิต', // ดูรายละเอียด
    buyAll: 'ซื้อทั้งหมด',
    dialogTitle: 'ข้อมูลการจัดซื้อ',
    dialogTotalSales: 'ยอดขายรวม',
    dialogTotalPurchase: 'จำนวนการซื้อทั้งหมด',
    dialogTotalProfit: 'กำไรทั้งหมด',
    dialogBtn: 'การจัดซื้อ',
    deleteTitle: 'การสั่งซื้อ',
    dialogMessage: 'คุณแน่ใจหรือที่จะสั่งซื้อใบสั่งซื้อนี้? ',
    dialogAllMessage: 'คุณแน่ใจหรือไม่ที่จะซื้อคำสั่งซื้อทั้งหมด? ',
    affirm: 'ยืนยัน',
    close: 'ยกเลิก',
    selectionPeriod: 'การเลือกเวลา'//เลือกเวลา
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "รายละเอียดการสั่งซื้อ", // รายการสั่งซื้อ
    orderCode: "หมายเลขคำสั่งซื้อ:",//หมายเลขคำสั่งซื้อ
    totalProfitTitleCard: 'กำไรรวม',
    statementPrice: "ยอดซื้อทั้งหมด", // ยอดรวม:
    amountRelated: 'จำนวนการสั่งซื้อ',
    totalSalesTitle: 'ยอดขายรวม',
    totalPurchase: 'กำไรทั้งหมด',
    totalProfitTitle: 'การซื้อทั้งหมด',
    btnTitle: 'ซื้อ',
    deleteTitle: 'การสั่งซื้อ',
    dialogMessage: 'คุณยืนยันการเปลี่ยนแปลงใบสั่งซื้อหรือไม่? ',
    affirm: 'ยืนยัน',
    close: 'ยกเลิก',
    salesTitle: 'ยอดขาย',
    discount: 'อัตราส่วนการขาย',
    salesAmount: 'ยอดขายจริง'
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "รายละเอียดสินค้าสั่งซื้อ", // รายการสั่งซื้อ
    orderCode: "หมายเลขคำสั่งซื้อ:",//หมายเลขคำสั่งซื้อ
    productInformation: "ข้อมูลผลิตภัณฑ์", // ข้อมูลผลิตภัณฑ์
    totalProfitTitleCard: 'กำไรรวม',
    statementPrice: "ยอดซื้อทั้งหมด:", // ยอดรวม:
    unitPrice: "ราคาต่อหน่วย", // ราคาต่อหน่วย
    salesVolume: "ราคาขายรวม", // ปริมาณการขาย
    purchasePrices: "ซื้อราคาต่อหน่วย", // ราคาซื้อ
    purchasePricesTotal: "ราคาซื้อทั้งหมด", // ราคาซื้อ
    profitPerPiece: "ราคาต่อหน่วยกำไร", // กำไร
    profitPerPieceTotal: "ราคากำไรรวม", // Profit
    orderCardTitle: 'การชำระเงิน',
    paymentMethods: "วิธีการชำระเงิน", // สร้างอายุการใช้งาน
    paymentTime: "เวลาชำระเงิน", // เวลาชำระเงิน
    returnedMoneyTitle: 'คืนเงิน',
    returnedMoneyMethods: 'ไม่ว่าจะคืนเงิน',
    returnedMoneyMethods1: 'ไม่จ่ายคืน',
    returnedMoneyMethods2: 'คืนเงินแล้ว',
    returnedMoneyTime: 'เวลาคืน',
    logisticsInformation: 'ข้อมูลโลจิสติก',
    logisticsInformationTitle: 'คลิกเพื่อดูข่าวโลจิสติกส์ล่าสุด',
    trackingNumber: 'หมายเลขโลจิสติก',
    orderNumber: 'หมายเลขคำสั่งซื้อ',
    logisticsCardTitle: 'ข้อมูลโลจิสติก'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'กรุณากรอกเงื่อนไขการสืบค้น',
}
// 脚部语言
let footerLanguage = {
    home: 'บ้าน',
    order: 'คำสั่ง',
    Workbench: 'โต๊ะทำงาน',
}
let chatPageMessage = {
    ladingText: 'ดาวน์โหลด...',
    loosingText: 'เผยแพร่การอัปเดต...',
    pullingText: 'การอัปเดตแบบเลื่อนลง...',
    send: 'ส่ง',
    messageText: 'ข่าว',
    connecting: 'บริการ IM กำลังเชื่อมต่อ...',
    connectionSuccess: 'การเชื่อมต่อบริการ IM สำเร็จ',
    connectionFail: 'การเชื่อมต่อบริการ IM ล้มเหลว',
    connectionSendFail: 'ไม่ได้เชื่อมต่อบริการ IM การส่งล้มเหลว'
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'เชิญเพื่อนเพื่อรับรางวัล',
    qrCodeLanguage: 'คัดลอก',
    ruleTitle: 'กฎการเชิญ',
    rule1: 'ผู้เชิญสามารถเชิญเพื่อนให้ลงทะเบียนให้เสร็จสมบูรณ์โดยการแชร์ลิงก์โปรโมชัน และเพื่อนที่ถูกเชิญจะเชื่อมโยงกับคุณ',
    rule2: 'หลังจากเชิญผู้ใช้ให้ชำระเงินเสร็จแล้ว คุณจะได้รับส่วนแบ่งรายได้ของพวกเขา',
    rule3: 'รองรับรางวัลเชิญสามระดับเท่านั้น อัตราส่วนรางวัลคือ: <%, >%, @%;',
    rule4: 'วิธีการเชิญจะต้องถูกกฎหมายและสอดคล้องกับกฎสำหรับการเชิญเพื่อน หากมีวิธีการเชิญที่ไม่เหมาะสมหรือพฤติกรรมที่ละเมิดกฎสำหรับการเชิญเพื่อน เมื่อได้รับการยืนยันแล้ว รางวัลที่ยังไม่ได้ออกทั้งหมดจะถูกหักออก แพลตฟอร์มขอสงวนสิทธิ์ในการติดตามรางวัลที่ออกทั้งหมด และดำเนินการตามความรับผิดชอบทางกฎหมายแล้วแต่กรณี ',
    situation: 'บันทึกคำเชิญล่าสุด',
    copySuccess: 'คัดลอกสำเร็จ'
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'เชิญบันทึกผู้ใช้',
    cardTitle: 'จำนวนผู้ใช้ทั้งหมด',
    firstLevelUser: 'ระดับหนึ่ง',
    secondaryUser: 'รอง',
    tertiaryUser: 'ระดับอุดมศึกษา',

    tabs1Title: 'ทั้งหมด',
    tabs2Title: 'ระดับหนึ่ง',
    tabs3Title: 'รอง',
    tabs4Title: 'ระดับที่สาม',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'บันทึกรายได้จากการเชิญ',
    cardTitle: 'รายได้ทั้งหมด',
    placeholder: 'กรุณาป้อน',
    firstLevelUser: 'ระดับหนึ่ง',
    secondaryUser: 'รอง',
    tertiaryUser: 'ระดับอุดมศึกษา',
    store: 'จำนวนเงินทั้งหมด',
    ratio: 'อัตราส่วน',
    commission: 'รายได้',

    tabs1Title: 'ทั้งหมด',
    tabs2Title: 'ระดับหนึ่ง',
    tabs3Title: 'รอง',
    tabs4Title: 'ระดับที่สาม'
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'การจัดการการขาย',
    navContent: 'จัดลำดับยอดขายผลิตภัณฑ์',
    tabs1: 'การขาย',
    tabs2: 'จำนวนเงินทั้งหมด',
    tabs3: 'จำนวนกำไร',
    searchPlaceholder: 'ชื่อผลิตภัณฑ์',
    date1Placeholder: 'เวลาเริ่มต้น',
    date2Placeholder: 'เวลาสิ้นสุด',
    salesVolume: 'ยอดขาย',
    totalPrice: 'ยอดขายรวม',
    shelfTime: 'กำไรรวม',
    ranking: 'ไม่',
    timeAffirm: 'ยืนยัน',
    timeCancel: 'ยกเลิก'
};
let rankingItemLanguage = {
    navTitle: 'บันทึกการขายผลิตภัณฑ์',
    date1Placeholder: 'เวลาเริ่มต้น',
    date2Placeholder: 'เวลาสิ้นสุด',
    tabs1: 'การขาย',
    tabs2: 'เวลา',
    salesTime: 'เวลา',
    salesVolume: 'ยอดขาย',
    totalAmount: 'ยอดขายรวม',
    timeAffirm: 'ยืนยัน',
    timeCancel: 'ยกเลิก'

};
// 个人中心新增
myCenterLanguage.invitation = 'เชิญ'
myCenterLanguage.commission = 'คณะกรรมการ'

// 订单页面新增
orderPageLangage.orderBgTitle = 'อันดับการขายฉันรู้'
orderPageLangage.orderBgLeaveFor = 'ไปที่'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
