import service from '@/required/websotrcketAxios'
export default {

    /**
     * @description 上传图片
     * @param {*} file 
     * @returns 
     */
    updateImagesPort (body) {
        return service({
            url: "/upload",
            method: "post",
            data: body,
        });
    },
    /**
     * @description 发送消息
     * @param {*} memberId  消息接收用户ID
     * @param {*} type 消息类型 [text image]
     * @param {*} content 消息内容
     * @returns  code data
     */
    sendMessagePort (body) {
        return service({
            url: "/send_message",
            method: "post",
            data: body,
        });
    },
    /**
     * @description 消息历史记录
     * @param {*} memberId  消息接收用户ID
     * @param {*} id 消息ID
     * @param {*} size 返回条数
     * @returns  code data
     */
    historyMessagePort (body) {
        return service({
            url: "/message_history",
            method: "get",
            params: {
                memberId: body.memberId,
                id: body.id,
                size: body.size,
            }
        });
    },
    /**
     * @description 消息已读
     * @param {*} memberId  消息接收用户ID 
     * @returns  code data
     */
    readMessagePort (body) {
        return service({
            url: "/read_message",
            method: "post",
            data: body,
        });
    },
    /**
     * @description 用户列表
     * @param {*} forceReload  是否强制更新列表缓存 
     * @returns  code data
     */
    listMessagePort (body) {
        return service({
            url: "/member_list",
            method: "get",
            params: {
                forceReload: body.forceReload
            }
        });
    },
    /**
     * @description  获取未读消息数 
     * @returns  code data
     */
    unreadMessagePort () {
        return service({
            url: "/get_unread_message_number",
            method: "post",
        });
    },
    /**
     * @description 注册
     * @param {*} clientId  IM客户端ID 
     * @returns  code data
     */
    registerMessagePort (body) {
        return service({
            url: "/register",
            method: "post",
            data: body
        });
    },
}