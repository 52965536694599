// 法语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Mise à jour de la version, veuillez confirmer? ',// Mise à jour de la version, veuillez confirmer?
    loadingTitle: 'Téléchargement...', // Téléchargement...
    systemUpgrade: 'mise à niveau du système', // mise à niveau du système
    upgradeImmediately: 'Confirmer', // Confirmer
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'nom de la boutique',
    pass: 'mot de passe',
    placeholderPass: 'Veuillez saisir votre mot de passe',
    placeholderShopName: "Veuillez entrer le nom de la boutique",
    mail: "Boîte aux lettres",
    placeholderMail: "Veuillez saisir votre e-mail",
    emailCode: "Code de vérification de l'e-mail",
    placeholderEmailCode: "Veuillez entrer le code de vérification",
    send: "Envoyer",
    InvitationCode: 'Code d\'invitation',
    PlaceholderInvitationCode: 'Veuillez entrer le code d\'invitation',
    documents: 'Télécharger des documents',
    front: 'recto du document',
    reverseSide: 'recto du document',
    placeholderFront: 'Veuillez télécharger le recto du certificat',
    placeholderReverseSide: 'Veuillez télécharger le verso du document',
    btn: 'Appliquer',
    applicationBtn: 'Confirmer',
    applicationNav: 'Accord d\'application de magasin',
    dialogTitle: 'Examiner le succès',
    dialogMessage: 'L\'application a réussi, veuillez attendre patiemment que le personnel l\'examine！',
    close: 'Confirmer',
}
// 登录
let loginLanguage = {
    navTitle: 'connexion', // connexion
    warningTitle: 'Veuillez entrer votre e-mail de connexion',
    passTitle: 'Veuillez entrer le mot de passe de connexion', // mot de passe
    forgetPassword: 'Mot de passe oublié? ',//Mot de passe oublié
    btnTitle: 'connexion', // connexion
    hintTitle: 'Pas de compte? aller s\'inscrire', // s'inscrire
    linkTitle: 'service client', // service client
    AppDownload: 'Télécharger l\'application', //Télécharger l'application
    goToShop: 'Au centre commercial',
    register: 'Enregistrement'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Mot de passe oublié',
    email: 'e-mail de connexion',
    placeholderEmail: 'Veuillez saisir votre e-mail de connexion',
    emailCode: 'Code de vérification',
    placeholderEmailCode: 'Veuillez entrer le code de vérification',
    newPass: 'Nouveau mot de passe',
    placeholderNewPass: "Veuillez saisir un nouveau mot de passe",
    affirmPass: 'Confirmer le mot de passe',
    placeholderAffirmPass: 'Veuillez entrer le mot de passe deux fois',
    submitBtn: 'envoyer',
    affirm: 'confirmer',
}

// 首页
let homeLanguage = {
    navTitle: 'Promotions du jour', // Accueil
    orderTitle: 'Nombre de commandes aujourd\'hui',//Commande
    balanceTitle: 'balance',//balance
    flowTitle: 'Compte de trafic d\'aujourd\'hui', // Trafic d'aujourd'hui
    navTitleLine: 'Ventes', // Accueil
    orderTitleLine: 'Nombre de commandes',//Commande
    flowTitleLine: 'Numéro du flux', // Flux d'aujourd'hui
    rechargeTitle: 'Recharge', // recharge
    bankTitle: 'Retirer', // Retrait
    analysis: 'Vue d\'ensemble des 30 derniers jours',//Titre du graphique en courbes
    showTitle: 'Développer',//Développer le graphique en courbes
    selectAll: 'sélectionner tout',//sélectionner tout
    cancel: 'fermer',//fermer
    sure: 'confirmer',//confirmer
    revenueLanguage: 'tax',
    revenuepopupTitle: 'Rappel fiscal',
    revenuepopupWarn: 'Pour recharger et payer l\'impôt',
    revenuepopupNeed: 'La taxe est actuellement due',
    revenuepopupSurn: 'confirmer',
    outstandingAmount: 'montant en cours',
    outstandingOrders: 'commandes en cours',
    totalSalesYesterday: 'Total des ventes hier',
    yesterdaySalesOrders: 'Commandes client d\'hier',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Recharger', // Recharger
    currencyType1: 'Monnaie fiduciaire',
    currencyType2: 'Monnaie virtuelle',
    pleaseChoose: "Veuillez choisir",
    cancelBtn: 'Annuler',
    submitBtn: 'confirmer', // confirmer
    placeholderNav: 'Entrez le montant de la recharge', // solde actuel
    placeholderCurrent: 'Veuillez entrer le montant de la recharge', // entrez le montant
    dialogMessage: 'Veuillez sélectionner le type de devise. ',
    pathTitle: 'méthode de recharge',// canal
    hintTitle: 'Important:',
    hintContent: 'Uniquement autorisé à importer <assets',
    success: 'Copie réussie! ',
    hintPopup: 'Allez contacter le service clientèle!',
    hintTile: 'Info conseils'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Retirer', // Retrait
    currencyType1: 'Monnaie fiduciaire',
    currencyType2: 'Monnaie virtuelle',
    pleaseChoose: "Veuillez choisir",
    cancelBtn: 'Annuler',
    submitBtn: 'confirmer', // confirmer
    placeholderNav: 'Entrez le montant du retrait',
    placeholderCurrent: 'Veuillez entrer le montant du retrait', // entrez le montant
    currentBalance: 'Current Balance', // Solde actuel
    passwordTips: 'Veuillez entrer le mot de passe de retrait', // entrez le montant
    pathTitle: 'taux de change', // canal
    rateTips: 'Taux de conversion actuel',
    dialogMessage: 'Veuillez sélectionner le type de devise. ',
    hintTitle: 'Important:',
    hintContent: 'Uniquement autorisé à importer <assets',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'erreur', // erreur
    loadingTitle: 'Chargement', // Chargement
    finishedText: "aucun", //aucun
    loadingText: 'chargement', // chargement
    wait: 'Veuillez patienter un instant',
    placeholderHite: 'La valeur d\'entrée est incorrecte',
    uploadError: 'Le téléchargement a échoué',
    upload: 'Téléchargement',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'centre personnel', // centre personnel
    bankCard: 'Paramètres de retrait', // Adresse de retrait
    productLibrary: 'gestion des produits', // bibliothèque de produits
    financialManagement: 'Enregistrement du fonds', //gestion financière
    reportStatistics: 'Rapport d\'activité', //Statistiques
    accountInformation: 'Enregistrer les informations', // Changer de langue
    setting: 'Paramètres système',
    goToShop: 'Au centre commercial',
    goToLoan: 'prêt',
    downloadApp: 'Télécharger l\'application',
    activity: 'Activité'
}
// 活动页面
let activityLanguage = {
    navTitle: 'centre d\'activités',
    discount: 'remise'
}
let discountLanguage = {
    navTitle: 'réduction',
    affirmBtn: 'Ajouter',
    submitBtn: 'soumettre',
    accain: 'Complet',
    amount: 'le montant',
    ratio: 'Remise',
    plusAffirm: 'affirmer',
    placeholderEnter: 'veuillez entrer'
}

// 下载
let downloadApp = {
    navTitle: 'Télécharger l\'application',
    downloadAndroidApp: 'télécharger l\'application Android',
    downloadIosApp: 'télécharger l\'application ios',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Bibliothèque de produits', // Bibliothèque de produits
    productList: 'Store Product', // Liste de produits
    productLibraryTitle: 'Produit de plate-forme', // bibliothèque de produits
    purchasePrice: 'Prix d\'achat',
    sellingPrice: 'Prix de vente',
    selectAll: 'tout sélectionner',
    affirm: 'confirmer',
    close: 'Annuler',
    editPrice: 'Modifier le prix',
    deleteTitle: 'Supprimer la commande',
    dialogMessage: 'Êtes-vous sûr de vouloir supprimer ce produit? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Enregistrements de fonds',
    tabs1Title: 'Tous les fonds', // Liquidité
    tabs2Title: 'recharger l\'enregistrement', // recharger l'enregistrement
    tabs3Title: 'enregistrement de retrait',//enregistrement de retrait
    selectionPeriod: 'sélection de l\'heure',//sélectionner l'heure
    rechargeTitle: 'recharge',// recharge
    wthdrawTitle: 'Retrait',// Retrait
    successful: 'succès',//succès
    balance: 'balance', // balance
    rechargeTime: 'temps de recharge',// temps de recharge
    rechargeMessage: 'type de devise',// méthode de recharge
    wthdrawTime: 'temps de retrait',// temps de recharge
    wthdrawMessage: 'type de devise',// méthode de recharge
    currency: "devise",// devise
    timeAffirm: 'Confirmer',
    timeCancel: 'Annuler',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Total des ventes',
    totalPurchase: 'Montant total de l\'achat',
    totalProfit: 'Bénéfice total',
    selectionPeriod: 'filtre temporel',//sélectionner l'heure
    analysis: 'line chart',//line chart title
    showTitle: 'Développer',//Développer le graphique en courbes
    selectAll: 'sélectionner tout',//sélectionner tout
    cancel: 'fermer',//fermer
    sure: 'confirmer',//confirmer
    fundingDetails: 'données détaillées', // détails du financement
    typeSelection: 'type de sélection',//type de sélection
    purchaseAmount: 'Montant de l\'achat', //Ventes
    profitAmount: 'Montant du bénéfice',//Total des ventes
    unitAmount: 'Sales', // achat à tempérament
  grandTotal: 'cumulatif',
  timeAffirm: 'Confirmer',
    timeCancel: 'Annuler',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Liste de gestion',
    selectionPeriod: 'Sélectionner l\'heure',//Sélectionner l'heure
    totalPurchase: 'montant de l\'achat',
    totalSales: 'montant du bénéfice',
    totalProfit: 'Ventes',
    grandTotal: 'cumulatif',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Informations sur le magasin',
    picture: 'image de la boutique',
    nameTitle: 'Nom du magasin',
    Mail: 'E-mail de connexion',
    invitationCode: 'Code d\'invitation',
    loginPassword: 'mot de passe de connexion',
    paymentPassword: 'mot de passe de paiement',
    documents: 'informations sur les documents',
    frontDocument: 'document avant',
    backDocument: 'verso du document',
    exit: 'déconnexion',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Paramètres de retrait', // Adresse de paiement
    cashCurrency: 'Monnaie fiduciaire',
    virtualCurrency: 'Monnaie virtuelle',
    pleaseChoose: 'Veuillez choisir',
    popupAffirm: 'Confirmer',
    popupCancel: 'Annuler',
    hintTitle: 'Important:', // Prend uniquement en charge le retrait à < adresse.
    hintContent: 'Autorisé uniquement à importer < actifs. ', // Prend uniquement en charge le retrait à < adresse.
    affirmBtn: 'confirmer', // confirmer
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'vrai nom',
    email: 'e-mail',
    mobilePhone: 'Numéro de téléphone mobile',
    pixType: 'Type PIX',
    pixKey: 'Compte PIX',
    pleaseEnter: 'Veuillez entrer',
    pleaseSelect: 'Veuillez sélectionner',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'veuillez entrer ',
    country: 'pays/région',
    name: 'nom',
    surname: 'nom de famille',
    bsb: 'BSB',
    bankName: 'Nom de la banque',
    bankAccount: 'Compte bancaire',
    state: 'Province ou État',
    mobilePhone: 'Numéro de téléphone mobile',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'veuillez entrer ',
    country: 'pays/région',
    name: 'nom',
    surname: 'nom de famille',
    swift: 'swift',
    bankName: 'Nom de la banque',
    iban: 'iban',
    mobilePhone: 'Numéro de téléphone mobile',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'veuillez entrer ',
    country: 'pays/région',
    name: 'nom',
    surname: 'nom de famille',
    bankCode: 'code banque',
    bankName: 'Nom de la banque',
    bankAccount: 'Compte bancaire',
    mobilePhone: 'Numéro de téléphone mobile',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'veuillez entrer ',
    country: 'pays/région',
    name: 'nom',
    surname: 'nom de famille',
    zengin: 'zengin',
    bankName: 'Nom de la banque',
    bankAccount: 'Compte bancaire',
    county: 'comté',
    mobilePhone: 'Numéro de téléphone mobile',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'veuillez entrer ',
    country: 'pays/région',
    name: 'nom',
    surname: 'nom de famille',
    swift: 'swift',
    ach: 'ach',
    bankName: 'Nom de la banque',
    bankAccount: 'Compte bancaire',
    state: 'Province ou État',
    mobilePhone: 'Numéro de téléphone mobile',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'veuillez entrer ',
    name: 'nom',
    surname: 'nom de famille',
    bankName: 'Nom de la banque',
    bankAccount: 'Compte bancaire',
    mobilePhone: 'Numéro de téléphone mobile',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Veuillez saisir',
    bankName: 'Nom de la banque',
    account : 'Compte',
    clabe: 'compte clabe',
    mobilePhone: 'Numéro de téléphone mobile',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Adresse',
    pleaseInformation: 'Veuillez entrer l\'adresse de la monnaie virtuelle'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Modification du mot de passe de connexion', // mot de passe de connexion
    oldPass: 'ancien mot de passe', // ancien mot de passe
    newPass: 'nouveau mot de passe', // nouveau mot de passe
    email: 'Code de vérification',
    placeholderOldPass: 'Veuillez saisir l\'ancien mot de passe',
    placeholderNewPass: 'Veuillez saisir un nouveau mot de passe',
    placeholderEmailCode: 'Veuillez entrer le code de vérification',
    submitBtn: 'envoyer', // confirmer
    affirm: 'confirmer', // confirmer

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Modification du mot de passe de paiement', // Mot de passe de paiement
    affirmPass: 'mot de passe de paiement',
    email: 'Code de vérification', // Confirmer le mot de passe
    submitBtn: 'Envoyer',// Confirmer
    affirm: 'confirmer', // confirmer
    placeholder: 'Veuillez entrer',
    placeholderPaymentPassword: 'Veuillez entrer le mot de passe de paiement',
    placeholderEmailCode: 'Veuillez entrer le code de vérification',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Paramètres système', // Paramètre
    languageShift: 'Paramètres multilingues', // changement de langue
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Paramètres multilingues',
    affirm: 'confirmer'
}
//  公告
let afficheDetail = {
    navTitle: 'message', // notification
    comment: 'répondre',// commentaire
    unreadMessage: 'non lu',
    readTitle: 'lire'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'détails du message', // notification
    comment: 'commentaire',// commentaire
    check: 'Voir',
    packUpPlaceholder: 'Veuillez saisir le contenu de la réponse',
    sendInform: 'envoyer'
}

// 客服
let serviceLanguage = {
    navTitle: 'Service client', // Service client
    customerService: 'service client exclusif',//service client
    helloTitle: 'Bonjour',
    welcomeTitle: 'Je suis à vous <, vous pouvez me contacter des manières suivantes',
    iconInformation: 'Veuillez sélectionner un logiciel de communication pour consultation',
    whatsApp: 'whatsApp',
    telegram: 'telegram',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Gestion des commandes", // Liste des commandes
    tabsTodos: "acheté", // tous
    tabsPendente: "à acheter", // à acheter
    tabsCompleto: "En logistique", // en logistique
    tabsCancelar: "Terminé", // Terminé
    totalProfit: 'Bénéfice total',
    statementPrice: "Montant total de l'achat", // Montant total:
    affirmBtn: "Acheter", // confirmer
    orderTotal: "Total des commandes",
    orderCode: "Numéro de commande:",
    childrenDetail: 'produits', // détails
    checkDetail: 'Détails de la production', // Afficher les détails
    buyAll: 'Tout acheter',
    dialogTitle: 'Informations d\'achat',
    dialogTotalSales: 'Total des ventes',
    dialogTotalPurchase: 'Montant total de l\'achat',
    dialogTotalProfit: 'Bénéfice total',
    dialogBtn: 'Achats',
    deleteTitle: 'Commande d\'achat',
    dialogMessage: 'Etes-vous sûr d\'acheter cette commande? ',
    dialogAllMessage: 'Êtes-vous sûr d\'avoir acheté toutes les commandes? ',
    affirm: 'confirmer',
    close: 'Annuler',
    selectionPeriod: 'sélection de l\'heure',//sélectionner l'heure
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Détails de la commande", // Liste de commandes
    orderCode: "Numéro de commande:",//Numéro de commande
    totalProfitTitleCard: 'Bénéfice total',
    statementPrice: "Montant total de l'achat", // Montant total:
    amountRelated: 'Montant de la commande',
    totalSalesTitle: 'Total des ventes',
    totalPurchase: 'Bénéfice total',
    totalProfitTitle: 'Total des achats',
    btnTitle: 'Achat',
    deleteTitle: 'Commande d\'achat',
    dialogMessage: 'Confirmez-vous la modification du bon de commande? ',
    affirm: 'confirmer',
    close: 'Annuler',
    salesTitle: 'Montant des ventes',
    discount: 'ratio de ventes',
    salesAmount: 'Montant réel des ventes',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "commander les détails du produit", // liste de commandes
    orderCode: "Numéro de commande:",//Numéro de commande
    productInformation: "Informations sur le produit", // Informations sur le produit
    totalProfitTitleCard: 'Bénéfice total',
    statementPrice: "Montant total de l'achat:", // Montant total:
    unitPrice: "prix unitaire", // prix unitaire
    salesVolume: "Prix de vente total", // Volume des ventes
    purchasePrices: "Prix unitaire d'achat", // Prix d'achat
    purchasePricesTotal: "Prix d'achat total", // prix d'achat
    profitPerPiece: "prix unitaire du profit", // profit
    profitPerPieceTotal: "Prix total du profit", // Profit
    orderCardTitle: 'Paiement',
    paymentMethods: "Mode de paiement", // Génération de la durée de vie
    paymentTime: "délai de paiement", // délai de paiement
    returnedMoneyTitle: 'remboursement de l\'argent',
    returnedMoneyMethods: 'Si l\'argent est retourné',
    returnedMoneyMethods1: 'Non remboursé',
    returnedMoneyMethods2: 'L\'argent a été restitué',
    returnedMoneyTime: 'Heure de retour',
    logisticsInformation: 'Informations logistiques',
    logisticsInformationTitle: 'Cliquez pour voir les dernières actualités logistiques',
    trackingNumber: 'Numéro de logistique',
    orderNumber: 'Numéro de commande',
    logisticsCardTitle: 'Informations logistiques'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Veuillez entrer les conditions de la requête',
}
// 脚部语言
let footerLanguage = {
    home: 'Maison',
    order: 'commande',
    Workbench: 'Établi',
}
let chatPageMessage = {
    ladingText: 'télécharger...',
    loosingText: 'publier une mise à jour...',
    pullingText: 'mise à jour déroulante...',
    send: 'envoyer',
    messageText: 'Message',
    connecting: 'Connexion au service de messagerie instantanée...',
    connectionSuccess: 'La connexion au service de messagerie instantanée est réussie',
    connectionFail: 'La connexion au service de messagerie instantanée a échoué',
    connectionSendFail: 'Le service de messagerie instantanée n\'est pas connecté, l\'envoi a échoué',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Invitez des amis pour gagner des récompenses',
    qrCodeLanguage: 'Copier',
    ruleTitle: 'Règles d\'invitation',
    rule1: 'L\'invitant peut inviter des amis à terminer l\'inscription en partageant le lien de promotion, et les amis invités seront associés à vous;',
    rule2: 'Après avoir invité les utilisateurs à effectuer le paiement, vous pouvez obtenir une part de leurs revenus;',
    rule3: 'Ne prend en charge que les récompenses d\'invitation à trois niveaux, le ratio de récompense est: <%, >%, @%;',
    rule4: 'La méthode d\'invitation doit être légale et conforme aux règles d\'invitation d\'amis. S\'il existe une méthode d\'invitation inappropriée ou un comportement qui enfreint les règles d\'invitation d\'amis, une fois vérifié, toutes les récompenses non émises seront déduites. La plate-forme se réserve le droit de retracer toutes les récompenses émises et de poursuivre la responsabilité légale, le cas échéant. ',
    situation: 'Le dernier enregistrement d\'invitation',
    copySuccess: 'Copie réussie',

}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Inviter un enregistrement d\'utilisateur',
    cardTitle: 'Nombre total d\'utilisateurs',
    firstLevelUser: 'niveau un',
    secondaryUser: 'secondaire',
    tertiaryUser: 'Tertiaire',

    tabs1Title: 'Tous',
    tabs2Title: 'niveau un',
    tabs3Title: 'Secondaire',
    tabs4Title: 'Troisième niveau',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Enregistrement des revenus d\'invitation',
    cardTitle: "Revenu total",
    placeholder: 'Veuillez entrer',
    firstLevelUser: 'niveau un',
    secondaryUser: 'secondaire',
    tertiaryUser: 'Tertiaire',
    store: 'montant total',
    ratio: 'rapport',
    commission: 'commissions',

    tabs1Title: 'Tous',
    tabs2Title: 'niveau un',
    tabs3Title: 'Secondaire',
    tabs4Title: 'Troisième niveau',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Gestion des ventes',
    navContent: 'Classement des ventes de produits de la commande',
    tabs1: 'Ventes',
    tabs2: 'Montant total',
    tabs3: 'Montant du bénéfice',
    searchPlaceholder: 'Nom du produit',
    date1Placeholder: 'Heure de début',
    date2Placeholder: 'Heure de fin',
    salesVolume: 'ventes',
    totalPrice: 'total des ventes',
    shelfTime: 'Bénéfice total',
    ranking: 'NON',
    timeAffirm: 'Confirmer',
    timeCancel: 'Annuler'
};
let rankingItemLanguage = {
    navTitle: 'Enregistrement des ventes de produits',
    date1Placeholder: 'Heure de début',
    date2Placeholder: 'Heure de fin',
    tabs1: 'Ventes',
    tabs2: 'heure',
    salesTime: 'heure',
    salesVolume: 'ventes',
    totalAmount: 'total des ventes',
    timeAffirm: 'Confirmer',
    timeCancel: 'Annuler'

};
// 个人中心新增
myCenterLanguage.invitation = 'inviter'
myCenterLanguage.commission = 'commission'

// 订单页面新增
orderPageLangage.orderBgTitle = 'Classement des ventes, je sais'
orderPageLangage.orderBgLeaveFor = 'aller à'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
