// 西班牙语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Actualización de versión, ¿confirmar? ', // Actualización de versión, por favor confirme?
    loadingTitle: 'Descargando...', // Descargando...
    systemUpgrade: 'actualización del sistema', // actualización del sistema
    upgradeImmediately: 'Confirmar', // Confirmar
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'nombre de la tienda',
    pass: 'contraseña',
    placeholderPass: 'Por favor ingrese su contraseña',
    placeholderShopName: "Por favor ingrese el nombre de la tienda",
    mail: "Buzón",
    placeholderMail: "Por favor ingrese su correo electrónico",
    emailCode: "Código de verificación de correo electrónico",
    placeholderEmailCode: "Por favor ingrese el código de verificación",
    send: "enviar",
    InvitationCode: 'Código de invitación',
    PlaceholderInvitationCode: 'Ingrese el código de invitación',
    documents: 'Subir documentos',
    front: 'frente del documento',
    reverseSide: 'frente del documento',
    placeholderFront: 'Cargue el anverso del certificado',
    placeholderReverseSide: 'Cargue el reverso del documento',
    btn: 'Aplicar',
    applicationBtn: 'Confirmar',
    applicationNav: 'Acuerdo de aplicación de tienda',
    dialogTitle: 'Revisar el éxito',
    dialogMessage: 'La solicitud es exitosa, espere pacientemente a que el personal la revise!',
    close: 'Confirmar',
}
// 登录
let loginLanguage = {
    navTitle: 'iniciar sesión', // iniciar sesión
    warningTitle: 'Por favor ingrese su correo electrónico de inicio de sesión',
    passTitle: 'Ingrese la contraseña de inicio de sesión', // contraseña
    forgetPassword: '¿Olvidaste tu contraseña? ',//Se te olvidó tu contraseña
    btnTitle: 'iniciar sesión', // iniciar sesión
    hintTitle: '¿No tienes cuenta? ir a registrarse', // registrarse
    linkTitle: 'servicio al cliente', // servicio al cliente
    AppDownload: 'Descargar aplicación', //Descargar aplicación
    goToShop: 'Al centro comercial',
    register: 'Registro'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Olvidé mi contraseña',
    email: 'correo electrónico de inicio de sesión',
    placeholderEmail: 'Ingrese su correo electrónico de inicio de sesión',
    emailCode: 'Código de verificación',
    placeholderEmailCode: 'Ingrese el código de verificación',
    newPass: 'Nueva contraseña',
    placeholderNewPass: "Por favor ingrese una nueva contraseña",
    affirmPass: 'Confirmar contraseña',
    placeholderAffirmPass: 'Ingrese la contraseña dos veces',
    submitBtn: 'enviar',
    affirm: 'confirmar',
}

// 首页
let homeLanguage = {
    navTitle: 'Ventas de hoy', // Inicio
    orderTitle: 'Número de pedidos hoy', // Pedido
    balanceTitle: 'saldo',//saldo
    flowTitle: 'Recuento de tráfico de hoy', //Tráfico de hoy
    navTitleLine: 'Ventas', // Inicio
    orderTitleLine: 'Número de pedidos',//Pedido
    flowTitleLine: 'Número de flujo', //Flujo de hoy
    rechargeTitle: 'Recargar', // recargar
    bankTitle: 'Retirar', // Retirar
    analysis: 'Resumen de los últimos 30 días',//Título del gráfico de líneas
    showTitle: 'Expandir',//Expandir el gráfico de líneas
    selectAll: 'seleccionar todo',//seleccionar todo
    cancel: 'cerrar',//cerrar
    sure: 'confirmar',//confirmar
    revenueLanguage: 'impuesto',
    revenuepopupTitle: 'Impuesto',
    revenuepopupWarn: 'Para recargar y pagar impuestos',
    revenuepopupNeed: 'El impuesto vence actualmente',
    revenuepopupSurn: 'confirmar',
    outstandingAmount: 'monto pendiente',
    outstandingOrders: 'órdenes pendientes',
    totalSalesYesterday: 'Total de ventas ayer',
    yesterdaySalesOrders: 'Órdenes de Venta de Ayer',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Recargar', // Recargar
    currencyType1: 'Moneda fiduciaria',
    currencyType2: 'Moneda virtual',
    pleaseChoose: "Por favor elige",
    cancelBtn: 'Cancelar',
    submitBtn: 'confirmar', // confirmar
    placeholderNav: 'Ingrese el monto de la recarga', // saldo actual
    placeholderCurrent: 'Ingrese el monto de la recarga', // ingrese el monto
    dialogMessage: 'Por favor seleccione el tipo de moneda. ',
    pathTitle: 'método de recarga', // canal
    hintTitle: 'Importante:',
    hintContent: 'Solo se permite importar <activos',
    success: 'Copiar con exito! ',
    hintPopup: '¡¡ por favor, vaya a ponerse en contacto con el personal de servicio al cliente!',
    hintTile: 'Información de recordatorio'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Retirar', // Retirar
    currencyType1: 'Moneda fiduciaria',
    currencyType2: 'Moneda virtual',
    pleaseChoose: "Por favor elige",
    cancelBtn: 'Cancelar',
    submitBtn: 'confirmar', // confirmar
    placeholderNav: 'Ingrese el monto del retiro',
    placeholderCurrent: 'Ingrese el monto del retiro', // ingrese el monto
    currentBalance: 'Saldo Actual', // Saldo Actual
    passwordTips: 'Ingrese la contraseña de retiro', // ingrese el monto
    pathTitle: 'tasa de cambio', // canal
    rateTips: 'Tasa de conversión actual',
    dialogMessage: 'Por favor seleccione el tipo de moneda. ',
    hintTitle: 'Importante:',
    hintContent: 'Solo se permite importar <activos',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'error', // error
    loadingTitle: 'Cargando', // Cargando
    finishedText: "ninguno", //ninguno
    loadingText: 'cargando', // cargando
    wait: 'Por favor, espere un momento',
    placeholderHite: 'El valor de entrada es incorrecto',
    uploadError: 'Carga fallida',
    upload: 'Subiendo',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'centro personal', // centro personal
    bankCard: 'Configuración de retiro', // Dirección de retiro
    productLibrary: 'gestión de productos', //biblioteca de productos
    financialManagement: 'Registro de fondos', //administración financiera
    reportStatistics: 'Informe comercial', //Estadísticas
    accountInformation: 'Almacenar información', //Cambiar idioma
    setting: 'Configuración del sistema',
    goToShop: 'Al centro comercial',
    goToLoan: 'préstamo',
    downloadApp: 'Descargar aplicación',
    activity: 'Actividad'
}
// 活动页面
let activityLanguage = {
    navTitle: 'Centro de actividades',
    discount: 'descuento'
}
let discountLanguage = {
    navTitle: 'descuento',
    affirmBtn: 'Agregar',
    submitBtn: 'enviar',
    accain: 'lleno',
    amount: 'la cantidad',
    ratio: 'Descuento',
    plusAffirm: 'afirmar',
    placeholderEnter: 'por favor ingrese'
}

// 下载
let downloadApp = {
    navTitle: 'Descargar aplicación',
    downloadAndroidApp: 'descargar aplicación de Android',
    downloadIosApp: 'descargar aplicación ios',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Biblioteca de productos', // Biblioteca de productos
    productList: 'Producto de la tienda', // Lista de productos
    productLibraryTitle: 'Producto de plataforma', // biblioteca de productos
    purchasePrice: 'Precio de compra',
    sellingPrice: 'Precio de venta',
    selectAll: 'seleccionar todo',
    affirm: 'confirmar',
    close: 'Cancelar',
    editPrice: 'Modificar precio',
    deleteTitle: 'Eliminar pedido',
    dialogMessage: '¿Está seguro de que desea eliminar este producto? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Registros de fondos',
    tabs1Title: 'Todos los fondos', // Liquidez
    tabs2Title: 'registro de recarga', // registro de recarga
    tabs3Title: 'registro de retiro',//registro de retiro
    selectionPeriod: 'selección de tiempo',//seleccionar tiempo
    rechargeTitle: 'recargar',// recargar
    wthdrawTitle: 'Retiro', // Retiro
    successful: 'éxito',//éxito
    balance: 'saldo', // saldo
    rechargeTime: 'tiempo de recarga',// tiempo de recarga
    rechargeMessage: 'tipo de moneda', // método de recarga
    wthdrawTime: 'tiempo de retiro', // tiempo de recarga
    wthdrawMessage: 'tipo de moneda', // método de recarga
    currency: "moneda",// moneda
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Ventas Totales',
    totalPurchase: 'Cantidad total de la compra',
    totalProfit: 'Beneficio total',
    selectionPeriod: 'filtro de tiempo',//seleccionar tiempo
    analysis: 'gráfico de líneas',//título del gráfico de líneas
    showTitle: 'Expandir',//Expandir el gráfico de líneas
    selectAll: 'seleccionar todo',//seleccionar todo
    cancel: 'cerrar',//cerrar
    sure: 'confirmar',//confirmar
    fundingDetails: 'datos detallados', // detalles de financiacion
    typeSelection: 'tipo de selección',//tipo de selección
    purchaseAmount: 'Cantidad de compra', // Ventas
    profitAmount: 'Cantidad de ganancias', // Ventas totales
    unitAmount: 'Ventas', // compra a plazos
    grandTotal: 'acumulativo',
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Lista de gestión',
    selectionPeriod: 'Seleccionar hora',//Seleccionar hora
    totalPurchase: 'monto de compra',
    totalSales: 'cantidad de ganancia',
    totalProfit: 'Ventas',
    grandTotal: 'acumulativo',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Información de la tienda',
    picture: 'imagen de la tienda',
    nameTitle: 'Nombre de la tienda',
    Mail: 'Correo electrónico de inicio de sesión',
    invitationCode: 'Código de invitación',
    loginPassword: 'contraseña de inicio de sesión',
    paymentPassword: 'contraseña de pago',
    documents: 'información del documento',
    frontDocument: 'frente del documento',
    backDocument: 'reverso del documento',
    exit: 'cerrar sesión',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Configuración de retiro', // Dirección de pago
    cashCurrency: 'Moneda fiduciaria',
    virtualCurrency: 'moneda virtual',
    pleaseChoose: 'Por favor elija',
    popupAffirm: 'Confirmar',
    popupCancel: 'Cancelar',
    hintTitle: 'Importante:', // Solo admite el retiro a <dirección.
    hintContent: 'Solo se permite importar < activos. ', // Solo admite retiros a <dirección.
    affirmBtn: 'confirmar', // confirmar
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'nombre real',
    email: 'correo electrónico',
    mobilePhone: 'Número de teléfono móvil',
    pixType: 'tipo de FOTO',
    pixKey: 'Cuenta PIX',
    pleaseEnter: 'Por favor ingrese',
    pleaseSelect: 'Por favor seleccione',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'por favor ingrese ',
    country: 'país/región',
    name: 'nombre',
    surname: 'apellido',
    bsb: 'BSB',
    bankName: 'Nombre del banco',
    bankAccount: 'Cuenta Bancaria',
    state: 'Provincia o Estado',
    mobilePhone: 'Número de teléfono móvil',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'por favor ingrese ',
    country: 'país/región',
    name: 'nombre',
    surname: 'apellido',
    swift: 'swift',
    bankName: 'Nombre del banco',
    iban: 'iba',
    mobilePhone: 'Número de teléfono móvil',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'por favor ingrese ',
    country: 'país/región',
    name: 'nombre',
    surname: 'apellido',
    bankCode: 'código bancario',
    bankName: 'Nombre del banco',
    bankAccount: 'Cuenta Bancaria',
    mobilePhone: 'Número de teléfono móvil',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'por favor ingrese ',
    country: 'país/región',
    name: 'nombre',
    surname: 'apellido',
    zengin: 'zengin',
    bankName: 'Nombre del banco',
    bankAccount: 'Cuenta Bancaria',
    county: 'condado',
    mobilePhone: 'Número de teléfono móvil',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'por favor ingrese ',
    country: 'país/región',
    name: 'nombre',
    surname: 'apellido',
    swift: 'swift',
    ach: 'ach',
    bankName: 'Nombre del banco',
    bankAccount: 'Cuenta Bancaria',
    state: 'Provincia o Estado',
    mobilePhone: 'Número de teléfono móvil',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'por favor ingrese ',
    name: 'nombre',
    surname: 'apellido',
    bankName: 'Nombre del banco',
    bankAccount: 'Cuenta Bancaria',
    mobilePhone: 'Número de teléfono móvil',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Por favor ingrese',
    bankName: 'Nombre del banco',
    account: 'cuenta',
    clabe: 'cuenta clabe',
    mobilePhone: 'Número de teléfono móvil',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Dirección',
    pleaseInformation: 'Ingrese la dirección de la moneda virtual'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Modificación de contraseña de inicio de sesión', // contraseña de inicio de sesión
    oldPass: 'contraseña antigua', // contraseña antigua
    newPass: 'nueva contraseña', // nueva contraseña
    email: 'Código de verificación',
    placeholderOldPass: 'Ingrese la contraseña anterior',
    placeholderNewPass: 'Ingrese una nueva contraseña',
    placeholderEmailCode: 'Ingrese el código de verificación',
    submitBtn: 'enviar', // confirmar
    affirm: 'confirmar', // confirmar

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Modificación de contraseña de pago', // Contraseña de pago
    affirmPass: 'contraseña de pago',
    email: 'Código de verificación', // Confirmar contraseña
    submitBtn: 'Enviar', // Confirmar
    affirm: 'confirmar', // confirmar
    placeholder: 'Por favor ingrese',
    placeholderPaymentPassword: 'Ingrese la contraseña de pago',
    placeholderEmailCode: 'Ingrese el código de verificación',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Configuración del sistema', // Configuración
    languageShift: 'Configuración multilingüe', // cambio de idioma
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Configuración multilingüe',
    affirm: 'confirmar'
}
//  公告
let afficheDetail = {
    navTitle: 'mensaje', // notificación
    comment: 'responder',// comentar
    unreadMessage: 'no leído',
    readTitle: 'leer'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'detalles del mensaje', // notificación
    comment: 'comentario',// comentario
    check: 'Ver',
    packUpPlaceholder: 'Ingrese el contenido de la respuesta',
    sendInform: 'enviar'
}

// 客服
let serviceLanguage = {
    navTitle: 'Servicio al Cliente', // Servicio al Cliente
    customerService: 'servicio al cliente exclusivo', // servicio al cliente
    helloTitle: 'Hola',
    welcomeTitle: 'Soy tuyo <, puedes contactarme de las siguientes formas',
    iconInformation: 'Seleccione un software de comunicación para consultar',
    whatsApp: 'WhatsApp',
    telegram: 'telegrama',
    line: 'línea',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Gestión de pedidos", // Lista de pedidos
    tabsTodos: "comprado", // todo
    tabsPendente: "a comprar", // a comprar
    tabsCompleto: "En logística", // en logística
    tabsCancelar: "Completado", // Completado
    totalProfit: 'Beneficio Total',
    statementPrice: "Importe total de la compra", // Importe total:
    affirmBtn: "Comprar", // confirmar
    orderTotal: "Total de pedidos",
    orderCode: "Número de pedido:",
    childrenDetail: 'productos', // detalles
    checkDetail: 'Detalles de producción', // Ver detalles
    buyAll: 'Comprar todo',
    dialogTitle: 'Información de compra',
    dialogTotalSales: 'Ventas Totales',
    dialogTotalPurchase: 'Importe total de la compra',
    dialogTotalProfit: 'Beneficio total',
    dialogBtn: 'Compras',
    deleteTitle: 'Orden de compra',
    dialogMessage: '¿Está seguro de comprar este pedido? ',
    dialogAllMessage: '¿Está seguro de comprar todos los pedidos? ',
    affirm: 'confirmar',
    close: 'Cancelar',
    selectionPeriod: 'selección de tiempo',//seleccionar tiempo
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Detalles del pedido", // Lista de pedidos
    orderCode: "Número de pedido:",//Número de pedido
    totalProfitTitleCard: 'Beneficio total',
    statementPrice: "Importe total de la compra", // Importe total:
    amountRelated: 'cantidad del pedido',
    totalSalesTitle: 'Ventas totales',
    totalPurchase: 'Beneficio total',
    totalProfitTitle: 'Total de compras',
    btnTitle: 'Comprar',
    deleteTitle: 'Orden de compra',
    dialogMessage: '¿Confirma el cambio de orden de compra? ',
    affirm: 'confirmar',
    close: 'Cancelar',
    salesTitle: 'Cantidad de ventas',
    discount: 'tasa de ventas',
    salesAmount: 'Cantidad de ventas real',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "detalles del producto del pedido", // lista de pedidos
    orderCode: "Número de pedido:",//Número de pedido
    productInformation: "Información del producto", // Información del producto
    totalProfitTitleCard: 'Beneficio total',
    statementPrice: "Importe total de la compra:", // Importe total:
    unitPrice: "precio unitario", // precio unitario
    salesVolume: "Precio de ventas total", // Volumen de ventas
    purchasePrices: "Precio unitario de compra", // Precio de compra
    purchasePricesTotal: "Precio de compra total", // precio de compra
    profitPerPiece: "precio unitario de beneficio", // beneficio
    profitPerPieceTotal: "Precio de beneficio total", // Beneficio
    orderCardTitle: 'Pago',
    paymentMethods: "Método de pago", // Generar de por vida
    paymentTime: "tiempo de pago", // tiempo de pago
    returnedMoneyTitle: 'dinero devuelto',
    returnedMoneyMethods: 'Si se devuelve el dinero',
    returnedMoneyMethods1: 'No devuelto',
    returnedMoneyMethods2: 'El dinero ha sido devuelto',
    returnedMoneyTime: 'Hora de regreso',
    logisticsInformation: 'Información logística',
    logisticsInformationTitle: 'Haga clic para ver las últimas noticias de logística',
    trackingNumber: 'Número de logística',
    orderNumber: 'Número de pedido',
    logisticsCardTitle: 'Información logística'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Por favor ingrese las condiciones de la consulta',
}
// 脚部语言
let footerLanguage = {
    home: 'Hogar',
    order: 'ordenar',
    Workbench: 'trabajar',
}
let chatPageMessage = {
    ladingText: 'descargar . ...',
    loosingText: 'publicar actualizaciones...',
    pullingText: 'actualización desplegable...',
    send: 'send',
    messageText: 'Noticias',
    connecting: 'Servicio de mensajería instantánea conectando...',
    connectionSuccess: 'La conexión del servicio IM es exitosa',
    connectionFail: 'Error en la conexión del servicio IM',
    connectionSendFail: 'El servicio de mensajería instantánea no está conectado, el envío falló',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Invita a amigos para ganar recompensas',
    qrCodeLanguage: 'Copiar',
    ruleTitle: 'Reglas de invitación',
    rule1: 'La persona que invita puede invitar a amigos a completar el registro compartiendo el enlace de la promoción, y los amigos invitados se asociarán contigo;',
    rule2: 'Después de invitar a los usuarios a completar el pago, puede obtener una parte de sus ingresos;',
    rule3: 'Solo admite recompensas de invitación de tres niveles, la relación de recompensa es: <%, >%, @%;',
    rule4: 'El método de invitación debe ser legal y estar en línea con las reglas para invitar amigos. Si hay algún método de invitación inadecuado o comportamiento que viole las reglas para invitar amigos, una vez que se verifique, se deducirán todas las recompensas no emitidas. La plataforma se reserva el derecho de rastrear todas las recompensas emitidas y perseguir la responsabilidad legal según sea el caso. ',
    situation: 'El último registro de invitación',
    copySuccess: 'Copia exitosa',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Invitar registro de usuario',
    cardTitle: 'Número total de usuarios',
    firstLevelUser: 'nivel uno',
    secondaryUser: 'secundario',
    tertiaryUser: 'Terciario',

    tabs1Title: 'Todos',
    tabs2Title: 'nivel uno',
    tabs3Title: 'Secundario',
    tabs4Title: 'Tercer nivel',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Registro de ingresos por invitación',
    cardTitle: 'Ingresos totales',
    placeholder: 'Por favor ingrese',
    firstLevelUser: 'nivel uno',
    secondaryUser: 'secundario',
    tertiaryUser: 'Terciario',
    store: 'cantidad total',
    ratio: 'relación',
    commission: 'comisiones',

    tabs1Title: 'Todos',
    tabs2Title: 'nivel uno',
    tabs3Title: 'Secundario',
    tabs4Title: 'Tercer nivel',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Gestión de ventas',
    navContent: 'Ordenar ranking de ventas de productos',
    tabs1: 'Ventas',
    tabs2: 'Cantidad total',
    tabs3: 'Cantidad de beneficio',
    searchPlaceholder: 'Nombre del producto',
    date1Placeholder: 'Hora de inicio',
    date2Placeholder: 'Hora de finalización',
    salesVolume: 'ventas',
    totalPrice: 'ventas totales',
    shelfTime: 'Beneficio total',
    ranking: 'NO',
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar'
};
let rankingItemLanguage = {
    navTitle: 'Registro de ventas de productos',
    date1Placeholder: 'Hora de inicio',
    date2Placeholder: 'Hora de finalización',
    tabs1: 'Ventas',
    tabs2: 'tiempo',
    salesTime: 'tiempo',
    salesVolume: 'ventas',
    totalAmount: 'ventas totales',
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar'

};
// 个人中心新增
myCenterLanguage.invitation = 'invitar'
myCenterLanguage.commission = 'comisión'

// 订单页面新增
orderPageLangage.orderBgTitle = 'Rango de ventas, lo sé'
orderPageLangage.orderBgLeaveFor = 'ir a'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
