// 芬兰语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Version päivitys, vahvista? ',// Version päivitys, vahvistatko?
    loadingTitle: 'Ladataan...', // Ladataan...
    systemUpgrade: 'järjestelmän päivitys', // järjestelmän päivitys
    upgradeImmediately: 'Vahvista', // Vahvista
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'shop name',
    pass: 'salasana',
    placeholderPass: "Anna salasanasi",
    placeholderShopName: "Anna kaupan nimi",
    mail: "Mailbox",
    placeholderMail: "Anna sähköpostiosoitteesi",
    emailCode: "Sähköpostin vahvistuskoodi",
    placeholderEmailCode: "Anna vahvistuskoodi",
    send: "Lähetä",
    InvitationCode: 'Kutsukoodi',
    PlaceholderInvitationCode: "Syötä kutsukoodi",
    documents: 'Lataa asiakirjat',
    front: "asiakirjan etupuoli",
    reverseSide: "asiakirjan etupuoli",
    placeholderFront: 'Lähetä varmenteen etupuoli',
    placeholderReverseSide: 'Lähetä asiakirjan kääntöpuoli',
    btn: 'Apply',
    applicationBtn: 'Vahvista',
    applicationNav: 'Store Application Agreement',
    dialogTitle:'Tarkista menestys',
    dialogMessage:'Hakemus on onnistunut, odota kärsivällisesti, että henkilökunta tarkistaa!',
    close:'Vahvista',
}
// 登录
let loginLanguage = {
    navTitle: 'kirjautuminen', // kirjaudu sisään
    warningTitle: "Anna kirjautumissähköpostiosoitteesi",
    passTitle: 'Anna kirjautumissalasana', // salasana
    forgetPassword: 'Unohditko salasanasi? ',//Unohtuiko salasana
    btnTitle: 'kirjautuminen', // kirjautuminen
    hintTitle: "Ei tiliä? mene rekisteröitymään", // rekisteröidy
    linkTitle: 'asiakaspalvelu', // asiakaspalvelu
    AppDownload: 'Lataa sovellus', //Lataa sovellus
    goToShop: 'ostoskeskukseen',
    register:'register'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: "Unohdin salasana",
    email: 'kirjautumissähköposti',
    placeholderEmail: "Anna kirjautumissähköpostiosoitteesi",
    emailCode: 'Vahvistuskoodi',
    placeholderEmailCode: "Anna vahvistuskoodi",
    newPass: "Uusi salasana",
    placeholderNewPass: "Anna uusi salasana",
    affirmPass: 'Vahvista salasana',
    placeholderAffirmPass: "Syötä salasana kahdesti",
    submitBtn: 'send',
    affirm: 'vahvista',
}

// 首页
let homeLanguage = {
    navTitle: "Tänään myynti", // Etusivu
    orderTitle: 'Tilausten lukumäärä tänään',//Tilaus
    balanceTitle:'tasapaino',//saldo
    flowTitle: "Tämän päivän liikennemäärä", //Tämän päivän liikenne
    navTitleLine: 'Sales', // Etusivu
    orderTitleLine: 'Tilausten määrä',//Tilaus
    flowTitleLine: 'Virtausnumero', //Tämän päivän kulku
    rechargeTitle: 'Recharge', // lataa
    bankTitle: 'Nosta', // Nosta
    analysis: "Viimeisten 30 päivän yleiskatsaus",//Viivakaavion otsikko
    showTitle: 'Laajenna',//Laajenna viivakaaviota
    selectAll: 'select all',//select all
    cancel: 'sulje',//sulje
    sure: 'confirm',//confirm
    revenueLanguage: 'tax',
    revenuepopupTitle: "Tax Reminder",
    revenuepopupWarn: "Lataa ja maksaa veroja",
    revenuepopupNeed: "Vero erääntyy tällä hetkellä",
    revenuepopupSurn:'confirm',
    outstandingAmount: 'erääntynyt summa',
    outstandingOrders: 'lopputilaukset',
    totalSalesYesterday : 'Kokonaismyynti eilen',
    yesterdaySalesOrders: "Eiliset myyntitilaukset",
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Recharge', // Lataa
    currencyType1: 'Fiatin valuutta',
    currencyType2: "Virtuaalivaluutta",
    pleaseChoose: "Ole hyvä ja valitse",
    cancelBtn: 'Peruuta',
    submitBtn: 'confirm', // vahvista
    placeholderNav: 'Syötä lataussumma', // nykyinen saldo
    placeholderCurrent: 'Syötä lataussumma', // kirjoita summa
    dialogMessage: 'Valitse valuuttatyyppi. ',
    pathTitle: 'latausmenetelmä',// kanava
    hintTitle: 'Tärkeää:',
    hintContent: "Sallii tuoda vain <resursseja",
    success: 'Kopioi onnistuneesti! ',
    hintPopup: 'Ole hyvä ja ota yhteyttä asiakaspalveluun!',
    hintTile: "Nopeat tiedot"
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Poista', // Poista
    currencyType1: 'Fiatin valuutta',
    currencyType2: "Virtuaalivaluutta",
    pleaseChoose: "Ole hyvä ja valitse",
    cancelBtn: 'Peruuta',
    submitBtn: 'confirm', // vahvista
    placeholderNav: "Syötä nostosumma",
    placeholderCurrent: 'Syötä nostosumma', // kirjoita summa
    currentBalance: 'Current Balance', // Current Balance
    passwordTips: "Syötä noston salasana", // syötä summa
    pathTitle: 'vaihtokurssi', // kanava
    rateTips: "Nykyinen tulosprosentti",
    dialogMessage: 'Valitse valuuttatyyppi. ',
    hintTitle: 'Tärkeää:',
    hintContent: "Sallii tuoda vain <resursseja",
}

// 提示文本
let messageLanguage = {
    warningTitle: 'error', // error
    loadingTitle: 'Ladataan', // Ladataan
    finishedText: "ei mitään", //ei mitään
    loadingText: 'lataus', // lataus
    wait: 'Odota hetki',
    placeholderHite: 'Syötearvo on virheellinen',
    uploadError: 'Lataus epäonnistui',
    upload: "Lataa",
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'henkilökohtainen keskus', // henkilökohtainen keskus
    bankCard: 'Nostoasetukset', // Kotiutusosoite
    productLibrary: 'tuotehallinta', //tuotekirjasto
    financialManagement: 'Fund record', //taloushallinta
    reportStatistics: 'Business report', //Tilastot
    accountInformation: 'Säilytystiedot', //Vaihda kieltä
    setting: "Järjestelmäasetukset",
    goToShop: 'ostoskeskukseen',
    goToLoan: "laina",
    downloadApp:'Lataa sovellus',
    activity: "Activity"
}
// 活动页面
let activityLanguage = {
    navTitle: 'toimintakeskus',
    discount: "alennus"
}
let discountLanguage = {
    navTitle: 'alennus',
    affirmBtn: 'Lisää',
    submitBtn: 'submit',
    accain: 'Täysi',
    amount: "summa",
    ratio: "alennus",
    plusAffirm: 'vahvista',
    placeholderEnter: 'ole hyvä ja syötä'
}

// 下载
let downloadApp = {
    navTitle: 'Lataa sovellus',
    downloadAndroidApp: "lataa Android-sovellus",
    downloadIosApp:'lataa ios-sovellus',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Tuotekirjasto', // Tuotekirjasto
    productList: 'Store Product', // Tuoteluettelo
    productLibraryTitle: 'Alustatuote', // tuotekirjasto
    purchasePrice: "Ostohinta",
    sellingPrice: "Myyntihinta",
    selectAll: 'valitse kaikki',
    affirm: 'vahvista',
    close: 'Peruuta',
    editPrice: 'Muokkaa hintaa',
    deleteTitle: 'Poista tilaus',
    dialogMessage: 'Haluatko varmasti poistaa tämän tuotteen? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Rahastot',
    tabs1Title: 'Kaikki rahastot', // Likviditeetti
    tabs2Title: 'recharge record', // lataa tietue
    tabs3Title: 'poistotietue',//poistotietue
    selectionPeriod: 'ajan valinta',//valitse aika
    rechargeTitle: 'lataa',// lataa
    wthdrawTitle: 'Peruuttaminen',// Poista
    successful: 'success',//success
    balance: 'tasapaino', // tasapaino
    rechargeTime: 'latausaika',// latausaika
    rechargeMessage: 'valuuttatyyppi',// lataustapa
    wthdrawTime: 'nostoaika',// latausaika
    wthdrawMessage: 'valuuttatyyppi',// lataustapa
    currency: "valuutta",// valuutta
    timeAffirm: 'Vahvista',
    timeCancel: 'Peruuta',
}
// 经营报表
let statisticsLanguage = {
    totalSales: "Total Sales",
    totalPurchase: 'Oston kokonaissumma',
    totalProfit: 'Kokonaisvoitto',
    selectionPeriod: 'aikasuodatin',//valitse aika
    analysis: 'viivakaavio',//viivakaavion otsikko
    showTitle: 'Laajenna',//Laajenna viivakaaviota
    selectAll: 'select all',//select all
    cancel: 'sulje',//sulje
    sure: 'confirm',//confirm
    fundingDetails: 'yksityiskohtaiset tiedot', // rahoituksen tiedot
    typeSelection: 'valintatyyppi',//valintatyyppi
    purchaseAmount: 'Ostosumma', //Myynti
    profitAmount: 'Profit Amount',//Kokonaismyynti
    unitAmount: 'Sales', // osamaksu
    grandTotal: 'kumulatiivinen',
    timeAffirm: 'Vahvista',
    timeCancel: 'Peruuta',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Hallintaluettelo',
    selectionPeriod: 'Valitse aika',//Valitse aika
    totalPurchase: 'ostosumma',
    totalSales: 'voiton määrä',
    totalProfit: "Myynti",
    grandTotal: 'kumulatiivinen',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Kaupan tiedot',
    picture: 'myymäläkuva',
    nameTitle: 'Kaupan nimi',
    Mail: 'Kirjautumissähköposti',
    invitationCode: 'Kutsukoodi',
    loginPassword: 'kirjautumissalasana',
    paymentPassword: 'maksun salasana',
    documents: "asiakirjatiedot",
    frontDocument: 'asiakirjan etupuoli',
    backDocument: 'asiakirjan takaosa',
    exit: 'kirjaudu ulos',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Nostoasetukset', // Maksuosoite
    cashCurrency: 'Fiatin valuutta',
    virtualCurrency: "Virtual Currency",
    pleaseChoose: 'Ole hyvä ja valitse',
    popupAffirm: 'Vahvista',
    popupCancel: 'Peruuta',
    hintTitle: 'Tärkeää:', // Tukee vain nostoa < osoitteeseen.
    hintContent: 'Sallii tuoda vain < resursseja. ', // Tukee vain nostoa < osoitteeseen.
    affirmBtn: 'vahvista', // vahvista
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'oikea nimi',
    email: 'sähköposti',
    mobilePhone: 'matkapuhelinnumero',
    pixType: 'PIX-tyyppi',
    pixKey: 'PIX-tili',
    pleaseEnter: 'Syötä',
    pleaseSelect: 'Ole hyvä ja valitse',

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'ole hyvä ja syötä',
    country: 'maa/alue',
    name: 'nimi',
    surname: 'sukunimi',
    bsb: 'BSB',
    bankName: 'Pankin nimi',
    bankAccount: 'Pankkitili',
    state: "Provinssi tai osavaltio",
    mobilePhone: 'matkapuhelinnumero',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'ole hyvä ja syötä',
    country: 'maa/alue',
    name: 'nimi',
    surname: 'sukunimi',
    swift: 'swift',
    bankName: 'Pankin nimi',
    iban: "iban",
    mobilePhone: 'matkapuhelinnumero',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'ole hyvä ja syötä',
    country: 'maa/alue',
    name: 'nimi',
    surname: 'sukunimi',
    bankCode: 'pankkikoodi',
    bankName: 'Pankin nimi',
    bankAccount: 'Pankkitili',
    mobilePhone: 'matkapuhelinnumero',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'ole hyvä ja syötä',
    country: 'maa/alue',
    name: 'nimi',
    surname: 'sukunimi',
    zengin: 'zengin',
    bankName: 'Pankin nimi',
    bankAccount: 'Pankkitili',
    county: 'maakunta',
    mobilePhone: 'matkapuhelinnumero',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'ole hyvä ja syötä',
    country: 'maa/alue',
    name: 'nimi',
    surname: 'sukunimi',
    swift: 'swift',
    ach: 'ah',
    bankName: 'Pankin nimi',
    bankAccount: 'Pankkitili',
    state: "Provinssi tai osavaltio",
    mobilePhone: 'matkapuhelinnumero',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'ole hyvä ja syötä',
    name: 'nimi',
    surname: 'sukunimi',
    bankName: 'Pankin nimi',
    bankAccount: 'Pankkitili',
    mobilePhone: 'matkapuhelinnumero',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: "Please enter",
    bankName: 'Pankin nimi',
    account: "Tili",
    clabe: 'clabe account',
    mobilePhone: 'matkapuhelinnumero',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'osoite',
    pleaseInformation: "Syötä virtuaalivaluutan osoite"
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Kirjautumissalasanan muuttaminen', // kirjautumissalasana
    oldPass: 'vanha salasana', // vanha salasana
    newPass: 'uusi salasana', // uusi salasana
    email: "Vahvistuskoodi",
    placeholderOldPass: 'Anna vanha salasana',
    placeholderNewPass: 'Anna uusi salasana',
    placeholderEmailCode: "Anna vahvistuskoodi",
    submitBtn: 'lähetä', // vahvista
    affirm: 'confirm', // vahvista
}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Maksun salasanan muuttaminen', // Maksun salasana
    affirmPass: 'maksun salasana',
    email: 'Vahvistuskoodi', // Vahvista salasana
    submitBtn: 'Lähetä',// Vahvista
    affirm: 'confirm', // vahvista
    placeholder: 'Syötä',
    placeholderPaymentPassword: "Anna maksun salasana",
    placeholderEmailCode: "Anna vahvistuskoodi",
}

//  系统设置
let settingLanguage = {
    navTitle: 'Järjestelmäasetukset', // Asetus
    languageShift: 'Monikieliset asetukset', // kielenvaihto
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Monikieliset asetukset',
    affirm: 'vahvista'
}
//  公告
let afficheDetail = {
    navTitle: 'viesti', // ilmoitus
    comment: 'vastaa',// kommentti
    unreadMessage: 'lukematon',
    readTitle: 'lue'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'viestin tiedot', // ilmoitus
    comment: 'kommentti',// kommentti
    check: "Näytä",
    packUpPlaceholder: "Anna vastauksen sisältö",
    sendInform: 'lähetä'
}

// 客服
let serviceLanguage = {
    navTitle: 'Asiakaspalvelu', // Asiakaspalvelu
    customerService: 'yksinomainen asiakaspalvelu',//asiakaspalvelu
    helloTitle: "Hei",
    welcomeTitle: 'Olen sinun <, voit ottaa minuun yhteyttä seuraavilla tavoilla',
    iconInformation: 'Valitse viestintäohjelmisto konsultaatiota varten',
    whatsApp: 'whatsApp',
    telegram: 'sähkö',
    line: 'rivi',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Tilausten hallinta", // Tilauslista
    tabsTodos: "ostettu", // kaikki
    tabsPendente: "ostettava", // ostettava
    tabsCompleto: "Logistiikassa", // logistiikassa
    tabsCancelar: "Valmis", // Valmis
    totalProfit: 'Total Profit',
    statementPrice: "Oston kokonaissumma", // Kokonaissumma:
    affirmBtn: "Osta", // vahvista
    orderTotal: "Tilaukset yhteensä",
    orderCode: "Tilausnumero:",
    childrenDetail: 'tuotteet', // tiedot
    checkDetail: 'Tuotantotiedot', // Näytä tiedot
    buyAll: "Osta kaikki",
    dialogTitle: 'Ostotiedot',
    dialogTotalSales: 'Total Sales',
    dialogTotalPurchase: 'Oston kokonaissumma',
    dialogTotalProfit: 'Kokonaisvoitto',
    dialogBtn: 'Ostaminen',
    deleteTitle: 'Tilaa ostos',
    dialogMessage: 'Oletko varma, että ostat tämän tilauksen? ',
    dialogAllMessage: 'Oletko varma, että ostat kaikki tilaukset? ',
    affirm: 'vahvista',
    close: 'Peruuta',
    selectionPeriod: 'ajan valinta',//valitse aika
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Tilauksen tiedot", // Tilauslista
    orderCode: "Tilausnumero:",//Tilausnumero
    totalProfitTitleCard: 'Kokonaisvoitto',
    statementPrice: "Oston kokonaissumma", // Kokonaissumma:
    amountRelated: 'Tilauksen määrä',
    totalSalesTitle: 'Total Sales',
    totalPurchase: 'Kokonaisvoitto',
    totalProfitTitle: "Ostokset yhteensä",
    btnTitle: 'Osta',
    deleteTitle: 'Tilaa ostos',
    dialogMessage: 'Vahvistatko ostotilauksen muutoksen? ',
    affirm: 'vahvista',
    close: 'Peruuta',
    salesTitle: "Myyntimäärä",
    discount: "myyntisuhde",
    salesAmount: "Todellinen myyntimäärä",
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "tilaa tuotetiedot", // tilauslista
    orderCode: "Tilausnumero:",//Tilausnumero
    productInformation: "Tuotetiedot", // Tuotetiedot
    totalProfitTitleCard: 'Kokonaisvoitto',
    statementPrice: "Oston kokonaissumma:", // Kokonaissumma:
    unitPrice: "yksikköhinta", // yksikköhinta
    salesVolume: "Total Sales Price", // Myyntimäärä
    purchasePrices: "Ostoyksikköhinta", // Ostohinta
    purchasePricesTotal: "Kokonaisostohinta", // ostohinta
    profitPerPiece: "voittoyksikköhinta", // voitto
    profitPerPieceTotal: "Total Profit Price", // Profit
    orderCardTitle: 'Maksu',
    paymentMethods: "Payment Method", // Luo käyttöikä
    paymentTime: "maksuaika", // maksuaika
    returnedMoneyTitle: "palautti rahat",
    returnedMoneyMethods: 'Onko rahat palautettu',
    returnedMoneyMethods1: "Ei maksettu takaisin",
    returnedMoneyMethods2: 'Rahat on palautettu',
    returnedMoneyTime: 'Palautusaika',
    logisticsInformation: "Logistiikkatiedot",
    logisticsInformationTitle: "Klikkaa nähdäksesi viimeisimmät logistiikkauutiset",
    trackingNumber: 'Logistiikkanumero',
    orderNumber: 'Tilausnumero',
    logisticsCardTitle: "Logistiikkatiedot"
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Anna kyselyn ehdot',
}
// 脚部语言
let footerLanguage = {
    home: "Koti",
    order: 'tilaa',
    Workbench: "Työpöytä",
}
let chatPageMessage = {
    ladingText: 'Lataa...',
    loosingText: 'Julkaise päivitykset...',
    pullingText: 'pudotusvalikosta päivitys...',
    send: 'Lähetä',
    messageText:'news',
    connecting: 'Pikaviestipalvelu yhdistää...',
    connectionSuccess:'Pikaviestipalveluyhteys onnistui',
    connectionFail: 'Pikaviestipalveluyhteys epäonnistui',
    connectionSendFail: 'Pikaviestipalvelua ei ole yhdistetty, lähetys epäonnistui',
}




/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: "Kutsu ystäviä ansaitsemaan palkintoja",
    qrCodeLanguage: 'Kopioi',
    ruleTitle: "Kutsusäännöt",
    rule1: 'Kutsuja voi kutsua ystäviä viimeistelemään rekisteröinti jakamalla promootiolinkin, jolloin kutsutut ystävät yhdistetään sinuun;',
    rule2: "Kutsuttuasi käyttäjiä suorittamaan maksun, voit saada osuuden heidän tuloistaan.",
    rule3: 'Tue vain kolmitasoisia kutsupalkintoja, palkkiosuhde on: <%, >%, @%;',
    rule4: 'Kutsutavan on oltava laillinen ja ystävien kutsumista koskevien sääntöjen mukainen. Jos jokin sopimaton kutsutapa tai -käyttäytyminen rikkoo ystävien kutsumista koskevia sääntöjä, kaikki jakamattomat palkinnot vähennetään sen vahvistamisen jälkeen. Alusta varaa oikeuden jäljittää kaikki myönnetyt palkinnot ja harjoittaa laillista vastuuta tapauksen mukaan. ',
    situation: "Viimeisin kutsuennätys",
    copySuccess: 'Kopiointi onnistui',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: "Kutsu käyttäjätietue",
    cardTitle: "Käyttäjien kokonaismäärä",
    firstLevelUser: "taso yksi",
    secondaryUser: 'toissijainen',
    tertiaryUser: 'Tertiary',

    tabs1Title: "Kaikki",
    tabs2Title: "taso yksi",
    tabs3Title: "Toissijainen",
    tabs4Title: "Kolmas taso",
}
// 总邀请页面
let commissionLanguage = {
    navTitle: "Kutsutulotietue",
    cardTitle: 'Kokonaistulot',
    placeholder: 'Syötä',
    firstLevelUser: "taso yksi",
    secondaryUser: 'toissijainen',
    tertiaryUser: 'Tertiary',
    store: "kokonaismäärä",
    ratio: "suhde",
    commission: "tulot",

    tabs1Title: "Kaikki",
    tabs2Title: "taso yksi",
    tabs3Title: "Toissijainen",
    tabs4Title: "Kolmas taso",
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Myynnin hallinta',
    navContent: "Tilaa tuotteen myynnin sijoitus",
    tabs1: 'myynti',
    tabs2: "Kokonaismäärä",
    tabs3: 'Tuottojen määrä',
    searchPlaceholder: 'Tuotteen nimi',
    date1Placeholder: 'Alkamisaika',
    date2Placeholder: 'End time',
    salesVolume: 'myynti',
    totalPrice: "kokonaismyynti",
    shelfTime: 'Kokonaisvoitto',
    ranking: 'EI',
    timeAffirm: 'Vahvista',
    timeCancel: 'Peruuta'
};
let rankingItemLanguage = {
    navTitle: 'Tuotemyyntiennätys',
    date1Placeholder: 'Alkamisaika',
    date2Placeholder: 'End time',
    tabs1: 'myynti',
    tabs2: 'aika',
    salesTime: 'aika',
    salesVolume: 'myynti',
    totalAmount: 'total sales',
    timeAffirm: 'Vahvista',
    timeCancel: 'Peruuta'

};
// 个人中心新增
myCenterLanguage.invitation = 'kutsua'
myCenterLanguage.commission = 'komissio'

// 订单页面新增
orderPageLangage.orderBgTitle='Myyntiarvo, tiedän'
orderPageLangage.orderBgLeaveFor='mene'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
