import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
  path: "/",
  name: "Home",
  redirect: "/home/home-index",
},
// {
//   path: '/transitionIndex',
//   name: 'transitionIndex',
//   component: () => import("@/views/page/transition-index.vue"),
//   meta: {
//     isShow: true
//   }
// },
{
  path: "/login",
  name: "login",
  component: () => import("@/views/Login.vue"),
  redirect: "/login/loginIndex",
  children: [{
    path: "/login/loginIndex",
    name: "loginIndex",
    component: () => import("@/views/page/login-index.vue"),
    meta: {
      isShow: true
    }
  },
  {
    path: "/login/loginIndex/change-password",
    name: "changePassword",
    component: () => import("@/views/detail/change-password.vue"),
    meta: {
      isShow: true
    }
  },
  ],
},
{
  path: '/chatIndex/chatRoomList',
  name: "chatRoomList",
  component: () => import("@/views/chatRoom/chat-room-list.vue"),
  meta: {
    keepAlive: true,
    scrollTop: 0,
    isShow: true
  }
},
{
  path: '/chatIndex/chat-page',
  name: "chatPageIndex",
  component: () => import("@/views/chatRoom/chat-page.vue"),
  meta: {
    isShow: true
  }
},
{
  path: "/join-index",
  name: "joinIndex",
  component: () => import("@/views/page/join-index.vue"),
  meta: {
    isShow: true
  }
},
{
  path: "/home",
  name: "Home",
  component: () => import("@/views/Home.vue"),
  redirect: "/home/home-index",
  children: [{
    path: "/home/home-index",
    name: "homeIndex",
    component: () => import("@/views/page/home-index.vue"),
  },
  {
    path: "/home/recharge-index",
    name: "rechargeIndex",
    component: () => import("@/views/page/recharge-index.vue"),
  },
  {
    path: "/home/withdrawal-index",
    name: "withdrawalIndex",
    component: () => import("@/views/page/withdrawal-index.vue"),
  },
  {
    path: "/home/workbench-index/change-password",
    name: "paymentPass",
    component: () => import("@/views/detail/change-password.vue"),
  },
  {
    path: "/home/workbench-index/paymentSetting",
    name: "paymentSetting",
    component: () => import("@/views/page/paymentSetting-index.vue"),
  },
  {
    path: "/home/message-index",
    name: "messageIndex",
    component: () => import("@/views/page/message-index.vue"),
  },
  ],
},
{
  path: "/order",
  name: "Order",
  component: () => import("@/views/Order.vue"),
  redirect: "/order/order-index",
  children: [{
    path: "/order/order-index",
    name: "orderIndex",
    component: () => import("@/views/page/order-index.vue"),
  },
  {
    path: "/order/order-index/order-detail",
    name: "orderDetail",
    component: () => import("@/views/detail/order-detail.vue"),
  },
  {
    path: "/order/order-index/orderList",
    name: "orderList",
    component: () => import("@/views/detail/orderList-detail.vue"),
  },
  {
    path: "/order/order-index/ranking-list",
    name: "orderList",
    component: () => import("@/views/detail/ranking-list.vue"),
    meta: {
      title: '排行榜'
    }
  },
  {
    path: "/order/order-index/ranking-list-item",
    name: "orderList",
    component: () => import("@/views/detail/ranking-list-item.vue"),
    meta: {
      title: '排行榜单个商品'
    }
  },
  ],
},
{
  path: "/workbench",
  name: "Workbench",
  component: () => import("@/views/Workbench.vue"),
  redirect: "/order/order-index",
  children: [{
    path: "/workbench/message-index",
    name: "messageIndex",
    component: () => import("@/views/page/message-index.vue"),
  },
  {
    path: "/workbench/workbench-index",
    name: "workbenchIndex",
    component: () => import("@/views/page/workbench-index.vue"),
  },
  {
    path: "/workbench/workbench-index/message-detail",
    name: "messageDetail",
    component: () => import("@/views/detail/message-detail.vue"),
  },
  {
    path: "/workbench/workbench-index/productLibrary-index",
    name: "productLibrary",
    component: () => import("@/views/page/productLibrary-index.vue"),
  },
  {
    path: "/workbench/workbench-index/financialManagement-index",
    name: "financialManagement",
    component: () => import("@/views/page/financialManagement-index.vue"),
  },
  {
    path: "/workbench/workbench-index/statement-index",
    name: "statement",
    component: () => import("@/views/page/statement-index.vue"),
  },
  {
    path: "/workbench/workbench-index/statement-index/statistical-form",
    name: "statisticalForm",
    component: () => import("@/views/page/statistical-form-index.vue"),
  },
  {
    path: "/workbench/workbench-index/accountManagement-index",
    name: "accountManagement",
    component: () => import("@/views/page/accountManagement-index.vue"),
  },
  {
    path: "/workbench/workbench-index/setting-index",
    name: "setting",
    component: () => import("@/views/page/setting-index.vue"),
  },
  {
    path: "/workbench/workbench-index/setting-index/language-shift",
    name: "languageShift",
    component: () => import("@/views/detail/language-shift.vue"),
  },
  {
    path: "/workbench/workbench-index/accountManagement-index/change-password",
    name: "changePassword",
    component: () => import("@/views/detail/change-password.vue"),
  },
  {
    path: "/workbench/workbench-index/accountManagement-index/paymentPassword",
    name: "paymentPassword",
    component: () => import("@/views/detail/change-password.vue"),
  },
  {
    path: "/workbench/workbench-index/accountManagement-index/paymentSetting",
    name: "paymentSetting",
    component: () => import("@/views/page/paymentSetting-index.vue"),
  },
  {
    path: "/workbench/workbench-index/service-index",
    name: "serviceIndex",
    component: () => import("@/views/page/service-index.vue"),
  },
  {
    path: "/workbench/workbench-index/download-app",
    name: "downloadApp",
    component: () => import("@/views/page/download-app.vue"),
  },
  {
    path: "/workbench/workbench-index/activity-index",
    name: "activity",
    component: () => import("@/views/page/activity-index.vue"),
  },
  {
    path: "/workbench/workbench-index/activity-index/discount-detail",
    name: "discount",
    component: () => import("@/views/detail/discount-detail.vue"),
  },
  {
    path: "/workbench/workbench-index/invitation-page",
    name: "invitationPage",
    component: () => import("@/views/detail/invitation-page.vue"),
  },
  {
    path: "/workbench/workbench-index/total-invitation",
    name: "totalInvitation",
    component: () => import("@/views/detail/total-invitation.vue"),
  },
  {
    path: "/workbench/workbench-index/total-commission",
    name: "totalInvitation",
    component: () => import("@/views/detail/total-commission.vue"),
  },
  ],
},

];

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (from.meta.keepAlive) {
    const $content = document.querySelector('#chatListIndex');
    const scrollTop = $content ? $content.scrollTop : 0;
    from.meta.scrollTop = scrollTop;
  } else {
    document.body.scrollTop = 0
    // document.documentElement.scrollTop = 0
  } 
  next()
})

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//     return originalPush.call(this, location).catch(err => err)
// }
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}
export default router