<template>
  <div id="app">
    <transitionCard v-if="transitionIsShow" @tiTokClose='tiTokClose' />
    <template v-else>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />

      <template v-if="isShow">
        <div class="message-entrance" @click="onChangRouter">
          <img :src="messageIcon.messageIcons" alt="" />
          <span class="unread-message" v-if="unreadNumber > 0">
            {{ unreadNumber }}
          </span>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import images from "./components/component/js/images";
import websocketApi from "./api/websocketApi";
import utils from "@/utils/storangeWathch";
import transitionCard from '@/views/page/transition-index.vue'
export default {
  components: { transitionCard },
  data () {
    return {
      messageIcon: images,
      unreadNumber: 0,
      imIsShow: false,
      isShow: false,
      transitionIsShow: true,
    };
  },
  created () {
    window.addEventListener("error1002", (val) => {
      this.transitionIsShow = true
    });
    window.addEventListener("loadingSuccessCount", (val) => {
      this.transitionIsShow = false
    });
    if (this.$route.query.data) {
      this.transitionIsShow = true
    }
  },
  watch: {
    $route: {
      handler (val, oldval) {
        this.callBack(val);
      },
      // 深度观察监听
      deep: true,
    },

  },
  updated () {
    if (localStorage.getItem("authentication") && !this.imIsShow) {
      this.imIsShow = true;

      this.$socketApi.initWebSocket(this.getsocketResult);
      this.unreadMessageGet();
    }
  },
  methods: {
    tiTokClose () {
      this.transitionIsShow = false
    },
    callBack (val) {
      this.isShow = false;

      if (
        !localStorage.getItem("authentication") ||
        (localStorage.getItem("authentication") &&
          (val.path == "/chatIndex/chatRoomList" ||
            val.path == "/chatIndex/chat-page"))
      )
        return false;

      this.isShow = true;
      this.unreadMessageGet();
      this.$addStorageEvent(0, "websocket");
      console.log(this.isShow);
    },
    getsocketResult (data) {
      if (data.type === "register") {
        let params = {
          clientId: data.content,
        };
        utils.resetSetItem("errorType", true);
        this.getRegisterWays(params);
      }
      if (data.type === "text" || data.type === "image") {
        utils.resetSetItem("errorType", true);
        this.$addStorageEvent(1, "websocket", JSON.stringify(data));
        this.unreadMessageGet();
      }
    },
    // 用户第一次登陆时调用的接口
    async getRegisterWays (params) {
      const { data, code } = await websocketApi.registerMessagePort(params);
    },

    // 未读消息广播设置
    async unreadMessageGet (isForcibly) {
      const { data, code } = await websocketApi.unreadMessagePort();
      if (code == 200) {
        this.unreadNumber = Number(data.number);
      }
    },
    onChangRouter () {
      this.$router.push({ path: "/chatIndex/chatRoomList" });
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  max-width: 768px;
  margin: auto !important;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

#app {
  width: 100%;
  margin: auto;
  position: relative;
  min-height: calc(100vh);
}

.van-popup {
  max-width: 468px !important;
  width: 468px !important;
}

.van-popup--bottom {
  max-width: 100% !important;
  width: 100% !important;
}

.message-entrance {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  right: 20px;
  top: 550px;
  box-shadow: 0px 0px 15px 0 #ccc;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding: 10px;
    width: 60px;
    height: 60px;
  }
}

.unread-message {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 25px;
  height: 25px;
  max-width: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: red;
  font-size: 18px;
  color: #fff;
  padding: 6px;
}

.axios-dialog-class {
  border-radius: 16px !important;

  // width:490px !important;
  .van-dialog__content {
    .van-dialog__message {
      text-align: left;
      font-size: 32px;
      line-height: 1.5;
    }
  }

  .van-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .van-loading__spinner.van-loading__spinner--circular {
      width: 45px;
      height: 45px;
    }
  }
}
</style>
