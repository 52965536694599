// 葡萄牙语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Atualização de versão, por favor confirme? ',// Atualização de versão, por favor confirme?
    loadingTitle: 'Baixando...', // Baixando...
    systemUpgrade: 'atualização do sistema', // atualização do sistema
    upgradeImmediately: 'Confirmar', // Confirmar
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'nome da loja',
    pass: 'senha',
    placeholderPass: 'Por favor, digite sua senha',
    placeholderShopName: "Por favor, digite o nome da loja",
    mail: "Caixa de correio",
    placeholderMail: "Por favor, digite seu e-mail",
    emailCode: "Código de verificação de e-mail",
    placeholderEmailCode: "Por favor, digite o código de verificação",
    send: "Enviar",
    InvitationCode: 'Código de convite',
    PlaceholderInvitationCode: 'Por favor, insira o código de convite',
    documents: 'Carregar documentos',
    front: 'frente do documento',
    reverseSide: 'frente do documento',
    placeholderFront: 'Por favor, carregue a frente do certificado',
    placeholderReverseSide: 'Por favor, carregue o verso do documento',
    btn: 'Aplicar',
    applicationBtn: 'Confirmar',
    applicationNav: 'Acordo de Aplicativo da Loja',
    dialogTitle: 'Revisar o sucesso',
    dialogMessage: 'A inscrição foi bem-sucedida, por favor, aguarde pacientemente a revisão da equipe！',
    close: 'Confirmar',
}
// 登录
let loginLanguage = {
    navTitle: 'login', // login
    warningTitle: 'Por favor, digite seu e-mail de login',
    passTitle: 'Por favor, digite a senha de login', // senha
    forgetPassword: 'Esqueceu sua senha? ',//Esqueceu a senha
    btnTitle: 'login', // login
    hintTitle: 'Sem conta? ir para registrar', // registrar
    linkTitle: 'atendimento ao cliente', // atendimento ao cliente
    AppDownload: 'Baixar aplicativo', //Baixar aplicativo
    goToShop: 'Para o shopping',
    register: 'registo'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Esqueci a senha',
    email: 'e-mail de login',
    placeholderEmail: 'Por favor, digite seu e-mail de login',
    emailCode: 'Código de verificação',
    placeholderEmailCode: 'Por favor, insira o código de verificação',
    newPass: 'Nova senha',
    placeholderNewPass: "Por favor, digite uma nova senha",
    affirmPass: 'Confirmar senha',
    placeholderAffirmPass: 'Por favor, digite a senha duas vezes',
    submitBtn: 'enviar',
    affirm: 'confirmar',
}

// 首页
let homeLanguage = {
    navTitle: 'Vendas de hoje', // Home
    orderTitle: 'Número de pedidos hoje',//Pedido
    balanceTitle: 'balance',//balance
    flowTitle: 'Contagem de tráfego de hoje', //Tráfego de hoje
    navTitleLine: 'Vendas', // Home
    orderTitleLine: 'Número de pedidos',//Pedido
    flowTitleLine: 'Número do fluxo', //Fluxo de hoje
    rechargeTitle: 'Recarregar', // recarregar
    bankTitle: 'Retirar', // Retirar
    analysis: 'Visão geral dos últimos 30 dias',//Título do gráfico de linhas
    showTitle: 'Expand',//Expandir o gráfico de linhas
    selectAll: 'select all',//select all
    cancel: 'fechar',//fechar
    sure: 'confirmar',//confirmar
    revenueLanguage: 'tax',
    revenuepopupTitle: 'Lembrete fiscal',
    revenuepopupWarn: 'Para recarregar e pagar impostos',
    revenuepopupNeed: 'O imposto está vencendo no momento',
    revenuepopupSurn: 'confirmar',
    outstandingAmount: 'valor pendente',
    outstandingOrders: 'pedidos pendentes',
    totalSalesYesterday: 'Total de vendas ontem',
    yesterdaySalesOrders: 'Pedidos de venda de ontem',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Recarregar', // Recarregar
    currencyType1: 'moeda fiduciária',
    currencyType2: 'Moeda virtual',
    pleaseChoose: "Por favor, escolha",
    cancelBtn: 'Cancelar',
    submitBtn: 'confirm', // confirma
    placeholderNav: 'Digite o valor da recarga', // saldo atual
    placeholderCurrent: 'Por favor, insira o valor da recarga', // insira o valor
    dialogMessage: 'Por favor selecione o tipo de moeda. ',
    pathTitle: 'método de recarga',// canal
    hintTitle: 'Importante:',
    hintContent: 'Somente permitido importar <assets',
    success: 'Copiar com sucesso! ',
    hintPopup: 'Por favor, entre em contato com o pessoal de atendimento ao cliente!',
    hintTile: 'Informação de pedido'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Retirar', // Retirar
    currencyType1: 'moeda fiduciária',
    currencyType2: 'Moeda virtual',
    pleaseChoose: "Por favor, escolha",
    cancelBtn: 'Cancelar',
    submitBtn: 'confirm', // confirma
    placeholderNav: 'Digite o valor da retirada',
    placeholderCurrent: 'Por favor, insira o valor da retirada', // insira o valor
    currentBalance: 'Saldo atual', // Saldo atual
    passwordTips: 'Por favor, digite a senha de retirada', // digite o valor
    pathTitle: 'taxa de câmbio', // canal
    rateTips: 'Taxa de conversão atual',
    dialogMessage: 'Por favor selecione o tipo de moeda. ',
    hintTitle: 'Importante:',
    hintContent: 'Somente permitido importar < ativos',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'erro', // erro
    loadingTitle: 'Carregando', // Carregando
    finishedText: "nenhum", // nenhum
    loadingText: 'carregando', // carregando
    wait: 'Por favor, espere um momento',
    placeholderHite: 'O valor de entrada está incorreto',
    uploadError: 'Falha no upload',
    upload: 'Enviando',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'centro pessoal', // centro pessoal
    bankCard: 'Configurações de saque', // Endereço de saque
    productLibrary: 'gerenciamento de produtos', //biblioteca de produtos
    financialManagement: 'Registro do fundo', //gestão financeira
    reportStatistics: 'Relatório de negócios', //Estatísticas
    accountInformation: 'Armazenar informações', //Alternar idioma
    setting: 'Configurações do sistema',
    goToShop: 'Para o shopping',
    goToLoan: 'empréstimo',
    downloadApp: 'Baixar aplicativo',
    activity: 'Atividade'
}
// 活动页面
let activityLanguage = {
    navTitle: 'Centro de atividades',
    discount: 'desconto'
}
let discountLanguage = {
    navTitle: 'desconto',
    affirmBtn: 'Adicionar',
    submitBtn: 'enviar',
    accain: 'Cheio',
    amount: 'o montante',
    ratio: 'Desconto',
    plusAffirm: 'afirmar',
    placeholderEnter: 'por favor digite'
}

// 下载
let downloadApp = {
    navTitle: 'Baixar aplicativo',
    downloadAndroidApp: 'baixar aplicativo android',
    downloadIosApp: 'baixar aplicativo ios',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Biblioteca de produtos', // Biblioteca de produtos
    productList: 'Store Product', // Lista de Produtos
    productLibraryTitle: 'Produto de plataforma', // biblioteca de produtos
    purchasePrice: 'Preço de compra',
    sellingPrice: 'Preço de venda',
    selectAll: 'selecionar tudo',
    affirm: 'confirmar',
    close: 'Cancelar',
    editPrice: 'Modificar preço',
    deleteTitle: 'Excluir pedido',
    dialogMessage: 'Tem certeza de que deseja excluir este produto? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Registros de fundos',
    tabs1Title: 'Todos os fundos', // Liquidez
    tabs2Title: 'registro de recarga', // registro de recarga
    tabs3Title: 'registro de retirada',//registro de retirada
    selectionPeriod: 'seleção de tempo',//selecionar tempo
    rechargeTitle: 'recharge',//recarga
    wthdrawTitle: 'Retirada', // Retirada
    successful: 'sucesso',//sucesso
    balance: 'saldo', // saldo
    rechargeTime: 'tempo de recarga', // tempo de recarga
    rechargeMessage: 'tipo de moeda',// método de recarga
    wthdrawTime: 'tempo de retirada', // tempo de recarga
    wthdrawMessage: 'tipo de moeda',// método de recarga
    currency: "moeda", // moeda
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Total de vendas',
    totalPurchase: 'Valor total da compra',
    totalProfit: 'Lucro total',
    selectionPeriod: 'filtro de tempo',//selecione o tempo
    analysis: 'gráfico de linhas',//título do gráfico de linhas
    showTitle: 'Expand',//Expandir o gráfico de linhas
    selectAll: 'select all',//select all
    cancel: 'fechar',//fechar
    sure: 'confirmar',//confirmar
    fundingDetails: 'dados detalhados', // detalhes do financiamento
    typeSelection: 'tipo de seleção',//tipo de seleção
    purchaseAmount: 'Valor da compra', //Vendas
    profitAmount: 'Profit Amount',//Total de vendas
    unitAmount: 'Vendas', // compra parcelada
    grandTotal: 'cumulativo',
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Lista de gerenciamento',
    selectionPeriod: 'Selecionar hora',//Selecionar hora
    totalPurchase: 'valor da compra',
    totalSales: 'quantidade de lucro',
    totalProfit: 'Vendas',
    grandTotal: 'cumulativo',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Informações da loja',
    picture: 'imagem da loja',
    nameTitle: 'Nome da loja',
    Mail: 'E-mail de login',
    invitationCode: 'Código de convite',
    loginPassword: 'senha de login',
    paymentPassword: 'senha de pagamento',
    documents: 'informações do documento',
    frontDocument: 'frente do documento',
    backDocument: 'verso do documento',
    exit: 'sair',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Configurações de saque', // Endereço de pagamento
    cashCurrency: 'moeda fiduciária',
    virtualCurrency: 'Moeda virtual',
    pleaseChoose: 'Por favor, escolha',
    popupAffirm: 'Confirmar',
    popupCancel: 'Cancelar',
    hintTitle: 'Importante:', // Suporta apenas retirada para < endereço.
    hintContent: 'Somente permitido importar < assets. ', // Suporta apenas retirada para < endereço.
    affirmBtn: 'confirmar', // confirmar
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'nome real',
    email: 'e-mail',
    mobilePhone: 'Número de telefone celular',
    pixType: 'tipo PIX',
    pixKey: 'conta PIX',
    pleaseEnter: 'Por favor, insira',
    pleaseSelect: 'Por favor, selecione',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'por favor insira ',
    country: 'país/região',
    name: 'nome',
    surname: 'sobrenome',
    bsb: 'BSB',
    bankName: 'Nome do banco',
    bankAccount: 'Conta Bancária',
    state: 'Província ou Estado',
    mobilePhone: 'Número de telefone celular',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'por favor insira ',
    country: 'país/região',
    name: 'nome',
    surname: 'sobrenome',
    swift: 'swift',
    bankName: 'Nome do banco',
    iban: 'iban',
    mobilePhone: 'Número de telefone celular',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'por favor insira ',
    country: 'país/região',
    name: 'nome',
    surname: 'sobrenome',
    bankCode: 'código do banco',
    bankName: 'Nome do banco',
    bankAccount: 'Conta Bancária',
    mobilePhone: 'Número de telefone celular',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'por favor insira ',
    country: 'país/região',
    name: 'nome',
    surname: 'sobrenome',
    zengin: 'zengin',
    bankName: 'Nome do banco',
    bankAccount: 'Conta Bancária',
    county: 'condado',
    mobilePhone: 'Número de telefone celular',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'por favor insira ',
    country: 'país/região',
    name: 'nome',
    surname: 'sobrenome',
    swift: 'swift',
    ach: 'ach',
    bankName: 'Nome do banco',
    bankAccount: 'Conta Bancária',
    state: 'Província ou Estado',
    mobilePhone: 'Número de telefone celular',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'por favor insira ',
    name: 'nome',
    surname: 'sobrenome',
    bankName: 'Nome do banco',
    bankAccount: 'Conta Bancária',
    mobilePhone: 'Número de telefone celular',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Por favor, digite',
    bankName: 'Nome do banco',
    account: 'Conta',
    clabe: 'conta clabe',
    mobilePhone: 'Número de telefone celular',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Endereço',
    pleaseInformation: 'Por favor, digite o endereço da moeda virtual'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Modificação de senha de login', // senha de login
    oldPass: 'senha antiga', // senha antiga
    newPass: 'nova senha', // nova senha
    email: 'Código de verificação',
    placeholderOldPass: 'Por favor, digite a senha antiga',
    placeholderNewPass: 'Por favor, digite uma nova senha',
    placeholderEmailCode: 'Por favor, insira o código de verificação',
    submitBtn: 'enviar', // confirma
    affirm: 'confirmar', // confirmar

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Modificação de senha de pagamento', // Senha de pagamento
    affirmPass: 'senha de pagamento',
    email: 'Código de verificação', // Confirmar senha
    submitBtn: 'Enviar', // Confirmar
    affirm: 'confirmar', // confirmar
    placeholder: 'Por favor, digite',
    placeholderPaymentPassword: 'Por favor, digite a senha de pagamento',
    placeholderEmailCode: 'Por favor, insira o código de verificação',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Configurações do sistema', // Configuração
    languageShift: 'Configurações multilíngues', // mudança de idioma
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Configurações multilíngues',
    affirm: 'confirmar'
}
//  公告
let afficheDetail = {
    navTitle: 'message', // notificação
    comment: 'responder', // comentar
    unreadMessage: 'unread',
    readTitle: 'ler'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'detalhes da mensagem', // notificação
    comment: 'comentário', // comentário
    check: 'Visualizar',
    packUpPlaceholder: 'Por favor, insira o conteúdo da resposta',
    sendInform: 'enviar'
}

// 客服
let serviceLanguage = {
    navTitle: 'Atendimento ao Cliente', // Atendimento ao Cliente
    customerService: 'atendimento ao cliente exclusivo',//atendimento ao cliente
    helloTitle: 'Olá',
    welcomeTitle: 'Eu sou seu <, você pode entrar em contato comigo das seguintes maneiras',
    iconInformation: 'Selecione um software de comunicação para consulta',
    whatsApp: 'whatsApp',
    telegram: 'telegram',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Gerenciamento de pedidos", // Lista de pedidos
    tabsTodos: "comprado", // todos
    tabsPendente: "a ser adquirido", // a ser adquirido
    tabsCompleto: "Em logística", // em logística
    tabsCancelar: "Completed", // Completed
    totalProfit: 'Lucro total',
    statementPrice: "Valor total da compra", // Valor total:
    affirmBtn: "Compra", // confirma
    orderTotal: "Total de pedidos",
    orderCode: "Número do pedido:",
    childrenDetail: 'produtos', // detalhes
    checkDetail: 'Detalhes da produção', // Ver detalhes
    buyAll: 'tudo',
    dialogTitle: 'Informações de compra',
    dialogTotalSales: 'Total de vendas',
    dialogTotalPurchase: 'Valor total da compra',
    dialogTotalProfit: 'Lucro total',
    dialogBtn: 'Compras',
    deleteTitle: 'Pedir compra',
    dialogMessage: 'Tem certeza de comprar este pedido? ',
    dialogAllMessage: 'Tem certeza de comprar todos os pedidos? ',
    affirm: 'confirmar',
    close: 'Cancelar',
    selectionPeriod: 'Selecionar hora',//Selecionar hora
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Detalhes do pedido", // Lista de pedidos
    orderCode: "Número do pedido:",//Número do pedido
    totalProfitTitleCard: 'Total Profit',
    statementPrice: "Valor total da compra", // Valor total:
    amountRelated: 'Valor do pedido',
    totalSalesTitle: 'Total de vendas',
    totalPurchase: 'Lucro total',
    totalProfitTitle: 'Total de compras',
    btnTitle: 'Compra',
    deleteTitle: 'Pedir compra',
    dialogMessage: 'Você confirma a alteração da ordem de compra? ',
    affirm: 'confirmar',
    close: 'Cancelar',
    salesTitle: 'Valor das vendas',
    discount: 'taxa de vendas',
    salesAmount: 'Valor real das vendas',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "pedido de detalhes do produto", // lista de pedidos
    orderCode: "Número do pedido:",//Número do pedido
    productInformation: "Informações do Produto", // Informações do Produto
    totalProfitTitleCard: 'Total Profit',
    statementPrice: "Valor total da compra:", // Valor total:
    unitPrice: "preço unitário", // preço unitário
    salesVolume: "Total Sales Price", // Volume de vendas
    purchasePrices: "Purchase Unit Price", // Preço de compra
    purchasePricesTotal: "Preço total de compra", // preço de compra
    profitPerPiece: "preço unitário do lucro", // lucro
    profitPerPieceTotal: "Preço de lucro total", // Lucro
    orderCardTitle: 'Pagamento',
    paymentMethods: "Payment Method", // Gerar tempo de vida
    paymentTime: "hora do pagamento", // hora do pagamento
    returnedMoneyTitle: 'dinheiro devolvido',
    returnedMoneyMethods: 'Se o dinheiro é devolvido',
    returnedMoneyMethods1: 'Não pago de volta',
    returnedMoneyMethods2: 'O dinheiro foi devolvido',
    returnedMoneyTime: 'Hora de retorno',
    logisticsInformation: 'Informações de Logística',
    logisticsInformationTitle: 'Clique para ver as últimas notícias de logística',
    trackingNumber: 'Número de Logística',
    orderNumber: 'Número do pedido',
    logisticsCardTitle: 'Informações de Logística'

};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Por favor, insira as condições da consulta',
}
// 脚部语言
let footerLanguage = {
    home: 'Casa',
    order: 'ordem',
    Workbench: 'Banca de trabalho',
}
let chatPageMessage = {
    ladingText: 'Download . ...',
    loosingText: 'Publicar actualizações...',
    pullingText: 'Dropdown update...',
    send: 'Enviar',
    messageText: 'notícias',
    connecting: 'Serviço de mensagens instantâneas conectando...',
    connectionSuccess: 'A conexão do serviço de mensagens instantâneas foi bem-sucedida',
    connectionFail: 'Falha na conexão do serviço de mensagens instantâneas',
    connectionSendFail: 'Serviço de IM não está conectado, falha no envio',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Convidar amigos para ganhar recompensas',
    qrCodeLanguage: 'Copiar',
    ruleTitle: 'Regras de convite',
    rule1: 'O convidante pode convidar amigos para completar o cadastro compartilhando o link da promoção, e os amigos convidados serão associados a você;',
    rule2: 'Depois de convidar os usuários a concluir o pagamento, você pode obter uma parte de sua receita;',
    rule3: 'Suporta apenas recompensas de convite de três níveis, a taxa de recompensa é: <%, >%, @%;',
    rule4: 'O método de convite precisa ser legal e estar de acordo com as regras para convidar amigos. Se houver algum método de convite impróprio ou comportamento que viole as regras para convidar amigos, uma vez verificado, todas as recompensas não emitidas serão deduzidas. A plataforma reserva-se o direito de rastrear todas as recompensas emitidas e exercer a responsabilidade legal, conforme o caso. ',
    situation: 'O último registro de convite',
    copySuccess: 'Copiado com sucesso',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Invite user record',
    cardTitle: 'Número total de usuários',
    firstLevelUser: 'nível um',
    secondaryUser: 'secundário',
    tertiaryUser: 'Terciário',

    tabs1Title: 'Todos',
    tabs2Title: 'nível um',
    tabs3Title: 'Secundário',
    tabs4Title: 'Terceiro nível',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Registro de receita de convite',
    cardTitle: 'Receita total',
    placeholder: 'Por favor, digite',
    firstLevelUser: 'nível um',
    secondaryUser: 'secundário',
    tertiaryUser: 'Terciário',
    store: 'valor total',
    ratio: 'razão',
    commission: 'renda',

    tabs1Title: 'Todos',
    tabs2Title: 'nível um',
    tabs3Title: 'Secundário',
    tabs4Title: 'Terceiro nível',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Gerenciamento de vendas',
    navContent: 'Classificação de vendas de produtos do pedido',
    tabs1: 'Vendas',
    tabs2: 'Valor total',
    tabs3: 'Valor do Lucro',
    searchPlaceholder: 'Nome do produto',
    date1Placeholder: 'Hora de início',
    date2Placeholder: 'Hora de término',
    salesVolume: 'vendas',
    totalPrice: 'total de vendas',
    shelfTime: 'Lucro total',
    ranking: 'NÃO',
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar'
};
let rankingItemLanguage = {
    navTitle: 'Registro de vendas do produto',
    date1Placeholder: 'Hora de início',
    date2Placeholder: 'Hora de término',
    tabs1: 'Vendas',
    tabs2: 'tempo',
    salesTime: 'hora',
    salesVolume: 'vendas',
    totalAmount: 'total de vendas',
    timeAffirm: 'Confirmar',
    timeCancel: 'Cancelar'

};
// 个人中心新增
myCenterLanguage.invitation = 'convidar'
myCenterLanguage.commission = 'comissão'

// 订单页面新增
orderPageLangage.orderBgTitle = 'Classificação de vendas, eu sei'
orderPageLangage.orderBgLeaveFor = 'Vá para'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
