// 韩语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: '버전 업그레이드, 확인하시겠습니까? ',// 버전 업그레이드, 확인해주세요?
    loadingTitle: '다운로드 중...', // 다운로드 중...
    systemUpgrade: '시스템 업그레이드', // 시스템 업그레이드
    upgradeImmediately: '확인', // 확인
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: '상점 이름',
    pass: '비밀번호',
    placeholderPass: '비밀번호를 입력하세요',
    placeholderShopName: "상점 이름을 입력하세요",
    mail: "사서함",
    placeholderMail: "이메일을 입력하세요",
    emailCode: "이메일 확인 코드",
    placeholderEmailCode: "확인 코드를 입력하세요",
    send: "보내기",
    InvitationCode: '초대 코드',
    PlaceholderInvitationCode: '초대 코드를 입력하세요',
    documents: '문서 업로드',
    front: '문서 앞',
    reverseSide: '문서 앞면',
    placeholderFront: '인증서의 앞면을 업로드하세요',
    placeholderReverseSide: '문서의 뒷면을 올려주세요',
    btn: '적용',
    applicationBtn: '확인',
    applicationNav: '스토어 애플리케이션 동의',
    dialogTitle: '성공 검토',
    dialogMessage: '신청이 완료되었습니다. 직원이 검토할 때까지 참을성 있게 기다려 주세요!',
    close: '확인',
}
// 登录
let loginLanguage = {
    navTitle: '로그인', // 로그인
    warningTitle: '로그인 이메일을 입력하세요',
    passTitle: '로그인 비밀번호를 입력하세요', // 비밀번호
    forgetPassword: '비밀번호를 잊으셨나요? ',//비밀번호를 잊으 셨나요
    btnTitle: '로그인', // 로그인
    hintTitle: '계정이 없나요? 등록으로 이동', // 등록
    linkTitle: '고객 서비스', // 고객 서비스
    AppDownload: '앱 다운로드', //앱 다운로드
    goToShop: '쇼핑몰로',
    register: '등록'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: '비밀번호 찾기',
    email: '로그인 이메일',
    placeholderEmail: '로그인 이메일을 입력하세요',
    emailCode: '인증 코드',
    placeholderEmailCode: '확인 코드를 입력하세요',
    newPass: '새 비밀번호',
    placeholderNewPass: "새 비밀번호를 입력하세요",
    affirmPass: '비밀번호 확인',
    placeholderAffirmPass: '비밀번호를 두 번 입력하세요',
    submitBtn: '보내기',
    affirm: '확인하다',
}

// 首页
let homeLanguage = {
    navTitle: '오늘의 매출', // 홈
    orderTitle: '오늘의 주문 수',//주문
    balanceTitle: '균형',//균형
    flowTitle: '오늘의 교통량', //오늘의 교통량
    navTitleLine: '판매', // 홈
    orderTitleLine: '주문수',//주문
    flowTitleLine: '흐름번호', //오늘의 흐름
    rechargeTitle: '충전', // 충전
    bankTitle: '출금', // 출금
    analysis: '지난 30일 개요',//선 차트 제목
    showTitle: '확장',//라인 차트 확장
    selectAll: '모두 선택',//모두 선택
    cancel: '닫기',//닫기
    sure: '확인',//확인
    revenueLanguage: '세금',
    revenuepopupTitle: '세금 알림',
    revenuepopupWarn: '재충전 및 세금 납부',
    revenuepopupNeed: '세금이 현재 납부 기한입니다',
    revenuepopupSurn: '확인',
    outstandingAmount: '미결제 금액',
    outstandingOrders: '미결제 주문',
    totalSalesYesterday: '어제 총 판매',
    yesterdaySalesOrders: '어제의 판매 주문',
}

// 充值页
let rechargeLanguage = {
    navTitle: '충전', // 충전
    currencyType1: '명목화폐',
    currencyType2: '가상화폐',
    pleaseChoose: "선택하세요",
    cancelBtn: '취소',
    submitBtn: '확인', // 확인
    placeholderNav: '충전 금액 입력', // 현재 잔액
    placeholderCurrent: '충전할 금액을 입력하세요.', // 충전할 금액을 입력하세요.
    dialogMessage: '통화 유형을 선택하십시오. ',
    pathTitle: '충전 방법',// 채널
    hintTitle: '중요:',
    hintContent: '<assets 가져오기만 허용됨',
    success: '복사 성공! ',
    hintPopup: '고객센터 직원에게 연락하세요!',
    hintTile: '메시지'
}

// 提现页
let withdrawLanguage = {
    navTitle: '철회', // 철회
    currencyType1: '명목화폐',
    currencyType2: '가상화폐',
    pleaseChoose: "선택하세요",
    cancelBtn: '취소',
    submitBtn: '확인', // 확인
    placeholderNav: '출금금액을 입력하세요',
    placeholderCurrent: '출금 금액을 입력하세요.', // 금액을 입력하세요.
    currentBalance: '현재 잔액', // 현재 잔액
    passwordTips: '출금 비밀번호를 입력하세요', // 금액을 입력하세요.
    pathTitle: '환율', // 채널
    rateTips: '현재 전환율',
    dialogMessage: '통화 유형을 선택하십시오. ',
    hintTitle: '중요:',
    hintContent: '<assets 가져오기만 허용됨',
}

// 提示文本
let messageLanguage = {
    warningTitle: '오류', // 오류
    loadingTitle: '로드 중', // 로드 중
    finishedText: "없음", //없음
    loadingText: '로드 중', // 로드 중
    wait: '잠시만 기다려주세요',
    placeholderHite: '입력 값이 올바르지 않습니다.',
    uploadError: '업로드 실패',
    upload: '업로드 중',
}

// 个人中心
let myCenterLanguage = {
    navTitle: '개인 중심', // 개인 중심
    bankCard: '출금 설정', // 출금 주소
    productLibrary: '제품 관리', //제품 라이브러리
    financialManagement: '자금 기록', //재무 관리
    reportStatistics: '사업 보고서', //통계
    accountInformation: '스토어 정보', //언어 전환
    setting: '시스템 설정',
    goToShop: '쇼핑몰로',
    goToLoan: '대출',
    downloadApp: '앱 다운로드',
    activity: '활동'
}
// 活动页面
let activityLanguage = {
    navTitle: '활동 센터',
    discount: '할인'
}
let discountLanguage = {
    navTitle: '할인',
    affirmBtn: '추가',
    submitBtn: '제출',
    accain: '전체',
    amount: '금액',
    ratio: '할인',
    plusAffirm: '확인',
    placeholderEnter: '입력하세요'
}

// 下载
let downloadApp = {
    navTitle: '앱 다운로드',
    downloadAndroidApp: '안드로이드 앱 다운로드',
    downloadIosApp: 'iOS 앱 다운로드',
}

// 产品库
let productLibraryLanguage = {
    navTitle: '제품 라이브러리', // 제품 라이브러리
    productList: '매장 상품', // 상품 목록
    productLibraryTitle: '플랫폼 제품', // 제품 라이브러리
    purchasePrice: '구매 가격',
    sellingPrice: '판매 가격',
    selectAll: '모두 선택',
    affirm: '확인하다',
    close: '취소',
    editPrice: '가격 수정',
    deleteTitle: '주문 삭제',
    dialogMessage: '이 제품을 삭제하시겠습니까? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: '펀드 기록',
    tabs1Title: '모든 자금', // 유동성
    tabs2Title: '기록 충전', // 기록 충전
    tabs3Title: '출금기록',//출금기록
    selectionPeriod: '시간 선택',//시간 선택
    rechargeTitle: '충전',// 충전
    wthdrawTitle: '철회',// 철회
    successful: '성공',//성공
    balance: '균형', // 균형
    rechargeTime: '재충전 시간',// 재충전 시간
    rechargeMessage: '통화 종류',// 충전 방법
    wthdrawTime: '출금 시간',// 충전 시간
    wthdrawMessage: '통화 종류',// 충전 방법
    currency: "통화",// 통화
    timeAffirm: '확인',
    timeCancel: '취소',
}
// 经营报表
let statisticsLanguage = {
    totalSales: '총 매출',
    totalPurchase: '총 구매 금액',
    totalProfit: '총 수익',
    selectionPeriod: '시간 필터',//시간 선택
    analysis: '라인 차트',//라인 차트 제목
    showTitle: '확장',//라인 차트 확장
    selectAll: '모두 선택',//모두 선택
    cancel: '닫기',//닫기
    sure: '확인',//확인
    fundingDetails: 'detailed data', // 펀딩내역
    typeSelection: '선택 유형',//선택 유형
    purchaseAmount: '구매 금액', //매출액
    profitAmount: '이익 금액',//총 매출
    unitAmount: '매출', //할부구매
    grandTotal: '누적',
    timeAffirm: '확인',
    timeCancel: '취소',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: '관리 목록',
    selectionPeriod: '시간 선택',//시간 선택
    totalPurchase: '구매 금액',
    totalSales: '이익 금액',
    totalProfit: '매출',
    grandTotal: '누적',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: '매장 정보',
    picture: '샵 이미지',
    nameTitle: '상점명',
    Mail: '로그인 이메일',
    invitationCode: '초대 코드',
    loginPassword: '로그인 비밀번호',
    paymentPassword: '결제 비밀번호',
    documents: '문서 정보',
    frontDocument: '문서 앞',
    backDocument: '문서 뒷면',
    exit: '로그아웃',
}

//  提现设置
let eBackLanguage = {
    navTitle: '출금 설정', // 입금 주소
    cashCurrency: '명목화폐',
    virtualCurrency: '가상화폐',
    pleaseChoose: '선택하세요',
    popupAffirm: '확인',
    popupCancel: '취소',
    hintTitle: '중요:', // < 주소로의 출금만 지원합니다.
    hintContent: '< 자산만 가져올 수 있습니다. ', // <주소로의 출금만 지원합니다.
    affirmBtn: '확인', // 확인
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: '실명',
    email: '이메일',
    mobilePhone: '휴대폰 번호',
    pixType: 'PIX 유형',
    pixKey: 'PIX 계정',
    pleaseEnter: '입력하세요',
    pleaseSelect: '선택하세요',

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: '입력하세요',
    country: '국가/지역',
    name: '이름',
    surname: '성',
    bsb: 'BSB',
    bankName: '은행명',
    bankAccount: '은행 계좌',
    state: '도 또는 주',
    mobilePhone: '휴대폰 번호',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: '입력하세요',
    country: '국가/지역',
    name: '이름',
    surname: '성',
    swift: '신속하다',
    bankName: '은행명',
    iban: '이반',
    mobilePhone: '휴대폰 번호',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: '입력하세요',
    country: '국가/지역',
    name: '이름',
    surname: '성',
    bankCode: '은행 코드',
    bankName: '은행명',
    bankAccount: '은행 계좌',
    mobilePhone: '휴대폰 번호',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: '입력하세요',
    country: '국가/지역',
    name: '이름',
    surname: '성',
    zengin: '젠긴',
    bankName: '은행명',
    bankAccount: '은행 계좌',
    county: '카운티',
    mobilePhone: '휴대폰 번호',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: '입력하세요',
    country: '국가/지역',
    name: '이름',
    surname: '성',
    swift: '신속하다',
    ach: '아',
    bankName: '은행명',
    bankAccount: '은행 계좌',
    state: '도 또는 주',
    mobilePhone: '휴대폰 번호',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: '입력하세요',
    name: '이름',
    surname: '성',
    bankName: '은행명',
    bankAccount: '은행 계좌',
    mobilePhone: '휴대폰 번호',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: '입력하세요',
    bankName: '은행 이름',
    account: '계정',
    clabe: '클라베 계정',
    mobilePhone: '휴대폰 번호',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: '주소',
    pleaseInformation: '가상화폐 주소를 입력해주세요'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: '로그인 비밀번호 수정', // 로그인 비밀번호
    oldPass: '이전 비밀번호', // 이전 비밀번호
    newPass: '새 비밀번호', // 새 비밀번호
    email: '인증 코드',
    placeholderOldPass: '이전 비밀번호를 입력하세요',
    placeholderNewPass: '새 비밀번호를 입력하세요',
    placeholderEmailCode: '확인 코드를 입력하세요',
    submitBtn: '보내기', // 확인
    affirm: '확인', // 확인

}

//  个人中心修改支付密码
let payPass = {
    navTitle: '결제 비밀번호 수정', // 결제 비밀번호
    affirmPass: '결제 비밀번호',
    email: '인증 코드', // 비밀번호 확인
    submitBtn: '보내기',// 확인
    affirm: '확인', // 확인
    placeholder: '입력하세요',
    placeholderPaymentPassword: '결제 비밀번호를 입력하세요',
    placeholderEmailCode: '확인 코드를 입력하세요',
}

//  系统设置
let settingLanguage = {
    navTitle: '시스템 설정', // 설정
    languageShift: '다국어 설정', // 언어 전환
}
// 设置语言
let languageShiftLanguage = {
    navTitle: '다국어 설정',
    affirm: '확인하다'
}
//  公告
let afficheDetail = {
    navTitle: '메시지', // 알림
    comment: '답글',// 댓글
    unreadMessage: '읽지 않음',
    readTitle: '읽기'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: '메시지 세부 정보', // 알림
    comment: '댓글',// 댓글
    check: '보기',
    packUpPlaceholder: '답글 내용을 입력해주세요',
    sendInform: '보내기'
}

// 客服
let serviceLanguage = {
    navTitle: '고객 서비스', // 고객 서비스
    customerService: '전용 고객 서비스',//고객 서비스
    helloTitle: '안녕하세요',
    welcomeTitle: 'I am yours <, 다음과 같은 방법으로 저에게 연락하실 수 있습니다.',
    iconInformation: '상담할 통신 소프트웨어를 선택하세요',
    whatsApp: '왓츠앱',
    telegram: '전보',
    line: '라인',
    kakao: '카카',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "주문 관리", // 주문 목록
    tabsTodos: "구매", // 모두
    tabsPendente: "구매 예정", // 구매 예정
    tabsCompleto: "물류에서", // 물류에서
    tabsCancelar: "완료됨", // 완료됨
    totalProfit: '총 이익',
    statementPrice: "총 구매 금액", // 총 금액:
    affirmBtn: "구매", // 확인
    orderTotal: "총 주문",
    orderCode: "주문 번호:",
    childrenDetail: '제품', // 세부정보
    checkDetail: '제품 상세정보', // 상세정보 보기
    buyAll: '모두 구매',
    dialogTitle: '구매 정보',
    dialogTotalSales: '총 판매액',
    dialogTotalPurchase: '총 구매 금액',
    dialogTotalProfit: '총 이익',
    dialogBtn: '구매 중',
    deleteTitle: '주문 구매',
    dialogMessage: '이 주문을 구매하시겠습니까? ',
    dialogAllMessage: '모든 주문을 구매하시겠습니까? ',
    affirm: '확인하다',
    close: '취소',
    selectionPeriod: '시간 선택',//시간 선택
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "주문 내역", // 주문 목록
    orderCode: "주문 번호:",//주문 번호
    totalProfitTitleCard: '총 이익',
    statementPrice: "총 구매 금액", // 총 금액:
    amountRelated: '주문 금액',
    totalSalesTitle: '총 매출',
    totalPurchase: '총 이익',
    totalProfitTitle: '총 구매',
    btnTitle: '구매하기',
    deleteTitle: '주문 구매',
    dialogMessage: '구매 주문 변경을 확인하시겠습니까? ',
    affirm: '확인하다',
    close: '취소',
    salesTitle: '판매 금액',
    discount: '매출율',
    salesAmount: '실제 판매 금액',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "주문 상품 상세정보", // 주문 목록
    orderCode: "주문 번호:",//주문 번호
    productInformation: "제품 정보", // 제품 정보
    totalProfitTitleCard: '총 이익',
    statementPrice: "총 구매 금액:", // 총 금액:
    unitPrice: "단가", // 단가
    salesVolume: "총 판매 가격", // 판매량
    purchasePrices: "구매 단가", // 구매 가격
    purchasePricesTotal: "총 구매 가격", // 구매 가격
    profitPerPiece: "이익 단가", // 이익
    profitPerPieceTotal: "총 이익 가격", // 이익
    orderCardTitle: '결제',
    paymentMethods: "결제 수단", // 수명 생성
    paymentTime: "결제시간", // 결제시간
    returnedMoneyTitle: '주문 상태',
    returnedMoneyMethods: '돈 반환 여부',
    returnedMoneyMethods1: '환불되지 않음',
    returnedMoneyMethods2: '주문이 완료되었습니다.',
    returnedMoneyTime: '반환 시간',
    logisticsInformation: '물류정보',
    logisticsInformationTitle: '클릭하시면 최신 물류 소식을 보실 수 있습니다.',
    trackingNumber: '물류 번호',
    orderNumber: '주문번호',
    logisticsCardTitle: '물류정보'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: '쿼리 조건을 입력하세요.',
}
// 脚部语言
let footerLanguage = {
    home: '집',
    order: '주문',
    Workbench: '작업대',
}
let chatPageMessage = {
    ladingText: '다운로드 . ...',
    loosingText: '업데이트 게시...',
    pullingText: '드롭다운 업데이트...',
    send: '보내기',
    messageText: '메시지',
    connecting: 'IM 서비스 연결 중...',
    connectionSuccess: 'IM 서비스 연결 성공',
    connectionFail: 'IM 서비스 연결 실패',
    connectionSendFail: 'IM 서비스가 연결되지 않았습니다. 보내기 실패',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: '친구를 초대하여 보상을 받으세요',
    qrCodeLanguage: '복사',
    ruleTitle: '초대 규칙',
    rule1: '초대자는 친구를 초대하여 프로모션 링크를 공유하여 등록을 완료할 수 있으며, 초대된 친구는 귀하와 연결됩니다.',
    rule2: '결제를 완료하도록 사용자를 초대한 후 수익의 일부를 받을 수 있습니다.',
    rule3: '3단계 초대 보상만 지원, 보상 비율: <%, >%, @%;',
    rule4: '초대 방법은 합법적이고 친구 초대 규칙에 따라야 합니다. 부적절한 초대 방법이나 친구 초대 규칙을 위반하는 행위가 있는 경우, 확인 후 미지급 보상은 모두 차감됩니다. 플랫폼은 발행된 모든 보상을 추적할 권리를 보유하고 경우에 따라 법적 책임을 집니다. ',
    situation: '최근 초대 기록',
    copySuccess: '복사 성공',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: '사용자 레코드 초대',
    cardTitle: '총 사용자 수',
    firstLevelUser: '레벨 1',
    secondaryUser: '보조',
    tertiaryUser: '3차',

    tabs1Title: '모두',
    tabs2Title: '레벨 1',
    tabs3Title: '보조',
    tabs4Title: '세 번째 수준',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: '초대 수익 기록',
    cardTitle: '총 수익',
    placeholder: '입력하세요',
    firstLevelUser: '레벨 1',
    secondaryUser: '보조',
    tertiaryUser: '3차',
    store: '총 금액',
    ratio: '비율',
    commission: '수입',

    tabs1Title: '모두',
    tabs2Title: '레벨 1',
    tabs3Title: '보조',
    tabs4Title: '세 번째 수준',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: '영업관리',
    navContent: '주문상품 판매순위',
    tabs1: '판매',
    tabs2: '총액',
    tabs3: '이익 금액',
    searchPlaceholder: '제품 이름',
    date1Placeholder: '시작 시간',
    date2Placeholder: '종료 시간',
    salesVolume: '매출',
    totalPrice: '총 매출',
    shelfTime: '총 수익',
    ranking: 'NO',
    timeAffirm: '확인',
    timeCancel: '취소'
};
let rankingItemLanguage = {
    navTitle: '상품판매실적',
    date1Placeholder: '시작 시간',
    date2Placeholder: '종료 시간',
    tabs1: '판매',
    tabs2: '시간',
    salesTime: '시간',
    salesVolume: '매출',
    totalAmount: '총 매출',
    timeAffirm: '확인',
    timeCancel: '취소'

};
// 个人中心新增
myCenterLanguage.invitation = '초대하다'
myCenterLanguage.commission = '수수료'

// 订单页面新增
orderPageLangage.orderBgTitle = '판매 순위, 알아요'
orderPageLangage.orderBgLeaveFor = '이동'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
