// 阿拉伯语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'ترقية الإصدار , يرجى التأكيد؟ ', // ترقية الإصدار , يرجى التأكيد؟
    loadingTitle: "جارٍ التنزيل ...", // جارٍ التنزيل ...
    systemUpgrade: "ترقية النظام", // ترقية النظام
    upgradeImmediately: "تأكيد", // تأكيد
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: "اسم المتجر",
    pass: "كلمة المرور",
    placeholderPass: "الرجاء إدخال كلمة المرور الخاصة بك",
    placeholderShopName: "الرجاء إدخال اسم المحل",
    mail: "صندوق البريد",
    placeholderMail: "الرجاء إدخال بريدك الإلكتروني",
    emailCode: "رمز التحقق من البريد الإلكتروني",
    placeholderEmailCode: "الرجاء إدخال رمز التحقق",
    send: "إرسال",
    InvitationCode: "رمز الدعوة",
    PlaceholderInvitationCode: "الرجاء إدخال رمز الدعوة",
    documents: "تحميل المستندات",
    front: "واجهة المستند",
    reverseSide: "واجهة المستند",
    placeholderFront: "الرجاء تحميل مقدمة الشهادة",
    placeholderReverseSide: "الرجاء تحميل الجانب العكسي من المستند",
    btn: "تطبيق",
    applicationBtn: "تأكيد",
    applicationNav: "اتفاقية تطبيق المتجر",
    dialogTitle: "مراجعة النجاح",
    dialogMessage: 'التطبيق ناجح , يرجى الانتظار بصبر حتى يراجع الموظفون',
    close: "تأكيد",
}
// 登录
let loginLanguage = {
    navTitle: "تسجيل الدخول", // تسجيل الدخول
    warningTitle: "الرجاء إدخال البريد الإلكتروني لتسجيل الدخول",
    passTitle: "الرجاء إدخال كلمة مرور تسجيل الدخول", // كلمة المرور
    forgetPassword: ' هل نسيت كلمة المرور؟ ',//هل نسيت كلمة السر
    btnTitle: "تسجيل الدخول", // تسجيل الدخول
    hintTitle: ' ليس لديك حساب؟ اذهب للتسجيل', // تسجيل
    linkTitle: "خدمة العملاء", // خدمة العملاء
    AppDownload: "تنزيل التطبيق", // تنزيل التطبيق
    goToShop: "إلى المركز التجاري",
    register: 'التسجيل'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: "نسيت كلمة المرور",
    email: "البريد الإلكتروني لتسجيل الدخول",
    placeholderEmail: "الرجاء إدخال البريد الإلكتروني الخاص بتسجيل الدخول",
    emailCode: "رمز التحقق",
    placeholderEmailCode: "الرجاء إدخال رمز التحقق",
    newPass: "كلمة مرور جديدة",
    placeholderNewPass: "الرجاء إدخال كلمة مرور جديدة",
    affirmPass: "تأكيد كلمة المرور",
    placeholderAffirmPass: "الرجاء إدخال كلمة المرور مرتين",
    submitBtn: "إرسال",
    affirm: "تأكيد",
}

// 首页
let homeLanguage = {
    navTitle: "مبيعات اليوم", // الصفحة الرئيسية
    orderTitle: "عدد الطلبات اليوم", // الطلب
    balanceTitle: "الرصيد", // الرصيد
    flowTitle: "عدد حركة المرور اليوم", // حركة المرور اليوم
    navTitleLine: "المبيعات", // الصفحة الرئيسية
    orderTitleLine: "عدد الطلبات", // الطلب
    flowTitleLine: "رقم التدفق", // تدفق اليوم
    rechargeTitle: "إعادة الشحن", // إعادة الشحن
    bankTitle: "سحب", // سحب
    analysis: "نظرة عامة على آخر 30 يومًا", // عنوان المخطط الخطي
    showTitle: "توسيع", // قم بتوسيع المخطط الخطي
    selectAll: "حدد الكل", // حدد الكل
    cancel: "إغلاق", // إغلاق
    sure: "تأكيد", // تأكيد
    revenueLanguage: 'ضريبة',
    revenuepopupTitle: 'تذكير الضرائب',
    revenuepopupWarn: 'لإعادة الشحن ودفع الضرائب',
    revenuepopupNeed: 'الضريبة مستحقة حاليا',
    revenuepopupSurn: 'تؤكد',
    outstandingAmount: 'كمية رهيبة',
    outstandingOrders: 'تنظيمات متميزة',
    totalSalesYesterday: 'إجمالي المبيعات أمس',
    yesterdaySalesOrders: 'طلبات مبيعات الأمس',
}

// 充值页
let rechargeLanguage = {
    navTitle: "إعادة الشحن", // إعادة الشحن
    currencyType1: "العملة الورقية",
    currencyType2: "العملة الافتراضية",
    pleaseChoose: "الرجاء الاختيار",
    cancelBtn: "إلغاء",
    submitBtn: "تأكيد", // تأكيد
    placeholderNav: "أدخل مبلغ إعادة الشحن", // الرصيد الحالي
    placeholderCurrent: "الرجاء إدخال مبلغ إعادة الشحن", // أدخل المبلغ
    dialogMessage: ' الرجاء تحديد نوع العملة.',
    pathTitle: "طريقة إعادة الشحن", // القناة
    hintTitle: "هام:",
    hintContent: "مسموح فقط باستيراد <الأصول",
    success: 'انسخ بنجاح!',
    hintPopup: 'يرجى الاتصال بخدمة العملاء !',
    hintTile: 'معلومات سريعة'
}

// 提现页
let withdrawLanguage = {
    navTitle: "سحب", // سحب
    currencyType1: "العملة الورقية",
    currencyType2: "العملة الافتراضية",
    pleaseChoose: "الرجاء الاختيار",
    cancelBtn: "إلغاء",
    submitBtn: "تأكيد", // تأكيد
    placeholderNav: "أدخل مبلغ السحب",
    placeholderCurrent: "الرجاء إدخال مبلغ السحب", // أدخل المبلغ
    currentBalance: "الرصيد الحالي", // الرصيد الحالي
    passwordTips: "الرجاء إدخال كلمة مرور السحب", // أدخل المبلغ
    pathTitle: "سعر الصرف", // القناة
    rateTips: "معدل التحويل الحالي",
    dialogMessage: ' الرجاء تحديد نوع العملة.',
    hintTitle: "هام:",
    hintContent: "مسموح فقط باستيراد <الأصول",
}

// 提示文本
let messageLanguage = {
    warningTitle: "خطأ", // خطأ
    loadingTitle: "تحميل", // تحميل
    finishedText: "لا شيء", // لا شيء
    loadingText: "تحميل", // تحميل
    wait: "من فضلك انتظر لحظة",
    placeholderHite: "قيمة الإدخال غير صحيحة",
    uploadError: "فشل التحميل",
    upload: "تحميل",
}

// 个人中心
let myCenterLanguage = {
    navTitle: "مركز شخصي", // مركز شخصي
    bankCard: "إعدادات السحب", // عنوان السحب
    productLibrary: "إدارة المنتج", // مكتبة المنتجات
    financialManagement: "سجل الصندوق", // الإدارة المالية
    reportStatistics: "تقرير الأعمال", // الإحصائيات
    accountInformation: "معلومات المتجر", // تبديل اللغة
    setting: "إعدادات النظام",
    goToShop: "إلى المركز التجاري",
    goToLoan: "قرض",
    downloadApp: "تنزيل التطبيق",
    activity: 'نشاط'
}
// 活动页面
let activityLanguage = {
    navTitle: 'مركز فعالي',
    discount: 'تخفيض'
}
let discountLanguage = {
    navTitle: 'تخفيض',
    affirmBtn: 'يضيف',
    submitBtn: 'إرسال',
    accain: 'ممتلئ',
    amount: 'كمية',
    ratio: 'تخفيض',
    plusAffirm: 'يؤكد',
    placeholderEnter: 'تفضل'
}

// 下载
let downloadApp = {
    navTitle: "تنزيل التطبيق",
    downloadAndroidApp: ' تنزيل تطبيق android ',
    downloadIosApp: ' تنزيل تطبيق ios',
}

// 产品库
let productLibraryLanguage = {
    navTitle: "مكتبة المنتجات", // مكتبة المنتجات
    productList: "متجر المنتج", // قائمة المنتجات
    productLibraryTitle: "منتج النظام الأساسي", // مكتبة المنتجات
    purchasePrice: "سعر الشراء",
    sellingPrice: "سعر البيع",
    selectAll: "حدد الكل",
    affirm: "تأكيد",
    close: "إلغاء",
    editPrice: ' تعديل السعر',
    deleteTitle: "حذف الطلب",
    dialogMessage: ' هل أنت متأكد من أنك تريد حذف هذا المنتج؟'
}
// 资金记录
let financialManagementLanguage = {
    navTitle: "سجلات الصندوق",
    tabs1Title: "كل الأموال", // السيولة
    tabs2Title: "سجل إعادة الشحن", // سجل إعادة الشحن
    tabs3Title: "سجل السحب", // سجل السحب
    selectionPeriod: "اختيار الوقت", // حدد الوقت
    rechargeTitle: 'إعادة الشحن ', // إعادة الشحن
    wthdrawTitle: "سحب", // سحب
    successful: "نجاح", // نجاح
    balance: "الرصيد", // التوازن
    rechargeTime: "وقت إعادة الشحن", // وقت إعادة الشحن
    rechargeMessage: "نوع العملة", // طريقة إعادة الشحن
    wthdrawTime: "وقت السحب", // وقت إعادة الشحن
    wthdrawMessage: "نوع العملة", // طريقة إعادة الشحن
    currency: "العملة", // العملة
    timeAffirm: "تأكيد",
    timeCancel: "إلغاء",
}
// 经营报表
let statisticsLanguage = {
    totalSales: "إجمالي المبيعات",
    totalPurchase: "إجمالي مبلغ الشراء",
    totalProfit: "إجمالي الربح",
    selectionPeriod: "مرشح الوقت", // حدد الوقت
    analysis: "مخطط خطي", // عنوان مخطط خطي
    showTitle: "توسيع", // قم بتوسيع المخطط الخطي
    selectAll: "حدد الكل", // حدد الكل
    cancel: "إغلاق", // إغلاق
    sure: "تأكيد", // تأكيد
    fundingDetails: "بيانات مفصلة", // تفاصيل التمويل
    typeSelection: "نوع التحديد", // نوع التحديد
    purchaseAmount: "مبلغ الشراء", // المبيعات
    profitAmount: "مبلغ الربح", // إجمالي المبيعات
    unitAmount: "المبيعات", // الشراء بالتقسيط
    grandTotal: "تراكمي",
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: "قائمة الإدارة",
    selectionPeriod: "حدد الوقت", // حدد الوقت
    totalPurchase: "مبلغ الشراء",
    totalSales: "مبلغ الربح",
    totalProfit: "المبيعات",
    grandTotal: "تراكمي",
}
// 账户管理
let accountManagementLanguage = {
    navTitle: "معلومات المتجر",
    picture: "صورة متجر",
    nameTitle: "اسم المتجر",
    Mail: "البريد الإلكتروني لتسجيل الدخول",
    invitationCode: "كود الدعوة",
    loginPassword: "كلمة مرور تسجيل الدخول",
    paymentPassword: "كلمة مرور الدفع",
    documents: "معلومات المستند",
    frontDocument: "واجهة المستند",
    backDocument: "ظهر المستند",
    exit: "تسجيل الخروج",
}

//  提现设置
let eBackLanguage = {
    navTitle: "إعدادات السحب", // عنوان الدفع
    cashCurrency: "عملة فيات",
    virtualCurrency: "العملة الافتراضية",
    pleaseChoose: "الرجاء الاختيار",
    popupAffirm: "تأكيد",
    popupCancel: "إلغاء",
    hintTitle: 'هام:', // يدعم فقط السحب إلى <العنوان.
    hintContent: 'مسموح فقط باستيراد <الأصول. ', // يدعم فقط السحب إلى <العنوان.
    affirmBtn: "تأكيد", // تأكيد
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: "الاسم الحقيقي",
    email: "البريد الإلكتروني",
    mobilePhone: ' رقم الهاتف المحمول',
    pixType: "نوع PIX",
    pixKey: "حساب PIX",
    pleaseEnter: "الرجاء إدخال",
    pleaseSelect: "الرجاء التحديد",

}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: "الرجاء إدخال",
    country: "البلد / المنطقة",
    name: ' اسم',
    surname: "اللقب",
    bsb: "BSB",
    bankName: "اسم البنك",
    bankAccount: "حساب مصرفي",
    state: "المقاطعة أو الولاية",
    mobilePhone: ' رقم الهاتف المحمول',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: "الرجاء إدخال",
    country: "البلد / المنطقة",
    name: ' اسم',
    surname: "اللقب",
    swift: "سريع",
    bankName: "اسم البنك",
    iban: "إيبان",
    mobilePhone: ' رقم الهاتف المحمول',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: "الرجاء إدخال",
    country: "البلد / المنطقة",
    name: ' اسم',
    surname: "اللقب",
    bankCode: "رمز البنك",
    bankName: "اسم البنك",
    bankAccount: "حساب مصرفي",
    mobilePhone: ' رقم الهاتف المحمول',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: "الرجاء إدخال",
    country: "البلد / المنطقة",
    name: ' اسم',
    surname: "اللقب",
    zengin: "zengin",
    bankName: "اسم البنك",
    bankAccount: "حساب مصرفي",
    county: "مقاطعة",
    mobilePhone: ' رقم الهاتف المحمول',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: "الرجاء إدخال",
    country: "البلد / المنطقة",
    name: ' اسم',
    surname: "اللقب",
    swift: "سريع",
    ach: "آتش",
    bankName: "اسم البنك",
    bankAccount: "حساب مصرفي",
    state: "المقاطعة أو الولاية",
    mobilePhone: ' رقم الهاتف المحمول',
}

//  提现设置-法定货币-KRW
let krwLanguage = {
    pleaseEnter: "الرجاء إدخال",
    name: ' اسم',
    surname: "اللقب",
    bankName: "اسم البنك",
    bankAccount: "حساب مصرفي",
    mobilePhone: ' رقم الهاتف المحمول',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'تفضل',
    bankName: 'اسم البنك',
    account: 'حساب',
    clabe: 'حساب كلابي',
    mobilePhone: ' رقم الهاتف المحمول',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'تبوك',
    pleaseInformation: 'الرجاء إدخال عنوان العملة الافتراضية'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: "تعديل كلمة مرور تسجيل الدخول", // كلمة مرور تسجيل الدخول
    oldPass: "كلمة المرور القديمة", // كلمة المرور القديمة
    newPass: "كلمة مرور جديدة", // كلمة مرور جديدة
    email: "رمز التحقق",
    placeholderOldPass: "الرجاء إدخال كلمة المرور القديمة",
    placeholderNewPass: "الرجاء إدخال كلمة مرور جديدة",
    placeholderEmailCode: "الرجاء إدخال رمز التحقق",
    submitBtn: "إرسال", // تأكيد
    affirm: "تأكيد", // تأكيد

}

//  个人中心修改支付密码
let payPass = {
    navTitle: ' تعديل كلمة مرور الدفع ', // كلمة مرور الدفع
    affirmPass: "كلمة مرور الدفع",
    email: "رمز التحقق", // تأكيد كلمة المرور
    submitBtn: "إرسال", // تأكيد
    affirm: "تأكيد", // تأكيد
    placeholder: "الرجاء إدخال",
    placeholderPaymentPassword: "الرجاء إدخال كلمة مرور الدفع",
    placeholderEmailCode: "الرجاء إدخال رمز التحقق",
}

//  系统设置
let settingLanguage = {
    navTitle: 'اعدادات النظام', // Setting
    languageShift: 'إعدادات متعددة اللغات', // language shift
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'إعدادات متعددة اللغات',
    affirm: 'تؤكد'
}
//  公告
let afficheDetail = {
    navTitle: "رسالة", // إعلام
    comment: "رد", // تعليق
    unreadMessage: "غير مقروءة",
    readTitle: "قراءة"
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: "تفاصيل الرسالة", // الإعلام
    comment: "تعليق", // تعليق
    check: "عرض",
    packUpPlaceholder: "الرجاء إدخال محتوى الرد",
    sendInform: "إرسال"
}

// 客服
let serviceLanguage = {
    navTitle: "خدمة العملاء", // خدمة العملاء
    customerService: "خدمة العملاء الحصرية", // خدمة العملاء
    helloTitle: "مرحبًا",
    welcomeTitle: "أنا لك <, يمكنك الاتصال بي بالطرق التالية",
    iconInformation: "الرجاء تحديد برنامج اتصال للتشاور",
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "إدارة الطلبات", // قائمة الطلبات
    tabsTodos: "تم شراؤها", // الكل
    tabsPendente: "ليتم شراؤها", // ليتم شراؤها
    tabsCompleto: "في اللوجستيات", // في الخدمات اللوجستية
    tabsCancelar: "مكتمل", // مكتمل
    totalProfit: "إجمالي الربح",
    statementPrice: "إجمالي مبلغ الشراء", // إجمالي المبلغ:
    affirmBtn: "شراء", // تأكيد
    orderTotal: "إجمالي الطلبات",
    orderCode: "رقم الطلب:",
    childrenDetail: "المنتجات", // التفاصيل
    checkDetail: "تفاصيل الإنتاج", // عرض التفاصيل
    buyAll: "شراء الكل",
    dialogTitle: "معلومات الشراء",
    dialogTotalSales: "إجمالي المبيعات",
    dialogTotalPurchase: "إجمالي مبلغ الشراء",
    dialogTotalProfit: "إجمالي الربح",
    dialogBtn: "شراء",
    deleteTitle: "طلب الشراء",
    dialogMessage: " هل أنت متأكد من شراء هذا الطلب؟ ",
    dialogAllMessage: " هل أنت متأكد من شراء جميع الطلبات؟ ",
    affirm: "تأكيد",
    close: "إلغاء",
    selectionPeriod: "اختيار الوقت", // حدد الوقت
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "تفاصيل الطلب", // قائمة الطلبات
    orderCode: "رقم الطلب:", // رقم الطلب
    totalProfitTitleCard: "إجمالي الربح",
    statementPrice: "إجمالي مبلغ الشراء", // إجمالي المبلغ:
    amountRelated: "مبلغ الطلب",
    totalSalesTitle: "إجمالي المبيعات",
    totalPurchase: "إجمالي الربح",
    totalProfitTitle: "إجمالي المشتريات",
    btnTitle: "شراء",
    deleteTitle: "طلب الشراء",
    dialogMessage: ' هل تؤكد تغيير أمر الشراء؟',
    affirm: "تأكيد",
    close: "إلغاء",
    salesTitle: 'مبلغ المبيعات',
    discount: 'نسبة المبيعات',
    salesAmount: 'مبلغ المبيعات الفعلي',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "تفاصيل طلب المنتج", // قائمة الطلبات
    orderCode: "رقم الطلب:", // رقم الطلب
    productInformation: "معلومات المنتج", // معلومات المنتج
    totalProfitTitleCard: "إجمالي الربح",
    statementPrice: "إجمالي مبلغ الشراء:", // المبلغ الإجمالي:
    unitPrice: "سعر الوحدة", // سعر الوحدة
    salesVolume: "إجمالي سعر البيع", // حجم المبيعات
    purchasePrices: "سعر وحدة الشراء", // سعر الشراء
    purchasePricesTotal: "إجمالي سعر الشراء", // سعر الشراء
    profitPerPiece: "سعر وحدة الربح", // الربح
    profitPerPieceTotal: "إجمالي سعر الربح", // الربح
    orderCardTitle: "الدفع",
    paymentMethods: "طريقة الدفع", // توليد مدى الحياة
    paymentTime: "وقت الدفع", // وقت الدفع
    returnedMoneyTitle: "الأموال المرتجعة",
    returnedMoneyMethods: "ما إذا كان قد تم إرجاع الأموال",
    returnedMoneyMethods1: "لم يتم سدادها",
    returnedMoneyMethods2: "تم إرجاع الأموال",
    returnedMoneyTime: "وقت الإرجاع",
    logisticsInformation: "المعلومات اللوجستية",
    logisticsInformationTitle: "انقر لعرض آخر الأخبار اللوجستية",
    trackingNumber: "رقم الخدمات اللوجستية",
    orderNumber: "رقم الطلب",
    logisticsCardTitle: "المعلومات اللوجستية"
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'الرجاء إدخال شروط الاستعلام',
}
// 脚部语言
let footerLanguage = {
    home: 'مسكن',
    order: 'ترتيب',
    Workbench: 'منضدة',
}
let chatPageMessage = {
    ladingText: 'تحميل . ...',
    loosingText: 'الإفراج عن تحديث...',
    pullingText: 'المنسدلة لتحديث...',
    send: 'أرسل',
    messageText: 'رسالة',
    connecting: "اتصال خدمة الرسائل الفورية ...",
    connectionSuccess: "اتصال خدمة الرسائل الفورية ناجح",
    connectionFail: "فشل اتصال خدمة الرسائل الفورية",
    connectionSendFail: "خدمة الرسائل الفورية غير متصلة, فشل الإرسال",
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: "دعوة الأصدقاء لكسب المكافآت",
    qrCodeLanguage: "نسخ",
    ruleTitle: "قواعد الدعوة",
    rule1: "يمكن للداعي دعوة الأصدقاء لإكمال التسجيل من خلال مشاركة رابط الترويج , وسيتم ربط الأصدقاء المدعوين بك ؛",
    rule2: "بعد دعوة المستخدمين لإتمام الدفع , يمكنك الحصول على حصة من أرباحهم ؛",
    rule3: "دعم فقط مكافآت الدعوات ثلاثية المستويات , نسبة المكافأة هي: <٪,>٪, @٪؛",
    rule4: "يجب أن تكون طريقة الدعوة قانونية ومتوافقة مع قواعد دعوة الأصدقاء. إذا كان هناك أي طريقة دعوة أو سلوك غير لائق ينتهك قواعد دعوة الأصدقاء , فبمجرد التحقق من ذلك , سيتم خصم جميع المكافآت غير الصادرة. تحتفظ المنصة بالحق في تتبع جميع المكافآت الصادرة , ومتابعة المسؤولية القانونية حسب الحالة. ",
    situation: "أحدث سجل دعوة",
    copySuccess: "تم النسخ بنجاح",

}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: "دعوة سجل المستخدم",
    cardTitle: "إجمالي عدد المستخدمين",
    firstLevelUser: "المستوى الأول",
    secondaryUser: "ثانوي",
    tertiaryUser: "التعليم العالي",

    tabs1Title: "الكل",
    tabs2Title: "المستوى الأول",
    tabs3Title: "ثانوي",
    tabs4Title: "المستوى الثالث",
}
// 总邀请页面
let commissionLanguage = {
    navTitle: "سجل إيرادات الدعوات",
    cardTitle: "إجمالي الإيرادات",
    placeholder: "الرجاء إدخال",
    firstLevelUser: "المستوى الأول",
    secondaryUser: "ثانوي",
    tertiaryUser: "التعليم العالي",
    store: "المبلغ الإجمالي",
    ratio: "النسبة",
    commission: 'دخل',

    tabs1Title: "الكل",
    tabs2Title: "المستوى الأول",
    tabs3Title: "ثانوي",
    tabs4Title: "المستوى الثالث",
}
// 排行榜页面
let rankinLanguage = {
    navTitle: "إدارة المبيعات",
    navContent: "ترتيب مبيعات المنتج",
    tabs1: "المبيعات",
    tabs2: "المبلغ الإجمالي",
    tabs3: "مبلغ الربح",
    searchPlaceholder: "اسم المنتج",
    date1Placeholder: "وقت البدء",
    date2Placeholder: "وقت الانتهاء",
    salesVolume: "مبيعات",
    totalPrice: "إجمالي المبيعات",
    shelfTime: "الربح الإجمالي",
    ranking: "لا",
    timeAffirm: "تأكيد",
    timeCancel: "إلغاء"
};
let rankingItemLanguage = {
    navTitle: "سجل مبيعات المنتج",
    date1Placeholder: "وقت البدء",
    date2Placeholder: "وقت الانتهاء",
    tabs1: "المبيعات",
    tabs2: "الوقت",
    salesTime: "الوقت",
    salesVolume: "مبيعات",
    totalAmount: "إجمالي المبيعات",
    timeAffirm: "تأكيد",
    timeCancel: "إلغاء"

};
// 个人中心新增
myCenterLanguage.invitation = 'يدعو'
myCenterLanguage.commission = 'عمولة'

// 订单页面新增
orderPageLangage.orderBgTitle = 'رتبة المبيعات ، أعرف'
orderPageLangage.orderBgLeaveFor = 'اذهب إلى'
export default ({
    // 聊天页面
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
