import Vue from 'vue'
import utils from './storangeWathch'
import { Toast } from 'vant';
import baseUrl from '../components/component/js/imgurlApi'
import languageType from "@/components/component/js/language";
let languageList = languageType(localStorage.getItem("languageType")).chatPageMessage

let webSocket = null;
let isConnect = false; //连接状态
let globalCallback = function (e) { };//定义外部接收数据的回调函数
let reConnectNum = 0;//重连次数 
let websocketUrl = baseUrl.imBaseUrl
let v = new Vue()

v.$toast = Toast;
//心跳设置
var heartCheck = {
  heart: "heart",//心跳包
  timeout: 45 * 1000, //每段时间发送一次心跳包 这里设置为60s
  heartbeat: null, //延时发送消息对象(启动心跳新建这个对象，收到消息后重置对象)
  start: function () {
    this.heartbeat = setInterval(() => {
      if (isConnect) {
        webSocketSend(this.heart);
      } else {
        this.clear();
      }
    }, this.timeout);
  },
  reset: function () {
    clearInterval(this.heartbeat);
    this.start();
  },
  clear: function () {
    clearInterval(this.heartbeat);
  }
}

//初始化websocket
function initWebSocket (callback) {

  //此callback为在其他地方调用时定义的接收socket数据的函数
  if (callback) {
    if (typeof callback == 'function') {
      globalCallback = callback
    } else {
      throw new Error("callback is not a function")
    }
  }
  if ("WebSocket" in window) {
    webSocket = new WebSocket(websocketUrl);//创建socket对象
  } else {

    return
  }
  //打开
  webSocket.onopen = function () {

    webSocketOpen();
  };
  //收信
  webSocket.onmessage = function (e) {
    webSocketOnMessage(e);
  };
  //关闭
  webSocket.onclose = function (e) {
    webSocketOnClose(e);
  };
  //连接发生错误的回调方法
  webSocket.onerror = function (e) { 
    webSocketonError(e);
  };
}
//连接socket建立时触发
function webSocketOpen () {
  Toast({
    message: languageList.connectionSuccess,
    type: 'success'
  });
  console.log("WebSocket连接成功");
  //首次握手
  webSocketSend(heartCheck.heart);
  isConnect = true;
  heartCheck.start();
  reConnectNum = 0;
}

//客户端接收服务端数据时触发,e为接受的数据对象
function webSocketOnMessage (e) {
  if (e.data == "stopUser") {
    // Message({
    //     message: '你已被上级管理员停用即将跳转登录页',
    //     type: 'warning'
    // });
    // setTimeout(() => {
    //     window.location.href = vueConfig.jqUrl + "vue/web/login"
    // }, 3000);
  }
  utils.resetSetItem('errorType', true)
  const data = JSON.parse(e.data);//根据自己的需要对接收到的数据进行格式化
  globalCallback(data);//将data传给在外定义的接收数据的函数，至关重要。
}

//socket关闭时触发
function webSocketOnClose (e) { 
  heartCheck.clear();
  isConnect = false; //断开后修改标识
  //被动断开，重新连接
  if (e.code == 1006) {
    if (reConnectNum < 6) {
      setTimeout(() => {
        Toast({
          message: languageList.connecting,
          type: 'fail'
        });
        initWebSocket();
        ++reConnectNum;
      }, 5000)
    } else {
      Toast({
        message: languageList.connectionFail,
        type: 'fail'
      });
      sessionStorage.removeItem('reConnectNum')
    }
    utils.resetSetItem('errorType', false)
  }
}

//连接发生错误的回调方法
function webSocketonError (e) {
  heartCheck.clear();
  isConnect = false; //断开后修改标识
  utils.resetSetItem('errorType', false)
}


//发送数据
function webSocketSend (data) {
  webSocket.send(JSON.stringify(data));//在这里根据自己的需要转换数据格式
}
//在其他需要socket地方主动关闭socket
function closeWebSocket (e) {
  reConnectNum = 0;
  webSocket.close();
  heartCheck.clear();
  isConnect = false;

}
function reConnectNumFn () {
  reConnectNum = 0;
}
//在其他需要socket地方接受数据
function getSock (callback) {
  globalCallback = callback
}
//在其他需要socket地方调用的函数，用来发送数据及接受数据
function sendSock (agentData) {
  //下面的判断主要是考虑到socket连接可能中断或者其他的因素，可以重新发送此条消息。
  switch (webSocket.readyState) {
    //CONNECTING：值为0，表示正在连接。
    case webSocket.CONNECTING:
      setTimeout(function () {
        sendSock(agentData, callback);
      }, 5000);
      break;
    //OPEN：值为1，表示连接成功，可以通信了。
    case webSocket.OPEN:
      webSocketSend(agentData);
      break;
    //CLOSING：值为2，表示连接正在关闭。
    case webSocket.CLOSING:
      setTimeout(function () {
        sendSock(agentData, callback);
      }, 1000);
      break;
    //CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
    case webSocket.CLOSED:
      // do something
      break;
    default:
      // this never happens
      break;
  }
}

export default {
  initWebSocket,
  closeWebSocket,
  sendSock,
  getSock,
  reConnectNumFn
};
