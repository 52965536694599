// 德语

// 语言
let languageVersion = [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁體中文
    // '简体中文'  // 简体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', //土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
]

// app升级配置文件语言
let configLanguage = {
    upgradePrompt: 'Versions-Upgrade, bitte bestätigen? ',// Versionsaktualisierung, bitte bestätigen?
    loadingTitle: 'Wird heruntergeladen...', // Wird heruntergeladen...
    systemUpgrade: 'System-Upgrade', // System-Upgrade
    upgradeImmediately: 'Bestätigen', // Bestätigen
}
// 驻店
let joinLanguage = {
    navTitle: '',
    shopName: 'Geschäftsname',
    pass: 'Passwort',
    placeholderPass: 'Bitte geben Sie Ihr Passwort ein',
    placeholderShopName: "Bitte geben Sie den Namen des Shops ein",
    mail: "Postfach",
    placeholderMail: "Bitte geben Sie Ihre E-Mail-Adresse ein",
    emailCode: "E-Mail-Bestätigungscode",
    placeholderEmailCode: "Bitte geben Sie den Bestätigungscode ein",
    send: 'Senden',
    InvitationCode: 'Einladungscode',
    PlaceholderInvitationCode: 'Bitte geben Sie den Einladungscode ein',
    documents: 'Dokumente hochladen',
    front: 'Dokumentenfront',
    reverseSide: 'Vorderseite des Dokuments',
    placeholderFront: 'Bitte laden Sie die Vorderseite des Zertifikats hoch',
    placeholderReverseSide: 'Bitte laden Sie die Rückseite des Dokuments hoch',
    btn: 'Bewerben',
    applicationBtn: 'Bestätigen',
    applicationNav: 'Anwendungsvereinbarung speichern',
    dialogTitle: 'Überprüfen Sie den Erfolg',
    dialogMessage: 'Die Bewerbung war erfolgreich, bitte warten Sie geduldig auf die Überprüfung durch die Mitarbeiter！',
    close: 'Bestätigen',
}
// 登录
let loginLanguage = {
    navTitle: 'Anmeldung', // Anmeldung
    warningTitle: 'Bitte geben Sie Ihre Anmelde-E-Mail-Adresse ein',
    passTitle: 'Bitte geben Sie das Login-Passwort ein', // Passwort
    forgetPassword: 'Passwort vergessen? ',//Passwort vergessen
    btnTitle: 'Anmeldung', // Anmeldung
    hintTitle: 'Kein Account? gehe zur Registrierung', // registrieren
    linkTitle: 'Kundendienst', // Kundenservice
    AppDownload: 'App herunterladen', //App herunterladen
    goToShop: 'Zum Einkaufszentrum',
    register: 'registrieren'
}
// 忘记密码？
let forgetPasswrodLanguage = {
    navTitle: 'Passwort vergessen',
    email: 'Login-E-Mail',
    placeholderEmail: 'Bitte geben Sie Ihre Login-E-Mail ein',
    emailCode: 'Bestätigungscode',
    placeholderEmailCode: 'Bitte geben Sie den Bestätigungscode ein',
    newPass: 'Neues Passwort',
    placeholderNewPass: "Bitte geben Sie ein neues Passwort ein",
    affirmPass: 'Passwort bestätigen',
    placeholderAffirmPass: 'Bitte geben Sie das Passwort zweimal ein',
    submitBtn: 'senden',
    affirm: 'bestätigen',
}

// 首页
let homeLanguage = {
    navTitle: 'Heutige Verkäufe', // Startseite
    orderTitle: 'Anzahl der Bestellungen heute',//Order
    balanceTitle: 'balance',//balance
    flowTitle: 'Today\'s traffic count', //Today's traffic
    navTitleLine: 'Verkäufe', // Startseite
    orderTitleLine: 'Anzahl der Bestellungen',//Order
    flowTitleLine: 'Flow-Nummer', //Heutiger Flow
    rechargeTitle: 'Aufladen', // aufladen
    bankTitle: 'Abheben', // Abheben
    analysis: 'Übersicht der letzten 30 Tage',//Titel des Liniendiagramms
    showTitle: 'Expand',//Erweitern Sie das Liniendiagramm
    selectAll: 'alle auswählen',//alle auswählen
    cancel: 'schließen',//schließen
    sure: 'bestätigen',//bestätigen
    revenueLanguage: 'Steuer',
    revenuepopupTitle: 'Steuererinnerung',
    revenuepopupWarn: 'Zum Aufladen und Steuern zahlen',
    revenuepopupNeed: 'Steuer ist derzeit fällig',
    revenuepopupSurn: 'bestätigen',
    outstandingAmount: 'ausstehender Betrag',
    outstandingOrders: 'offene Bestellungen',
    totalSalesYesterday: 'Gesamtumsatz gestern',
    yesterdaySalesOrders: 'Kundenaufträge von gestern',
}

// 充值页
let rechargeLanguage = {
    navTitle: 'Aufladen', // Aufladen
    currencyType1: 'Fiat-Währung',
    currencyType2: 'Virtuelle Währung',
    pleaseChoose: "Bitte auswählen",
    cancelBtn: 'Abbrechen',
    submitBtn: 'confirm', // bestätigen
    placeholderNav: 'Geben Sie den Aufladebetrag ein', // aktuelles Guthaben
    placeholderCurrent: 'Bitte geben Sie den Aufladebetrag ein', // geben Sie den Betrag ein
    dialogMessage: 'Bitte wählen Sie den Währungstyp aus. ',
    pathTitle: 'Auflademethode',// channel
    hintTitle: 'Wichtig:',
    hintContent: 'Nur erlaubt <Assets zu importieren',
    success: 'Kopieren erfolgreich! ',
    hintPopup: 'Bitte wenden Sie sich an den Kundendienst!',
    hintTile: 'Schnelle Information'
}

// 提现页
let withdrawLanguage = {
    navTitle: 'Zurückziehen', // Zurückziehen
    currencyType1: 'Fiat-Währung',
    currencyType2: 'Virtuelle Währung',
    pleaseChoose: "Bitte auswählen",
    cancelBtn: 'Abbrechen',
    submitBtn: 'confirm', // bestätigen
    placeholderNav: 'Geben Sie den Auszahlungsbetrag ein',
    placeholderCurrent: 'Bitte geben Sie den Auszahlungsbetrag ein', // Geben Sie den Betrag ein
    currentBalance: 'Aktueller Kontostand', // Aktueller Kontostand
    passwordTips: 'Bitte geben Sie das Auszahlungspasswort ein', // Geben Sie den Betrag ein
    pathTitle: 'Wechselkurs', // Kanal
    rateTips: 'Aktuelle Conversion-Rate',
    dialogMessage: 'Bitte wählen Sie den Währungstyp aus. ',
    hintTitle: 'Wichtig:',
    hintContent: 'Nur erlaubt <Assets zu importieren',
}

// 提示文本
let messageLanguage = {
    warningTitle: 'Fehler', // Fehler
    loadingTitle: 'Laden', // Laden
    finishedText: "keine", //keine
    loadingText: 'Laden', // Laden
    wait: 'Bitte warten Sie einen Moment',
    placeholderHite: 'Der Eingabewert ist falsch',
    uploadError: 'Hochladen fehlgeschlagen',
    upload: 'Hochladen',
}

// 个人中心
let myCenterLanguage = {
    navTitle: 'persönliches Zentrum', // persönliches Zentrum
    bankCard: 'Auszahlungseinstellungen', // Auszahlungsadresse
    productLibrary: 'Produktverwaltung', //Produktbibliothek
    financialManagement: 'Fondsdatensatz', //Finanzverwaltung
    reportStatistics: 'Geschäftsbericht', //Statistik
    accountInformation: 'Informationen speichern', //Sprache wechseln
    setting: 'Systemeinstellungen',
    goToShop: 'Zum Einkaufszentrum',
    goToLoan: 'Darlehen',
    downloadApp: 'Lade App herunter',
    activity: 'Aktivität'
}
// 活动页面
let activityLanguage = {
    navTitle: 'Aktivitätszentrum',
    discount: 'Rabatt'
}
let discountLanguage = {
    navTitle: 'Rabatt',
    affirmBtn: 'Hinzufügen',
    submitBtn: 'absenden',
    accain: 'Voll',
    amount: 'der Betrag',
    ratio: 'Rabatt',
    plusAffirm: 'bestätigen',
    placeholderEnter: 'Bitte eingeben'
}

// 下载
let downloadApp = {
    navTitle: 'Lade App herunter',
    downloadAndroidApp: 'Android-App herunterladen',
    downloadIosApp: 'ios-App herunterladen',
}

// 产品库
let productLibraryLanguage = {
    navTitle: 'Produktbibliothek', // Produktbibliothek
    productList: 'Produkt speichern', // Produktliste
    productLibraryTitle: 'Plattformprodukt', // Produktbibliothek
    purchasePrice: 'Kaufpreis',
    sellingPrice: 'Verkaufspreis',
    selectAll: 'alle auswählen',
    affirm: 'bestätigen',
    close: 'Abbrechen',
    editPrice: 'Preis ändern',
    deleteTitle: 'Bestellung löschen',
    dialogMessage: 'Sind Sie sicher, dass Sie dieses Produkt löschen möchten? '
}
// 资金记录
let financialManagementLanguage = {
    navTitle: 'Fondsunterlagen',
    tabs1Title: 'Alle Fonds', // Liquidität
    tabs2Title: 'Datensatz aufladen', // Datensatz aufladen
    tabs3Title: 'Auszahlungsprotokoll',//Auszahlungsprotokoll
    selectionPeriod: 'Zeitauswahl',//Zeit auswählen
    rechargeTitle: 'aufladen',// aufladen
    wthdrawTitle: 'Rückzug',// Zurückziehen
    successful: 'Erfolg',//Erfolg
    balance: 'balance', // balance
    rechargeTime: 'Aufladezeit',// Aufladezeit
    rechargeMessage: 'Währungstyp',// Auflademethode
    wthdrawTime: 'Entnahmezeit', // Wiederaufladezeit
    wthdrawMessage: 'Währungstyp',// Auflademethode
    currency: "Währung",// Währung
    timeAffirm: 'Bestätigen',
    timeCancel: 'Abbrechen',
}
// 经营报表
let statisticsLanguage = {
    totalSales: 'Gesamtumsatz',
    totalPurchase: 'Gesamtkaufbetrag',
    totalProfit: 'Gesamtgewinn',
    selectionPeriod: 'Zeitfilter',//Zeit auswählen
    analysis: 'Liniendiagramm',//Titel des Liniendiagramms
    showTitle: 'Expand',//Erweitern Sie das Liniendiagramm
    selectAll: 'alle auswählen',//alle auswählen
    cancel: 'schließen',//schließen
    sure: 'bestätigen',//bestätigen
    fundingDetails: 'detaillierte Daten', // Details zur Finanzierung
    typeSelection: 'Auswahltyp',//Auswahltyp
    purchaseAmount: 'Kaufbetrag', //Verkäufe
    profitAmount: 'Gewinnbetrag',//Gesamtumsatz
    unitAmount: 'Sales', // Ratenkauf
    grandTotal: 'kumulativ',
    timeAffirm: 'Bestätigen',
    timeCancel: 'Abbrechen',
}
// 经营报表-列表
let statisticalFormLanguage = {
    navTitle: 'Verwaltungsliste',
    selectionPeriod: 'Zeit auswählen',//Zeit auswählen
    totalPurchase: 'Kaufbetrag',
    totalSales: 'Gewinnbetrag',
    totalProfit: 'Umsatz',
    grandTotal: 'kumulativ',
}
// 账户管理
let accountManagementLanguage = {
    navTitle: 'Geschäftsinformationen',
    picture: 'Shop-Bild',
    nameTitle: 'Geschäftsname',
    Mail: 'Login-E-Mail',
    invitationCode: 'Einladungscode',
    loginPassword: 'Login-Passwort',
    paymentPassword: 'Zahlungspasswort',
    documents: 'Dokumenteninformationen',
    frontDocument: 'Dokument-Vorderseite',
    backDocument: 'Rückseite des Dokuments',
    exit: 'abmelden',
}

//  提现设置
let eBackLanguage = {
    navTitle: 'Auszahlungseinstellungen', // Zahlungsadresse
    cashCurrency: 'Fiat-Währung',
    virtualCurrency: 'Virtuelle Währung',
    pleaseChoose: 'Bitte wählen',
    popupAffirm: 'Bestätigen',
    popupCancel: 'Abbrechen',
    hintTitle: 'Wichtig:', // Unterstützt nur Auszahlungen an < Adresse.
    hintContent: 'Nur erlaubt, < Assets zu importieren. ', // Unterstützt nur Auszahlungen an < Adresse.
    affirmBtn: 'bestätigen', // bestätigen
}

//  提现设置-法定货币-BRL
let eBackCurrencyBrlLanguage = {
    realName: 'richtiger Name',
    email: 'Email',
    mobilePhone: 'Handynummer',
    pixType: 'PIX-Typ',
    pixKey: 'PIX-Konto',
    pleaseEnter: 'Bitte eingeben',
    pleaseSelect: 'Bitte auswählen',
}

//  提现设置-法定货币-AUD
let audLanguage = {
    pleaseEnter: 'bitte eingeben ',
    country: 'Land/Region',
    name: 'Name',
    surname: 'Nachname',
    bsb: 'BSB',
    bankName: 'Bankname',
    bankAccount: 'Bankkonto',
    state: 'Provinz oder Staat',
    mobilePhone: 'Handynummer',
}
//  提现设置-法定货币-EUR
let eurLanguage = {
    pleaseEnter: 'bitte eingeben ',
    country: 'Land/Region',
    name: 'Name',
    surname: 'Nachname',
    swift: 'swift',
    bankName: 'Bankname',
    iban: 'iban',
    mobilePhone: 'Handynummer',
}
//  提现设置-法定货币-GBP
let gbpLanguage = {
    pleaseEnter: 'bitte eingeben ',
    country: 'Land/Region',
    name: 'Name',
    surname: 'Nachname',
    bankCode: 'Bankleitzahl',
    bankName: 'Bankname',
    bankAccount: 'Bankkonto',
    mobilePhone: 'Handynummer',
}
//  提现设置-法定货币-JPY
let jpyLanguage = {
    pleaseEnter: 'bitte eingeben ',
    country: 'Land/Region',
    name: 'Name',
    surname: 'Nachname',
    zengin: 'zengin',
    bankName: 'Bankname',
    bankAccount: 'Bankkonto',
    county: 'Landkreis',
    mobilePhone: 'Handynummer',
}
//  提现设置-法定货币-USD
let usdLanguage = {
    pleaseEnter: 'bitte eingeben ',
    country: 'Land/Region',
    name: 'Name',
    surname: 'Nachname',
    swift: 'swift',
    ach: 'ach',
    bankName: 'Bankname',
    bankAccount: 'Bankkonto',
    state: 'Provinz oder Staat',
    mobilePhone: 'Handynummer',
}

//  提现设置-法定货币-krw
let krwLanguage = {
    pleaseEnter: 'bitte eingeben ',
    name: 'Name',
    surname: 'Nachname',
    bankName: 'Bankname',
    bankAccount: 'Bankkonto',
    mobilePhone: 'Handynummer',
}

//  提现设置-法定货币-mxn
let mxnLanguage = {
    pleaseEnter: 'Bitte eingeben',
    bankName: 'Bankname',
    account: 'Konto',
    clabe: 'clabe-Konto',
    mobilePhone: 'Handynummer',
}

//  提现设置-虚拟货币
let eBackVirtualCurrency1Language = {
    paymentAddress: 'Adresse',
    pleaseInformation: 'Bitte geben Sie die Adresse der virtuellen Währung ein'
}

//  个人中心修改登录密码
let loginPass = {
    navTitle: 'Login-Passwort-Änderung', // Login-Passwort
    oldPass: 'altes Passwort', // altes Passwort
    newPass: 'neues Passwort', // neues Passwort
    email: 'Bestätigungscode',
    placeholderOldPass: 'Bitte geben Sie das alte Passwort ein',
    placeholderNewPass: 'Bitte geben Sie ein neues Passwort ein',
    placeholderEmailCode: 'Bitte geben Sie den Bestätigungscode ein',
    submitBtn: 'senden', // bestätigen
    affirm: 'bestätigen', // bestätigen

}

//  个人中心修改支付密码
let payPass = {
    navTitle: 'Änderung des Zahlungspassworts', // Zahlungspasswort
    affirmPass: 'Zahlungskennwort',
    email: 'Bestätigungscode', // Passwort bestätigen
    submitBtn: 'Senden',// Bestätigen
    affirm: 'bestätigen', // bestätigen
    placeholder: 'Bitte eingeben',
    placeholderPaymentPassword: 'Bitte geben Sie das Zahlungspasswort ein',
    placeholderEmailCode: 'Bitte geben Sie den Bestätigungscode ein',
}

//  系统设置
let settingLanguage = {
    navTitle: 'Systemeinstellungen', // Einstellung
    languageShift: 'Mehrsprachige Einstellungen', // Sprachumschaltung
}
// 设置语言
let languageShiftLanguage = {
    navTitle: 'Mehrsprachige Einstellungen',
    affirm: 'bestätigen'
}
//  公告
let afficheDetail = {
    navTitle: 'Nachricht', // Benachrichtigung
    comment: 'Antwort',// Kommentar
    unreadMessage: 'ungelesen',
    readTitle: 'lesen'
}
//  公告详情页
let afficheDetailIndex = {
    navTitle: 'Nachrichtendetails', // Benachrichtigung
    comment: 'Kommentar',// Kommentar
    check: 'Anzeigen',
    packUpPlaceholder: 'Bitte geben Sie den Inhalt der Antwort ein',
    sendInform: 'senden'
}

// 客服
let serviceLanguage = {
    navTitle: 'Kundenservice', // Kundenservice
    customerService: 'exklusiver Kundendienst',//Kundendienst
    helloTitle: 'Hallo',
    welcomeTitle: 'Ich gehöre dir <, du kannst mich auf folgende Weise kontaktieren',
    iconInformation: 'Bitte wählen Sie eine Kommunikationssoftware zur Beratung aus',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'kakao',
    signal: 'signal',
}

//  订单管理
let orderPageLangage = {
    navTitle: "Auftragsverwaltung", // Auftragsliste
    tabsTodos: "gekauft", // alle
    tabsPendente: "zu kaufen", // zu kaufen
    tabsCompleto: "In der Logistik", // in der Logistik
    tabsCancelar: "Completed", // Abgeschlossen
    totalProfit: 'Gesamtgewinn',
    statementPrice: "Gesamtkaufbetrag", // Gesamtbetrag:
    affirmBtn: "Kauf", // bestätigen
    orderTotal: "Bestellungen insgesamt",
    orderCode: "Bestellnummer:",
    childrenDetail: 'Produkte', // Details
    checkDetail: 'Produktionsdetails', // Details anzeigen
    buyAll: 'Alle kaufen',
    dialogTitle: 'Einkaufsinformationen',
    dialogTotalSales: 'Gesamtumsatz',
    dialogTotalPurchase: 'Gesamtkaufbetrag',
    dialogTotalProfit: 'Gesamtgewinn',
    dialogBtn: 'Einkauf',
    deleteTitle: 'Kauf bestellen',
    dialogMessage: 'Sind Sie sicher, diese Bestellung zu kaufen? ',
    dialogAllMessage: 'Sind Sie sicher, dass Sie alle Bestellungen kaufen? ',
    affirm: 'bestätigen',
    close: 'Abbrechen',
    selectionPeriod: 'Zeitauswahl',//Zeit auswählen
};

//  订单详情
let orderDetailListLangage = {
    navTitle: "Bestelldetails", // Bestellliste
    orderCode: "Bestellnummer:",//Bestellnummer
    totalProfitTitleCard: 'Gesamtgewinn',
    statementPrice: "Gesamtkaufbetrag", // Gesamtbetrag:
    amountRelated: 'Bestellbetrag',
    totalSalesTitle: 'Gesamtumsatz',
    totalPurchase: 'Gesamtgewinn',
    totalProfitTitle: 'Käufe insgesamt',
    btnTitle: 'Kauf',
    deleteTitle: 'Kauf bestellen',
    dialogMessage: 'Bestätigen Sie die Bestelländerung? ',
    affirm: 'bestätigen',
    close: 'Abbrechen',
    salesTitle: 'Verkaufsbetrag',
    discount: 'Umsatzverhältnis',
    salesAmount: 'Aktueller Verkaufsbetrag',
};

//  订单产品详情
let orderDetailLangage = {
    navTitle: "Produktdetails bestellen", // Bestellliste
    orderCode: "Bestellnummer:",//Bestellnummer
    productInformation: "Produktinformation", // Produktinformation
    totalProfitTitleCard: 'Gesamtgewinn',
    statementPrice: "Gesamtkaufbetrag:", // Gesamtbetrag:
    unitPrice: "Einheitspreis", // Einheitspreis
    salesVolume: "Gesamtverkaufspreis", // Verkaufsvolumen
    purchasePrices: "Kaufpreis pro Einheit", // Kaufpreis
    purchasePricesTotal: "Gesamtkaufpreis", // Kaufpreis
    profitPerPiece: "Gewinn-Einheitspreis", // Gewinn
    profitPerPieceTotal: "Gesamtgewinnpreis", // Gewinn
    orderCardTitle: 'Zahlung',
    paymentMethods: "Payment Method", // Lebenszeit generieren
    paymentTime: 'Zahlungszeit', // Zahlungszeit
    returnedMoneyTitle: 'Geld zurückerstattet',
    returnedMoneyMethods: 'Ob das Geld zurückgegeben wird',
    returnedMoneyMethods1: 'Nicht zurückgezahlt',
    returnedMoneyMethods2: 'Das Geld wurde zurückerstattet',
    returnedMoneyTime: 'Rückgabezeit',
    logisticsInformation: 'Logistikinformationen',
    logisticsInformationTitle: 'Klicken Sie hier, um die neuesten Logistiknachrichten anzuzeigen',
    trackingNumber: 'Logistiknummer',
    orderNumber: 'Bestellnummer',
    logisticsCardTitle: 'Logistikinformationen'
};

// 头部搜索框查询预值
let headerTitle = {
    placeholder: 'Bitte geben Sie die Abfragebedingungen ein',
}
// 脚部语言
let footerLanguage = {
    home: 'Heim',
    order: 'bestellen',
    Workbench: 'Werkbank',
}
let chatPageMessage = {
    ladingText: 'Download . ...',
    loosingText: 'Veröffentlichen Sie Updates...',
    pullingText: 'Dropdown update...',
    send: 'Senden',
    messageText: 'News',
    connecting: 'IM-Dienst verbindet...',
    connectionSuccess: 'Verbindung zum IM-Dienst erfolgreich',
    connectionFail: 'Verbindung zum IM-Dienst fehlgeschlagen',
    connectionSendFail: 'IM-Dienst ist nicht verbunden, Senden fehlgeschlagen',
}


/**
 *  2023年6月6日新版店铺
 */
// 邀请页面
let invitationLanguage = {
    navTitle: 'Freunde einladen, um Belohnungen zu verdienen',
    qrCodeLanguage: 'Kopieren',
    ruleTitle: 'Einladungsregeln',
    rule1: 'Der Einladende kann Freunde einladen, die Registrierung abzuschließen, indem er den Aktionslink teilt, und die eingeladenen Freunde werden mit Ihnen verknüpft;',
    rule2: 'Nachdem Sie Benutzer zum Abschluss der Zahlung eingeladen haben, können Sie einen Anteil an ihrem Umsatz erhalten;',
    rule3: 'Unterstützt nur dreistufige Einladungsbelohnungen. Das Belohnungsverhältnis beträgt: <%, >%, @%;',
    rule4: 'Die Einladungsmethode muss legal sein und den Regeln für das Einladen von Freunden entsprechen.\' Wenn eine unangemessene Einladungsmethode oder ein Verhalten vorliegt, das gegen die Regeln zum Einladen von Freunden verstößt, werden nach der Überprüfung alle nicht ausgegebenen Prämien abgezogen. Die Plattform behält sich das Recht vor, alle ausgegebenen Prämien zu verfolgen und gegebenenfalls rechtliche Verantwortung zu übernehmen. ',
    situation: 'Der neueste Einladungsdatensatz',
    copySuccess: 'Kopieren erfolgreich',
}
// 总邀请页面
let totalInvitationLanguage = {
    navTitle: 'Benutzerdatensatz einladen',
    cardTitle: 'Gesamtzahl der Benutzer',
    firstLevelUser: 'Ebene eins',
    secondaryUser: 'sekundär',
    tertiaryUser: 'Tertiär',

    tabs1Title: 'Alle',
    tabs2Title: 'Ebene eins',
    tabs3Title: 'Sekundär',
    tabs4Title: 'Dritte Ebene',
}
// 总邀请页面
let commissionLanguage = {
    navTitle: 'Datensatz zum Einladungsumsatz',
    cardTitle: 'Gesamtumsatz',
    placeholder: 'Bitte eingeben',
    firstLevelUser: 'Ebene eins',
    secondaryUser: 'sekundär',
    tertiaryUser: 'Tertiär',
    store: 'Gesamtbetrag',
    ratio: 'Verhältnis',
    commission: 'Einkommen',

    tabs1Title: 'Alle',
    tabs2Title: 'Ebene eins',
    tabs3Title: 'Sekundär',
    tabs4Title: 'Dritte Ebene',
}
// 排行榜页面
let rankinLanguage = {
    navTitle: 'Vertriebsmanagement',
    navContent: 'Produktverkaufsranking bestellen',
    tabs1: 'Verkäufe',
    tabs2: 'Gesamtbetrag',
    tabs3: 'Gewinnbetrag',
    searchPlaceholder: 'Produktname',
    date1Placeholder: 'Startzeit',
    date2Placeholder: 'Endzeit',
    salesVolume: 'sales',
    totalPrice: 'Gesamtumsatz',
    shelfTime: 'Gesamtgewinn',
    ranking: 'NEIN',
    timeAffirm: 'Bestätigen',
    timeCancel: 'Abbrechen'
};
let rankingItemLanguage = {
    navTitle: 'Produktverkaufsrekord',
    date1Placeholder: 'Startzeit',
    date2Placeholder: 'Endzeit',
    tabs1: 'Verkäufe',
    tabs2: 'Zeit',
    salesTime: 'Zeit',
    salesVolume: 'sales',
    totalAmount: 'Gesamtumsatz',
    timeAffirm: 'Bestätigen',
    timeCancel: 'Abbrechen'

};
// 个人中心新增
myCenterLanguage.invitation = 'einladen'
myCenterLanguage.commission = 'Kommission'

// 订单页面新增
orderPageLangage.orderBgTitle = 'Verkaufsrang, ich weiß'
orderPageLangage.orderBgLeaveFor = 'gehe zu'
export default ({
    chatPageMessage,
    orderDetailListLangage,
    // 头部搜索框查询预值
    headerTitle,
    // app升级配置文件语言
    configLanguage,
    // 登录密码
    loginLanguage,
    // 首页
    homeLanguage,
    rechargeLanguage,
    // 提现页
    withdrawLanguage,
    // 提示文本
    messageLanguage,
    // 语言
    languageVersion,
    // 个人中心
    myCenterLanguage,
    // 个人中心电子银行页
    eBackLanguage,
    // 个人中心电子银行页表单语句
    eBackCurrencyBrlLanguage,
    audLanguage,
    eurLanguage,
    gbpLanguage,
    jpyLanguage,
    usdLanguage,
    krwLanguage,
    mxnLanguage,
    // 个人中心电子银行页表单语句
    eBackVirtualCurrency1Language,
    //  个人中心修改登录密码
    loginPass,
    //  个人中心修改支付密码
    payPass,
    //  公告
    afficheDetail,
    //  公告详情
    afficheDetailIndex,
    // 客服
    serviceLanguage,
    //  订单
    orderPageLangage,
    // 脚部
    footerLanguage,
    // 订单详情
    orderDetailLangage,
    // 产品库
    productLibraryLanguage,
    // 产品库
    financialManagementLanguage,
    // 统计报表
    statisticsLanguage,
    statisticalFormLanguage,
    // 账户管理
    accountManagementLanguage,
    // 设置
    settingLanguage,
    // 设置语言
    languageShiftLanguage,
    // 驻店
    joinLanguage,
    // 登录忘记密码
    forgetPasswrodLanguage,
    // 下载
    downloadApp,
    // 活动中心
    activityLanguage,
    // 打折活动
    discountLanguage,


    /**2023年6月6日21:38:07  新版店铺 */
    // 邀请页面
    invitationLanguage,
    // 总邀请页面
    totalInvitationLanguage,
    commissionLanguage,
    // 订单排行榜页面
    rankinLanguage,
    // 订单排行榜单个商品页面
    rankingItemLanguage
})
